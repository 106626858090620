import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
  FC,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, IconButton, useTheme, Grid2 } from '@mui/material';

import { MdOutlineCloudUpload, MdDelete } from 'react-icons/md';

interface FileWithPreview extends File {
  preview: string;
  progress: number;
}

interface DragAndDropRef {
  files: FileWithPreview[];
}

const DragAndDrop = forwardRef<DragAndDropRef>((props, ref) => {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const theme = useTheme();

  // Expondo o estado 'files' para o componente pai através do ref
  useImperativeHandle(ref, () => ({
    files,
  }), [files]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const filesWithPreview = acceptedFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        progress: 0,
      })
    );
    setFiles((prev) => [...prev, ...filesWithPreview]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    // accept: 'image/*', // Pode limitar tipos de arquivos aqui
  });

  const handleRemoveFile = (fileToRemove: FileWithPreview) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
    URL.revokeObjectURL(fileToRemove.preview); // Liberar memória da URL
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          padding: '20px',
          border: '2px dashed #ccc',
          borderRadius: '8px',
          textAlign: 'center',
        }}
      >
        <div {...getRootProps()} style={{ padding: '20px', cursor: 'pointer' }}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <Box>
              <MdOutlineCloudUpload size={40} color={theme.palette.primary.light} />
              <Typography variant="body1">Solte os arquivos aqui...</Typography>
            </Box>
          ) : (
            <Box>
              <MdOutlineCloudUpload size={40} color={theme.palette.primary.light} />
              <Typography variant="body1">
                Arraste e solte os arquivos aqui, ou clique para selecionar
              </Typography>
            </Box>
          )}
        </div>
      </Box>

      <Box>
        {/* Visualização de arquivos */}
        <Grid2 container spacing={2} sx={{ marginTop: 4 }}>
          {files.map((file, index) => (
            <Grid2 size={2} key={index}>
              <Box
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  padding: '10px',
                  textAlign: 'center',
                  position: 'relative',
                }}
              >
                {/* Miniatura da imagem */}
                <img
                  src={file.preview}
                  alt={file.name}
                  style={{ width: '150px', height: 'auto', borderRadius: '8px' }}
                />
                <Typography variant="body2" noWrap sx={{ marginTop: 1 }}>
                  {file.name}
                </Typography>

                {/* Botão para remover o arquivo */}
                <IconButton
                  onClick={() => handleRemoveFile(file)}
                  sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px',
                    backgroundColor: '#e00d0d',
                    borderRadius: '50%',
                  }}
                >
                  <MdDelete size={25} color="#FFF" />
                </IconButton>
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>
    </Box>
  );
});

export default DragAndDrop;