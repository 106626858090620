import React, { useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { CreateBoxesUseCase } from "../../../Usecase/Boxes/create-boxes-usecase";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from 'yup'
import { useNavigate, useParams } from "react-router-dom";
import { ICreateBoxPayload } from "../../../Services/api/boxes/boxes.interface";
import { IOptionsProps } from "../../../Components/Form/Select/select.component";
import { ListServiceInfoOrdersUseCase } from "../../../Usecase/ServicesInfo/list-service-info-usecase";
import { CreateProductsServiceOrdersUseCase } from "../../../Usecase/ProductsService/create-products-service-usecase";
import { IProductsServicePayload } from "../../../Services/api/products-services/products-services-interface";

interface IUseCreateServicesProps {
    listServiceInfoOrdersUseCase: ListServiceInfoOrdersUseCase
    createProductsServiceOrdersUseCase: CreateProductsServiceOrdersUseCase
}

const CreateBoxSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    description: Yup.string().required('Descrição é obrigatório'),
    tributaryRegime: Yup.string().required('Regime tributário é obrigatório'),
    lcCode: Yup.string().required('Código LC é obrigatório'),
    nbsCode: Yup.string().required('Código NBS é obrigatório'),
    unitaryPrice: Yup.string().required('Preço unitário é obrigatório'),
    municipalityCode: Yup.string().required('Código Municipal é obrigatório'),
  });


interface IServicesProps {
	name: string
  description: string
  tributaryRegime: string;
  lcCode: string
  nbsCode: string
  unitaryPrice: string
  municipalityCode: string
}

export function useCreateServices({ listServiceInfoOrdersUseCase, createProductsServiceOrdersUseCase }: IUseCreateServicesProps){
    const [loading, setLoading] = useState(false)
    
    const [tributaryRegimeOptions, setTributaryRegimeOptions] = useState<IOptionsProps[]>()
    const [lcCode, setLcCode] = useState<IOptionsProps[]>()
    const [nbsCode, setNbsCode] = useState<IOptionsProps[]>()

    const params = useParams()
    
    const navigate = useNavigate()
    const { handleSubmit, register, control, formState } = useForm<IServicesProps>({
        resolver: yupResolver(CreateBoxSchemaValidation),
      });

      const { errors } =  formState

      const listServicesInfo = async () => {
        
        try {
          setLoading(true)
          
          const listResult = await listServiceInfoOrdersUseCase.handle();
  
          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }
  
         const list = listResult.getValue()
  
         const normalizeTributary = list?.tributaryRegimeCodes?.map(i => {
          return {
            value: i.cIdTrib,
            label: i.cDescricao
          }
         })

         const normalizeTLcCides = list?.lcCodes?.map(i => {
          return {
            value: i.cCodigo,
            label: i.cDescricao
          }
         })

         const normalizeNbsCodes = list?.nbsCodes?.map(i => {
          return {
            value: i.cNBS,
            label: i.cDescricao
          }
         })

         setTributaryRegimeOptions(normalizeTributary)
         setLcCode(normalizeTLcCides)
         setNbsCode(normalizeNbsCodes)


        } catch (error) {
          console.log("error listServices function: ", error)
        } finally {
          setLoading(false);
        }
      };

    const { showToast } =  useToast()

    

    const handleAction: SubmitHandler<IServicesProps> = async (values) => {
        console.log("values: ", values)
        try {
          setLoading(true)

          const payload : IProductsServicePayload = {
            name: values.name,
            description: values.description,
            lcCode: values.lcCode,
            municipalityCode: values.municipalityCode,
            nbsCode: values.nbsCode,
            tributaryRegime: values.tributaryRegime,
            unitaryPrice: values.unitaryPrice
          }
          
          
          const storeResult = await createProductsServiceOrdersUseCase.handle(payload);

          if (storeResult.isFailure) {
            
            showToast(String(storeResult?.error?.error), 'error');
            return;
          }

          showToast('Serviço Cadastrado', 'success');
         
        
          navigate('/services-list');
        } catch (error) {
            console.log("error: ", error)
            showToast(error.response?.data?.error ?? 'Erro. Por favor, tente novamente', 'error');
          // toast({
          //   position: 'top-right',
          //   render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao logar. Por favor, tente novamente'} />,
          // });
        } finally {
          setLoading(false);
        }
      };

      

    return {
        loading,
        handleSubmit,
        handleAction,
        register,
        tributaryRegimeOptions,
        errors,
        control,
        lcCode,
        nbsCode,
        listServicesInfo
        
    }
}