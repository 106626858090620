import { Box, SxProps, Theme } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { FC, useState } from "react";
import { IProductsListPayload } from "../../Services/api/products/products.interface";

interface ITableProps {
  columns: GridColDef[];
  data: any[];
  totalCount: number;
  onSelect?: (selectedIds: any[]) => void;
  onRowClick?: (row: any) => void;
  isRowSelectable?: (params: any) => boolean;
  onPageChange?: (data: IProductsListPayload) => void;
  search?: string
  sx?: SxProps<Theme>;
}

const Table: FC<ITableProps> = ({
  columns,
  data,
  totalCount,
  onSelect,
  onRowClick,
  isRowSelectable,
  onPageChange,
  search,
  ...rest
}) => {
  const [page, setPage] = useState(0); // 🟢 Página inicia como 0 (equivalente à página 1)

  const handleRowSelection = (selectionModel: any) => {
    if (onSelect) {
      onSelect(selectionModel);
    }
  };

  const handleRowClick = (params: any) => {
    if (onRowClick) {
      onRowClick(params.row);
    }
  };

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) { // 🛑 Evita chamadas desnecessárias
      setPage(newPage);
      if (onPageChange) {
        onPageChange({ page: newPage + 1 , search }); // 🟢 Converte para base 1 (page 1 = index 0)
      }
    }
  };

  const allRowsSelectable = data.every((row) =>
    isRowSelectable ? isRowSelectable({ row }) : true
  );

  return (
    <Box sx={{ minHeight: 600, width: "100%" }} {...rest}>
      <DataGrid
        rows={data}
        columns={columns}
        rowCount={totalCount}
        paginationMode="server"
        pageSizeOptions={[10]}
        paginationModel={{ pageSize: 10, page }}
        // page={page} // 🔹 Sincroniza com o estado
        rowHeight={80}
        checkboxSelection={allRowsSelectable}
        disableRowSelectionOnClick
        onRowSelectionModelChange={handleRowSelection}
        isRowSelectable={isRowSelectable}
        onRowClick={handleRowClick}
        onPaginationModelChange={(model) => handlePageChange(model.page)}
      />
    </Box>
  );
};

export { Table };
