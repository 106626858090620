
interface IEnvironmentProps {
  CUSTOMER: string
  IMAGE_PLACEHOLDER: string;
  OFFSET_REGISTERS: string
}

const Environment: IEnvironmentProps = {
  CUSTOMER: process.env.REACT_APP_CUSTOMER || '',
  IMAGE_PLACEHOLDER: 'https://acdn.mitiendanube.com/stores/002/907/105/themes/common/logo-1336738559-1706047471-a90c2b04f7208c4f190adf866d8df0b51706047472-320-0.webp',
  OFFSET_REGISTERS: process.env.OFFSET_REGISTERS || '8',
}

export { Environment }
