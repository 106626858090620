import { object, string } from 'yup'
import {
  AuthenticationApiRemoteService,
  TAuthLoginDTO,
  TAuthLoginResponse
} from '../../Services/api/auth/service-authentication'
import { StorageLocalstorage } from '../../Shared/storage/storage-localstorage'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'
import { setCookie } from '../../utils/cookies'
import { Environment } from '../../Config/constants'

export class LoginUseCase
implements IFormUsecase<TAuthLoginDTO, TAuthLoginResponse> {
  constructor (private readonly AuthApi: AuthenticationApiRemoteService) {}

  public async handle (
    data: TAuthLoginDTO
  ): Promise<Result<TAuthLoginResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.AuthApi.login(data)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao realizar login'))
      }

      const { sessionToken, refreshToken, username } = result

      StorageLocalstorage.set(`${Environment.CUSTOMER}:token`, sessionToken)

      StorageLocalstorage.set(`${Environment.CUSTOMER}:refresh-token`, refreshToken)


      StorageLocalstorage.set(`${Environment.CUSTOMER}:user`, {
        user: {
          username
        }
      })

     

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('E-mail é obrigatório'),
    password: string().required('Senha é obrigatória')
  })
}
