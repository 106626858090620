import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button } from '../../Form/Button/button.scomponent';
import { GoPlus } from 'react-icons/go';

import { ICubageVariantAssociated, ICubageVariantEntity, IUpdateCubageVariantPayload } from '../../../Services/api/cubages-variants/cubages-variants.interface';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid2, useTheme } from '@mui/material';
import { ActionButtons } from '../../Form/ActionButtons/action-buttons.component';
import { ConfirmDialog } from '../../Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { ICubageEntity } from '../../../Services/api/cubages/cubages.interface';
import { Flex } from '../../Flex/flex.component';
import { CubageVariantFormDrawer } from '../../Drawer/variants/cubage-variant-form/cuabge-variant-form-drawer.component';
import { IOptionsProps } from '../../Form/Select/select.component';
import { Placeholder } from '../../Placeholder/placeholder.component';
import { Switch } from '../../Form/Switch/switch.component';

import { AiOutlineDropbox } from 'react-icons/ai';
import { Control, useForm } from 'react-hook-form';
import { IProductProps } from '../../../Pages/Products/Form/create-products-hook';
import { v4 as uuid } from 'uuid'

interface ICubageAccordionProps {
  handleAddTemporaryCubage: (cubageVariant: ICubageVariantAssociated) => void
  handleRemoveTemporary: (cubageVariant: ICubageVariantAssociated) => void
   control: Control<IProductProps, any>
  data: ICubageEntity[]
  
 
}

const CubageAccordion : FC<ICubageAccordionProps> = ({ data, control, handleAddTemporaryCubage, handleRemoveTemporary  }) => {
  const [openRows, setOpenRows] = useState<{ [key: string]: boolean }>({});
  const theme = useTheme();
 
  const handleRowToggle = (rowId: string) => {
    setOpenRows((prev) => ({ ...prev, [rowId]: !prev[rowId] }));
  };


  function Row(props: { row: ICubageEntity }) {
    const { row } = props;
    const isOpen = openRows[row.id] || false;

    const handleSelect = (value: any, variant: ICubageVariantEntity) => {
      console.log("variant: ", variant)

      // setValue(`cubage-${cubageVariant?.cubageVariant?.cubage?.id}-variant-${cubageVariant.id}`, true)
      
      const payload :  ICubageVariantAssociated = {
        id: variant.id,
        cubageVariantId : variant.id,
        productId : `fake-temporary-id-${uuid()}`,
        cubageVariant: {
          ...variant,
          cubage: {
            id: variant.cubageId,
            createdAt: new Date()?.toString(),
            updatedAt: new Date()?.toString(),
            name: 'fake-name',
            normalizedName: 'fake-name',
            variants: []
          }
        }
      }

      if(value){
        handleAddTemporaryCubage(payload)
      } else {
        handleRemoveTemporary(payload)
      }
    } 
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
         
          <TableCell component="th" scope="row" width={'95%'}>
            {row.name}
          </TableCell>
          
          <TableCell  width={'10%'} >
            <Flex>
              
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleRowToggle(row.id)}
              >
                {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Flex>
          </TableCell>
        </TableRow>

        {row.variants.map((variant) => (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Grid2 size={12} key={variant.id} sx={{ border: 'solid 1px #ccc', padding: '15px', marginTop: '15px'}}>
                          <Switch label={`${variant. description} - ${variant.howManyInBox} Unidades`} name={`cubage-${row.id}-variant-${variant.id}`} control={control}
                          onChangeCallback={(e) => handleSelect(e, variant)}
                          // onChangeCallback={() => {}}
                           />
                              <Flex alignItems='center' marginTop='10px'>
                                  <AiOutlineDropbox size={18} color={theme.palette.text.secondary} />
                                  <Typography sx={{ color: '#999', fontSize: '16px', marginLeft: '5px'}}>{variant.box.code} - {variant.box.length}x{variant.box.width}x{variant.box.height} cm - {variant.boxWeight} kg </Typography>
                              </Flex>
                  </Grid2>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        ))}
    
       
      </React.Fragment>
    );
  }



   
  return(
    <>
      {!!data?.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
           
              <TableBody>
              {data.map((i) => (
                  <Row key={i.name} row={i} />
              ))}
              </TableBody>
          </Table>
        </TableContainer>
      ) : <Placeholder description='Nenhum dado encontrado.' /> }

    
    
    </>
  )

}

export { CubageAccordion }