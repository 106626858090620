import { ServiceOdersServiceApiRemoteService } from '../../Services/api/service-orders/service-orders-service'
import {  IServiceOrdersUpdateResponse, IUpdateServiceOrdersPayload } from '../../Services/api/service-orders/service-order.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class UpdateServiceOrdersUseCase
implements IFormUsecase<IUpdateServiceOrdersPayload, IServiceOrdersUpdateResponse> {
  constructor (private readonly ServiceOrdersApi: ServiceOdersServiceApiRemoteService) {}
    

  public async handle (
    payload: IUpdateServiceOrdersPayload
  ): Promise<Result<IServiceOrdersUpdateResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ServiceOrdersApi.update(payload)

      if (response.isFailure) {
        return response
      }

      const Update = response.getValue()

      if(!Update) return response

    
      return Result.ok(Update)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
