import { Breadcrumbs, Divider, Link, Paper, SxProps, Theme, Typography, useTheme } from '@mui/material'
import React, { FC, ReactElement, ReactNode } from 'react'
import { Flex } from '../../Flex/flex.component';
import { Button } from '../../Form/Button/button.scomponent';
import { FaArrowCircleLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { title } from 'process';
import { SearchInput } from '../../Form/InputSearch/input-search.component';
import { IProductsListPayload } from '../../../Services/api/products/products.interface';

interface IBreadCrumbItem {
    title: string;
    link?: string;
}


interface IBaseContentPageProps {
    children: ReactNode;
    breadcrumb?: IBreadCrumbItem[];
    title: string;
    Action?: () => ReactElement;
    backButton?: boolean
    backUrl?:string
    titleBoxStyle?: object
    sx?: SxProps<Theme>;
    search?: boolean
    searchFunction?: (data: IProductsListPayload) => void
}


const BaseContentPage: FC<IBaseContentPageProps> = ({ children, breadcrumb, title, Action, backButton = false, backUrl, titleBoxStyle = {}, search = false, searchFunction,  ...rest }) => {
    const theme = useTheme();
    const navigate = useNavigate()
    return (
       <Paper sx={{ display: 'flex', flexDirection: 'column', flex: 1, padding: '24px', minHeight: '600px', ...rest}}>
            <Flex sx={{ width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                <Flex sx={{ alignItems: 'center',  ...titleBoxStyle}}>
                    {backButton && <Button variant='text' sx={{ width: '30px', marginTop: '5px', marginLeft: '-18px'}} onClick={() => navigate(String(backUrl))}><FaArrowCircleLeft size={25} /></Button>}
                    <Typography sx={{ fontSize: '1.5rem', fontWeight: 700, marginBottom: '5px' }}>{title}</Typography>
                   
                </Flex>

                {search && searchFunction && (
                    <SearchInput
                        sx={{ width: '60%', }}
                        placeholder="Pesquisar"
                        onSearch={searchFunction}
                    />
                )}
                {Action && <Action />}
                
              
            </Flex>
            
            {breadcrumb?.length && (
                <Breadcrumbs aria-label="breadcrumb">
                    {breadcrumb.map((item) => (
                        item.link ? (
                            <Link
                                underline="hover"
                                color="inherit"
                                href={item.link}
                                sx={{ color: theme.palette.primary.dark, fontSize: '0.875rem', fontWeight: 500 }}
                                key={item.title}
                            >
                                {item.title}
                            </Link>
                        ) : (
                            <Typography
                                sx={{ color: theme.palette.text.secondary, fontSize: '0.875rem', fontWeight: 500 }}
                                key={item.title}
                            >
                                {item.title}
                            </Typography>
                        )
                    ))}
                </Breadcrumbs>
            )}

            {breadcrumb?.length && <Divider sx={{ marginTop: '10px', marginBottom: '20px' }} /> }
            
            {children}
       </Paper>
    );
};

export { BaseContentPage  }