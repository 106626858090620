import { Grid2, Paper } from '@mui/material';
import React, { FC } from 'react';
import * as Yup from 'yup';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { Input } from '../../../Components/Form/Input/input.component';
import { Loader } from '../../../Components/Loader';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';

import { CreateBoxesUseCase } from '../../../Usecase/Boxes/create-boxes-usecase';
import { useCreateBoxes } from './boxes-create.hook';
import { ShowBoxesUseCase } from '../../../Usecase/Boxes/show-boxes-usecase';
import { UpdateBoxesUseCase } from '../../../Usecase/Boxes/update-boxes-usecase';


interface ProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    description: string
  }
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });



  interface IBoxFormProps {
    createBoxesUseCase: CreateBoxesUseCase
    showBoxesUseCase: ShowBoxesUseCase
    updateBoxesUseCase: UpdateBoxesUseCase
  }
const BoxForm : FC<IBoxFormProps> = ({ createBoxesUseCase, showBoxesUseCase, updateBoxesUseCase }) => {

    const {handleAction, handleSubmit, loading, register, heightValue, lengthValue, setHeightValue, setLenghValue, setWidthValue, widthValue, errors, data } = useCreateBoxes({
      createBoxesUseCase,
      showBoxesUseCase,
      updateBoxesUseCase
    })

    
    return (
        <BaseScreen>
         
            <Paper >
                <BaseContentPage 
                    title='Caixas'
                    breadcrumb={[{ title: 'Caixas', link: "/box-list"}, { title: 'Nova Caixa'}]}
                >

                {loading && <Loader />}

                <Grid2 container spacing={5} sx={{ marginTop: 2}} component="form" onSubmit={handleSubmit(handleAction)}>


                <Grid2 size={8}>
                    <Input
                        label="Código da Caixa"
                        placeholder="Ex: CP 100/180"
                        error={!!errors.code}
                        helperText={errors.code?.message}
                        {...register('code')}
                        
                    />
                </Grid2>

                <Grid2 size={4}>
                    <Input
                        label="Tamanho da caixa"
                        disabled
                        placeholder="Preencher em Cm"
                        value={`${lengthValue}x${widthValue}x${heightValue} cm` }
                        name='size'
                        onChange={() => {}}
                        
                    />
                </Grid2>

                <Grid2 size={4}>
                    <Input
                        label="Comprimento"
                        placeholder="Preencher em Cm"
                        error={!!errors.length}
                        helperText={errors.length?.message}
                        callbackfunction={(e) => setLenghValue(Number(e))}
                        {...register('length')}
                        
                    />
                </Grid2>

                <Grid2 size={4}>
                  <Input
                        label="Largura"
                        placeholder="Preencher em Cm"
                        error={!!errors.width}
                        helperText={errors.width?.message}
                        {...register('width')}
                        callbackfunction={(e) => setWidthValue(Number(e))}
                        
                    />
                  
                </Grid2>

                <Grid2 size={4}>
                <Input
                        label="Altura"
                        placeholder="Preencher em Cm"
                        error={!!errors.height}
                        helperText={errors.height?.message}
                        {...register('height')}
                        callbackfunction={(e) => setHeightValue(Number(e))}
                        
                    />
                  
                  
                </Grid2>                

                <Button type="submit" sx={{ marginTop: '10px', width: '200px' }} isLoading={loading}>
                    SALVAR
                </Button>
                </Grid2>

              </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { BoxForm };
