import React, { FC } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { Button } from '../../../Form/Button/button.scomponent';

interface IConfirmDialogProps {
  open: boolean;
  title: string;
  description: string;
  onConfirm: (data?: any) => void;
  onCancel: () => void;
}

const ConfirmDialog: FC<IConfirmDialogProps> = ({
  open,
  title,
  description,
  onConfirm,
  onCancel,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirm-dialog-title"
      maxWidth="md" // Define o tamanho máximo para um diálogo maior
      fullWidth // Expande o diálogo para a largura completa dentro do maxWidth
      sx={{ '& .MuiDialog-paper': { padding: '24px', minHeight: '300px', zIndex: 999999999999} }} // Estiliza o conteúdo do papel do diálogo
    >
      <DialogTitle>
        <Typography fontSize="24px" fontWeight="600">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ fontSize: '18px', marginBottom: '16px' }}>
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant='text'>
          Cancelar
        </Button>
        <Button onClick={onConfirm} variant="contained">
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export { ConfirmDialog };
