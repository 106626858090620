import React, { useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { CreateCubagesUseCase } from "../../../Usecase/Cubages/create-cubages-usecase";
import { ListCubagesUseCase } from "../../../Usecase/Cubages/list-cubages-usecase";
import { ICubageEntity, IUpdateCubageProductPayload } from "../../../Services/api/cubages/cubages.interface";
import { CreateCubagesVariationsUseCase } from "../../../Usecase/CubagesVariations/create-cubages-variations-usecase";
import { ListBoxesUseCase } from "../../../Usecase/Boxes/list-boxes-usecase";
import { IOptionsProps } from "../../../Components/Form/Select/select.component";
import { ICreateCubageVariantPayload, ICubageVariantEntity, IUpdateCubageVariantPayload } from "../../../Services/api/cubages-variants/cubages-variants.interface";
import { ShowCubagesVariationsUseCase } from "../../../Usecase/CubagesVariations/show-cubages-variations-usecase";
import { UpdateubagesVariationsUseCase } from "../../../Usecase/CubagesVariations/update-cubages-variations-usecase";
import { DestroyCubagesVariationsUseCase } from "../../../Usecase/CubagesVariations/destroy-cubages-variations-usecase";
import { ShowCubagesUseCase } from "../../../Usecase/Cubages/show-cubages-usecase";
import { UpdateCubagesUseCase } from "../../../Usecase/Cubages/update-cubages-usecase";
import { DestroyCubagesUseCase } from "../../../Usecase/Cubages/destroy-cubages-usecase";


interface IUseCubagesProps {
  listProductUseCase: ListProductUseCase
  createCubagesUseCase: CreateCubagesUseCase
  listCubagesUseCase: ListCubagesUseCase
  createCubagesVariationsUseCase: CreateCubagesVariationsUseCase
  listBoxesUseCase: ListBoxesUseCase
  showCubagesVariationsUseCase: ShowCubagesVariationsUseCase
  updateubagesVariationsUseCase: UpdateubagesVariationsUseCase
  destroyCubagesVariationsUseCase: DestroyCubagesVariationsUseCase
  showCubagesUseCase: ShowCubagesUseCase
  updateCubagesUseCase: UpdateCubagesUseCase
  destroyCubagesUseCase: DestroyCubagesUseCase
}
export function useCubages({ listProductUseCase, createCubagesUseCase, listCubagesUseCase, createCubagesVariationsUseCase, listBoxesUseCase, showCubagesVariationsUseCase, updateubagesVariationsUseCase,destroyCubagesVariationsUseCase, showCubagesUseCase, updateCubagesUseCase,destroyCubagesUseCase }: IUseCubagesProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<ICubageEntity[]>([])
    const [showNewCubage, setShowNewCubage] = useState(false)
    const [cubageProductIdSelected, setCubageProductIdSelected] = useState('')
    const [boxOptions, setBoxOptions] = useState<IOptionsProps[]>()
    const [cubageVariantSelected, setCubageVariantSelected] = useState<ICubageVariantEntity | undefined>(undefined)
    const [cubageVariantToDestroy, setCubageVariantToDestroy] = useState('')
    const [variantProductSelected, setVariantProductSelected] = useState<ICubageEntity | undefined>(undefined)
    const [variantProductToDestroy, setVariantProductToDestroy] = useState<string>('')

    // const [cubageSelected, setCubageSelected] = useState<ICubageEntity | undefined>(undefined)
    // const [cubageToDestroy, setCubageToDestroy] = useState('')


    const { showToast } =  useToast()

    const listCubages = async (currentPage: number) => {
        
        try {
          setLoading(true)
          
          const listResult = await listCubagesUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()
         console.log("listCubages list:", list)

         if(list){
            setData(list)
         }

         setPage(currentPage)
        } catch (error) {
          console.log("error Cubages function: ", error)
        } finally {
          setLoading(false);
        }
    };

    const list = useMemo(() => {
        const rows = data?.map(product => {

            return {
                id: product.id,
                name: product.name,
                variants: product.variants
            }
        })

        return rows
    }, [data])

    const handleCreateCubage = async (name: string) => {
        
      try {
        setLoading(true)
        
        const createResult = await createCubagesUseCase.handle(name);

        if (createResult.isFailure) {
          showToast(String(createResult?.error?.error), 'error');
          return;
        }

        showToast('Cadastrado com sucesso', 'success');
        setShowNewCubage(false)
        listCubages(1)
      
      } catch (error) {
        console.log("error create Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const handleCreateCubageVariation = async (data: ICreateCubageVariantPayload) => {
        
      try {
        setLoading(true)
      
        const createResult = await createCubagesVariationsUseCase.handle(data);

        if (createResult.isFailure) {
          showToast(String(createResult?.error?.error), 'error');
          return;
        }

        showToast('Cadastrado com sucesso', 'success');
        setShowNewCubage(false)
        setCubageProductIdSelected('')
        await listCubages(1)
      } catch (error) {
        console.log("error create Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const listBoxes = async (currentPage: number) => {
        
      try {
        setLoading(true)
        
        const listResult = await listBoxesUseCase.handle(currentPage);

        if (listResult.isFailure) {
     
          showToast(String(listResult?.error?.error), 'error');
          return;
        }

       const list = listResult.getValue()
    
       if(list){
          const filtered = list.map(i => {
            return {
              value: i.id,
              label: `${i.code} - ${`${i.length}x${i.width}x${i.height} cm`}`
            }
          })

          console.log("filtered: ", filtered)

          setBoxOptions(filtered)
       }

       setPage(currentPage)
      } catch (error) {
        console.log("error Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const handleUpdateCubageVariation = async (data: IUpdateCubageVariantPayload) => {
        
      try {
        setLoading(true)
      
        const updateResult = await updateubagesVariationsUseCase.handle(data);

        if (updateResult.isFailure) {
          showToast(String(updateResult?.error?.error), 'error');
          return;
        }

        showToast('Editado com sucesso', 'success');
        setShowNewCubage(false)
        setCubageProductIdSelected('')
        await listCubages(1)
      } catch (error) {
        console.log("error update Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const handleDestroyVariant = async (variationId: string) => {
        
      try {
        setLoading(true)
        
        const destroyResult = await destroyCubagesVariationsUseCase.handle(variationId);

        if (destroyResult.isFailure) {
          showToast(String(destroyResult?.error?.error), 'error');
          return;
        }

        showToast('Removido com sucesso', 'success');
        setShowNewCubage(false)
        setCubageVariantToDestroy('')
        await listCubages(1)
      } catch (error) {
        console.log("error destroy Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };


    const handleShowCubage = async (cubageId: string) => {
        
      try {
        setLoading(true)
        
        const showResult = await showCubagesUseCase.handle(cubageId);

        if (showResult.isFailure) {
          showToast(String(showResult?.error?.error), 'error');
          return;
        }

        const result = showResult.getValue()

       if(result?.id) {
        setVariantProductSelected(result)
       }
      
      } catch (error) {
        console.log("error create Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const handleUpdateCubage = async (data: IUpdateCubageProductPayload) => {
        
      try {
        setLoading(true)

        const updateResult = await updateCubagesUseCase.handle(data);

        if (updateResult.isFailure) {
          showToast(String(updateResult?.error?.error), 'error');
          return;
        }

        showToast('Atualizado com sucesso', 'success');
        setVariantProductSelected(undefined)
        listCubages(1)
      
      } catch (error) {
        console.log("error create Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const handleDestroyCubage = async (cubageId: string) => {
        
      try {
        setLoading(true)
        
        const destroyResult = await destroyCubagesUseCase.handle(cubageId);

        if (destroyResult.isFailure) {
          showToast(String(destroyResult?.error?.error), 'error');
          return;
        }

        showToast('Removido com sucesso', 'success');
        setShowNewCubage(false)
        setVariantProductToDestroy('')
        listCubages(1)
        await listCubages(1)
      } catch (error) {
        console.log("error destroy Cubages function: ", error)
      } finally {
        setLoading(false);
      }
    };

      

    return {
        loading,
        listCubages,
        list,
        data,
        showNewCubage,
        setShowNewCubage,
        handleCreateCubage,
        handleCreateCubageVariation,
        setCubageProductIdSelected,
        cubageProductIdSelected,
        listBoxes,
        boxOptions,
        setCubageVariantSelected,
        cubageVariantSelected,
        handleUpdateCubageVariation,
        cubageVariantToDestroy,
        setCubageVariantToDestroy,
        handleDestroyVariant,
        variantProductSelected,
        setVariantProductSelected,
        variantProductToDestroy,
        setVariantProductToDestroy,
        handleShowCubage,
        handleUpdateCubage,
        handleDestroyCubage
    }
}