
import { Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineDot, TimelineContent } from "@mui/lab";
import { Box, Paper, Tabs, Tab, Grid2, Typography, useTheme } from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { KanbanBoardFinancial } from "../../../Components/KanbanBoardFinancial/kanban-board.component";
import { KanbanBoardProduction } from "../../../Components/KanbanBoardProduction/kanban-board.component";
import { SaleProductTable } from "../../../Components/Table/variants/sale-product-table/sale-product-table.component";
import { ISaleEntity, TSalePaymentStatus, TDeliveryPaymentStatus } from "../../../Services/api/sales/sales.interface";
import { BillProductOrderOrdersUseCase } from "../../../Usecase/Orders/bill-products-order-usecase";
import { ListOrdersUseCase } from "../../../Usecase/Orders/list-orders-usecase";
import { useDetailsFinancial } from "./financial-details.hook";
import HotelIcon from '@mui/icons-material/Hotel';
import { BaseContentPage } from "../../../Components/Templates/BaseContentPage/base-content-page.componen";
import { BaseScreen } from "../../../Components/Templates/BaseScreen/basescreen.component";
import { ShowOrdersUseCase } from "../../../Usecase/Orders/show-order-usecase";
import { Placeholder } from "../../../Components/Placeholder/placeholder.component";
import { Loader } from "../../../Components/Loader";
import { ShowOmieOrderDetailsUseCase } from "../../../Usecase/Orders/show-omie-order-details-usecase";

interface IFinancialDetailsProps {
    billProductOrderOrdersUseCase: BillProductOrderOrdersUseCase;
    listOrdersUseCase: ListOrdersUseCase
    showOrdersUseCase: ShowOrdersUseCase
    showOmieOrderDetailsUseCase: ShowOmieOrderDetailsUseCase
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const FinancialDetails : FC<IFinancialDetailsProps> = ({ billProductOrderOrdersUseCase, listOrdersUseCase, showOrdersUseCase, showOmieOrderDetailsUseCase }) => {
    const [value, setValue] = React.useState(0);
    const params = useParams()
    const [tabIndex, setTabIndex] = useState(0);
    const theme = useTheme()

  const { billProductsOrder, handleShowOrder, orderData, kanbanFinancialSchema, kanbanProductionSchema, sale, handleSelectProductToBill, productsToBill, loading, handleDetailsOmieOrder, omieOrderData, setOmieOrderData } = useDetailsFinancial({
    billProductOrderOrdersUseCase,
    listOrdersUseCase,
    showOrdersUseCase,
    orderId: String(params.orderId),
    showOmieOrderDetailsUseCase
  })


  useEffect(() => {
    if(params.orderId){
      handleShowOrder(params.orderId)
    }
  }, [])


  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ marginTop: '20px'}}>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }


  return (
   
     <BaseScreen withSideBar={false}>
      {loading && <Loader />}
      <Paper >
       
          <BaseContentPage 
              title={`Detalhes - Pedido ${orderData?.orderId}`}
              backButton
              backUrl='/financial-list'
              breadcrumb={[{ title: 'Pedidos', link: "/orders-list"}, { title: 'Listagem'}]}
              sx={{ minHeight: '60px', paddingBottom: 0}}
              titleBoxStyle={{ width: 'auto'}}
              Action={() =>  <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Pedido" {...a11yProps(0)} />
              <Tab label="Produção" {...a11yProps(1)} />
            <Tab label="Financeiro" {...a11yProps(2)} />
        </Tabs>}
          >

        
        </BaseContentPage>
        </Paper>

          {/* Conteúdo das Tabs */}
            
          <CustomTabPanel value={value} index={0} >
                <Grid2 container spacing={4}>
                    <Grid2 size={8} sx={{ borderBottom: 'solid 1px dashed'}}>
                      {sale?.id ?  <SaleProductTable sale={sale}/> :  <Placeholder description="Erro ao carregar pedido." />}
                     
                    </Grid2>

                    <Grid2 size={4}>
                      <Paper sx={{ padding: '20px'}}>
                        <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Dados do cliente</Typography>

                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.name}</Typography>

                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.email}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.phone}</Typography>
                      
                        </Paper>

                        <Paper sx={{ padding: '20px', marginTop: '30px'}}>
                          <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Endereço de entrega</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.name}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.phone}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.delivery_address?.address}</Typography>
                        </Paper>

                        <Paper sx={{ padding: '20px', marginTop: '30px'}}>
                          <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Endereço de cobrança</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.name}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.phone}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.delivery_address?.address}</Typography>
                        </Paper>

                        <Paper sx={{ padding: '20px', marginTop: '30px'}}>
                          <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Histórico</Typography>
                          
                          <Timeline position="right">
                              <TimelineItem>
                              <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                  <HotelIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                              </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px' }}>
                                <Typography variant="h6" component="span">
                                  Venda Registrada
                                </Typography>
                                <Typography>Por iehwefdr@guerrillamailblock.com de Pedidos manuais</Typography>
                              </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                  <HotelIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                              </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                  Venda Registrada
                                </Typography>
                                <Typography>Por iehwefdr@guerrillamailblock.com de Pedidos manuais</Typography>
                              </TimelineContent>
                            </TimelineItem>
                      
                          </Timeline>
                        </Paper>
                    </Grid2>
                    
              
                </Grid2>
              
            </CustomTabPanel>
        
            <CustomTabPanel value={value} index={1}>

            {!!kanbanProductionSchema?.columnOrder?.length ? <KanbanBoardProduction initialData={kanbanProductionSchema}
                  canClick={false}
                  canDrag={false}
                  handleShowOrderDetails={() => {}} 
                  handleConfirmProductionItems={() => {}}
                  setOrderDetails={() => {}}
                  /> : <Placeholder description='Nenhum dado encontrado.' /> }

             
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>

                  {!!kanbanFinancialSchema?.columnOrder?.length ? <KanbanBoardFinancial setOmieOrderData={setOmieOrderData} omieDetailsOrder={omieOrderData} handleDetailsOmieOrder={handleDetailsOmieOrder} handleSelectProductToBill={handleSelectProductToBill} billProductsOrder={billProductsOrder} initialData={kanbanFinancialSchema}
                  canClick={true}
                  canDrag={false}
                  productsToBill={productsToBill}
                  /> : <Placeholder description='Nenhum dado encontrado.' /> }
              
            </CustomTabPanel>
      </BaseScreen>
         
       

  );
};

export default FinancialDetails;
