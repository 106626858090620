import { Box, Paper, Typography,  useTheme } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { FiEye } from 'react-icons/fi';
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import SaleDetailsModal from '../../../Components/Modals/variants/sale-details/sale-details-variant.component';
import { Table } from '../../../Components/Table/table.component';
import { Tag } from '../../../Components/Tag/tag.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { ListProductUseCase } from '../../../Usecase/Products/list-product-usecase';
import { normalizeDeliveryPaymentStatusColors, normalizeDeliveryPaymentStatusTitle } from '../../../utils/normalizeSaleDeliveryStatus';
import { normalizeSalePaymentStatusColors, normalizeSalePaymentStatusTitle } from '../../../utils/normalizeSalePaymentStatus';
import { useListFinancial } from './financial-list.hook';
import { normalizeFinancialStatusTitle, normalizeSFinancialStatusColors } from '../../../utils/normalizeFinancialStatus';
import { IoFilterOutline } from "react-icons/io5";
import { FinancialFiltersDrawer } from '../../../Components/Drawer/variants/financial-filters/financial-filters-drawer.component';
import { ListOrdersUseCase } from '../../../Usecase/Orders/list-orders-usecase';
import { BillProductOrderOrdersUseCase } from '../../../Usecase/Orders/bill-products-order-usecase';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';

interface IFinancialList {
  listOrdersUseCase: ListOrdersUseCase;
  billProductOrderOrdersUseCase: BillProductOrderOrdersUseCase
}
const FinancialList : FC<IFinancialList> = ({ listOrdersUseCase, billProductOrderOrdersUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listOrders, list, data, handleShowDetails, showDetails, setShowDetails, loading, setShowFiltersModal, showFiltersModal, kanbanSchema, billProductsOrder } = useListFinancial({
      listOrdersUseCase,
      billProductOrderOrdersUseCase
    })

    console.log("financial list: ", list)

    useEffect(() => {
        listOrders(1)
    }, [])


    const columns = useMemo(() => {
        const createColumns: any[] = [ 
          {
            field: 'code',
            headerName: 'Pedido',
            width: 150,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{`#${params?.row?.orderId}`}</Typography></Box>
          },
          {
            field: 'createdAt',
            headerName: 'Data',
            width: 150,
          },
          {
            field: 'customer',
            headerName: 'Cliente',
            width: 200,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography >Diego Hennrich</Typography></Box>
          },
          {
            field: 'totalValue',
            headerName: 'Total',
            width: 150,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography >{`R$ ${params?.row?.totalValue}`}</Typography></Box>
          },
          {
            field: 'products',
            headerName: 'Produtos',
            width: 150,
            renderCell: (params: any) => {
              console.log("params: ", params)
              return <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography >{`${params?.row?.products?.length}`}</Typography></Box>
            } 
          },
          {
            field: 'payment_status',
            headerName: 'Status de Faturamento',
            width: 250,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center', margin: 0, padding: 0}}><Tag title={normalizeFinancialStatusTitle(params.row.productionStatus)} boxStyle={{ backgroundColor: normalizeSFinancialStatusColors(params.row.productionStatus)?.bgColor }} titleStyle={{ color: normalizeSFinancialStatusColors(params.row.productionStatus)?.textColor, fontSize: '15px' }} /></Box>
          },
          // // {
          // //   field: 'delivery_status',
          // //   headerName: 'Status de Envio',
          // //   width: 200,
          // //   renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center', margin: 0, padding: 0}}><Tag title={normalizeDeliveryPaymentStatusTitle(params.value)} boxStyle={{ backgroundColor: normalizeDeliveryPaymentStatusColors(params.value)?.bgColor }} titleStyle={{ color: normalizeDeliveryPaymentStatusColors(params.value)?.textColor, fontSize: '15px' }} /></Box>
          // // },
          {
            field: 'id',
            flex: 1,
            headerName: 'Ações',
            width: 150,
            // renderCell: (params: any) => <ButtonActionMenu options={[
            //   {
            //     title: 'Marcar como embalada',
            //     onClick: () => {}
            //   },
            //   {
            //     title: 'Notificar Envio',
            //     onClick: () => {}
            //   },
            //   {
            //     title: 'Arquivar',
            //     onClick: () => {}
            //   }
            // ]} />
            renderCell: (params: any) => {
              console.log("params: ", params)
            return <Box sx={{height: '100%', display: 'flex', alignItems: 'center', margin: 0, padding: 0}}><Button style={{ height: '40px', width: '140px', gap: '10px'}} variant='outlined' onClick={() => navigate(`/financial-details/${params.row.id}`)} LeftIcon={() => <FiEye size={23} />} leftIconSx={{ marginRight: 2}} textSx={{ fontSize: '14px'}}>Detalhes</Button></Box>
            }
          },
        ];
      
        return createColumns;
      }, []);
   

    return (
        <BaseScreen>
            <Paper >

            <FinancialFiltersDrawer handleSelect={e => {}} open={showFiltersModal} title='Filtros' onToggle={() => setShowFiltersModal(prev => !prev)}  />
 
              {showDetails &&  kanbanSchema?.columnOrder?.length && <SaleDetailsModal billProductsOrder={billProductsOrder} onClose={() => setShowDetails(undefined)} data={showDetails} fromFinancial type='financial' kanbanSchema={kanbanSchema} canDragProductionKanban={false} canClickProductionKanban={false} canDragFinancialKanban={false} canClickFinancialKanban={true} />}

                <BaseContentPage 
                    title='Financeiro'
                    breadcrumb={[{ title: 'Financeiro', link: "/financial-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <IoFilterOutline size={18} />} sx={{ width: '100px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => setShowFiltersModal(true)}>Filtros</Button>}
                >
                  {!!list?.length ?  <Table data={list} columns={columns} totalCount={0} /> : <Placeholder description='Nenhum dado encontrado.' /> }

                  
                  
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { FinancialList };
