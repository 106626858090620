import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Paper, useTheme } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { Flex } from '../../../Components/Flex/flex.component';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';
import { Table } from '../../../Components/Table/table.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { ListProductsServiceUseCase } from '../../../Usecase/ProductsService/list-products-service-usecase';
import { useServicesList } from './services-list.hook';
import { ActionButtons } from '../../../Components/Form/ActionButtons/action-buttons.component';

interface IServicesList {
  ListProductsServiceUseCase: ListProductsServiceUseCase
}
const ServicesList : FC<IServicesList> = ({ ListProductsServiceUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listServices, list, setBoxToDestroy, boxToDestroy } = useServicesList({
      ListProductsServiceUseCase
      
    })

    useEffect(() => {
      listServices(1)
    }, [])


    const columns = useMemo(() => {
        const createColumns: any[] = [
        
          {
            field: 'name',
            headerName: 'Código',
            width: 300,
          },
          {
            field: 'size',
            headerName: 'Tamanho',
            width: 300,
          },
          {
            field: "actions",
            headerName: "Ações",
            headerAlign: "right",
            flex: 1,
            renderCell: (params: any) => <ActionButtons params={params} setItemToDestroy={setBoxToDestroy} editLink={`/services-form/${params.row.id}`} />
          }
        ];
      
        return createColumns;
      }, []);
   

    return (
        <BaseScreen>
        {/* <ConfirmDialog title='Remover Caixa' onCancel={() => setBoxToDestroy('')} description='Você tem certeza?' open={!!boxToDestroy} onConfirm={handleDestroyBox}  />  */}
            <Paper >
                <BaseContentPage 
                    title='Serviços'
                    breadcrumb={[{ title: 'Serviços', link: "/services-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '130px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => navigate('/services-form')}>Adicionar</Button>}
                >
                  {!!list?.length ? <Table data={list} totalCount={0} columns={columns} /> : <Placeholder description='Nenhum dado encontrado.' />}
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { ServicesList };
