
import { Box, Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect, useMemo } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';
import { complementOptions, customerOptions, familyOptions, materialOptions, typeOptions, versionsOptions, volumeOptions } from './internal-code-options';


import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { IoCloseSharp } from "react-icons/io5";

import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Radio } from '../../../Form/Radio/radio-input.component';
import { Flex } from '../../../Flex/flex.component';


const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<unknown>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  

interface IDrawerComponent extends DrawerProps {
    onToggle: (state: any) => void;
    title: string;
    subTitle?: string;
    search?: boolean;
    searchFunction?: () => void;
 
    open: boolean;
    handleSelect: (e: string) => void;
    code: string;
    titleStyle?: object

}

// Dentro do seu componente InternalCodeDrawer
const InternalCodeDrawer : FC<IDrawerComponent> = ({ onToggle, title, subTitle, open, titleStyle, handleSelect }) => {
    const theme = useTheme();
    const { handleSubmit, register, control, formState, watch } = useForm({
        resolver: yupResolver(SignInSchemaValidation),
    });
    const { errors } = formState;

   

    const type = useWatch({ name: 'type', control }); 
    const family = useWatch({ name: 'family', control }); 
    const volume = useWatch({ name: 'volume', control }); 
    const material = useWatch({ name: 'material', control }); 
    const complement = useWatch({ name: 'complement', control }); 
    const version = useWatch({ name: 'version', control }); 
    const customer = useWatch({ name: 'customer', control }); 

    const makeCode = `${type || ''}${family || ''}${volume || ''}${material || ''}${complement || ''}${version || ''}${customer || ''}`;

    const onSubmit = () => {
        
        handleSelect(makeCode)
        onToggle(false)
    };
    

    // const handleClickOpen = () => {
    //     setOpen(true);
    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <Dialog
        fullScreen
        open={open}
        onClose={onToggle}
        TransitionComponent={Transition}
        sx={{ zIndex: 999999999999999999}}
      >
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar sx={{justifyContent: 'space-between', display: 'flex', alignItems: 'center', with: '100%'}}>
            <IoCloseSharp
              size={28}
              color='#fff'
              onClick={onToggle}
            />
            <Typography sx={{ ml: 2, flex: 1, color: '#fff' }} variant="h6" component="div">
              {makeCode}
            </Typography>
            {/* <Button autoFocus color="inherit" onClick={onToggle}>
              save
            </Button> */}
          </Toolbar>
        </AppBar>
        <Flex sx={{ padding: '20px', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
        <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle }}>Código Interno</Typography>
        <Button onClick={onSubmit} sx={{ width: '120px'}}>SALVAR</Button>
        </Flex>
        <Divider />
      
        <Grid2 container  sx={{ minWidth: '100%', width: '100%' }}>
                
    {/* Grade com as colunas de botões de rádio */}
    <Grid2 
        container 
        spacing={2} 
        sx={{ 
            marginTop: '20px', 
            overflowX: 'auto',  // Scroll horizontal
            overflowY: 'auto',  // Scroll vertical
            flexWrap: 'nowrap', // Itens não quebram em nova linha (horizontal)
            display: 'flex',
            width: '100vw',
            height: '82vh',
            padding: '30px',
            paddingBottom: '40px'
        }}
    >
        {/* Cada Grid2 representa uma "coluna" na tabela */}
        <Grid2  sx={{ minWidth: '150px', flex: '0 0 auto' }}>
            <Radio
                name="type"
                label='Tipo de Produto'
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                control={control}
                options={typeOptions}
                error={!!errors.type}
                helperText={String(errors.type?.message)}
            />
        </Grid2>

        <Grid2  sx={{ minWidth: '150px', flex: '0 0 auto' }}>
            <Radio
                label="Família de Produto"
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                name="family"
                control={control}
                options={familyOptions}
                error={!!errors.family}
                helperText={errors?.family?.message}
            />
        </Grid2>

        <Grid2  sx={{ minWidth: '150px', flex: '0 0 auto' }}>
            <Radio
                label="Volume"
                name="volume"
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                control={control}
                options={volumeOptions}
                error={!!errors.volume}
                helperText={errors.volume?.message}
            />
        </Grid2>

        <Grid2  sx={{ width: '380px', flex: '0 0 auto' }}>
            <Radio
                label="Matéria Prima Principal ou Versão para equipamentos"
                name="volume"
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                control={control}
                options={materialOptions}
                error={!!errors.material}
                helperText={errors.material?.message}
            />
        </Grid2>

        <Grid2  sx={{ width: '380px', flex: '0 0 auto' }}>
            <Radio
                label="Tipo de acabamento, personalização ou tipo de tampa plástica"
                name="complement"
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                control={control}
                options={complementOptions}
                error={!!errors.complement}
                helperText={errors.complement?.message}
            />
        </Grid2>

        <Grid2  sx={{ minWidth: '150px', flex: '0 0 auto' }}>
            <Radio
                label="Versão do produto"
                name="version"
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                control={control}
                options={versionsOptions}
                error={!!errors.version}
                helperText={errors.version?.message}
            />
        </Grid2>

        <Grid2  sx={{ width: '400px', flex: '0 0 auto' }}>
            <Radio
                label="Código do cliente (número do cadastro do cliente na base de dados, quando aplicável)"
                name="customer"
                labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                control={control}
                options={customerOptions}
                error={!!errors.customer}
                helperText={errors.customer?.message}
            />
        </Grid2>
    </Grid2>
</Grid2>

      </Dialog>

       
    );
};

// Certifique-se de atualizar o esquema de validação do Yup
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
    // Adicione os novos campos para validação
    type: Yup.string().required('Selecione um tipo'),
    version: Yup.string().required('Selecione uma versão'),
    // Outros campos conforme necessário
});

export { InternalCodeDrawer }