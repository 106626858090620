import React, { FC, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DroppableProps } from 'react-beautiful-dnd';
import { Box, Card, CardContent, Typography, Grid2, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@mui/material';
import { Button as ButtonCustom } from '../Form/Button/button.scomponent'
import { StrictModeDroppable } from './components/droppable-component';
import { Flex } from '../Flex/flex.component';
import { Table } from '../Table/table.component';
import OsDetailsModal from '../Modals/variants/os-details/os-details-variant.component';
import { BsCurrencyDollar } from 'react-icons/bs';
import IKanbanBoardFinancialSchemaProps from '../KanbanBoardFinancial/interfaces/kanban-board-financial-schema.interface';
import { ISaleEntity } from '../../Services/api/sales/sales.interface';
import { list } from '@chakra-ui/react';
import { ConfirmDialog } from '../Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { IBillProductOrderPayload, IOrdersRegisteredOnOmieEntity } from '../../Services/api/orders/orders.interface';
import OmieOrderDetailsModal from '../Modals/variants/omie-order-details/omie-order-details-variant.component';



interface IKanbanBoardFinancialProps {
  initialData?: IKanbanBoardFinancialSchemaProps
  handleSelectProductToBill: (productId?:string) => void;
  handleDetailsOmieOrder: (omieOrderId?: string) => Promise<void>
  omieDetailsOrder?: IOrdersRegisteredOnOmieEntity
  
  billProductsOrder: (data?: any) => Promise<any>
  canClick?: boolean;
  canDrag?: boolean
  productsToBill: string[]
  setOmieOrderData: (state: any) => void
}

const KanbanBoardFinancial : FC<IKanbanBoardFinancialProps> = ({ initialData, handleSelectProductToBill,  billProductsOrder, canClick, canDrag, productsToBill, handleDetailsOmieOrder, omieDetailsOrder, setOmieOrderData  }) => {
  const [data, setData] = useState(initialData);
  const [tempMovement, setTempMovement] = useState()
  const [showConfirmation, setShowConfirmation] = useState({
    show: false,
    title: '',
    description: ''
  })

  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCancelDragAction = () => {
    setShowConfirmation({ show: false, title: '', description: ''})
    setTempMovement(undefined)
  }

  const handleSubmit = () => {
    try {
     
      billProductsOrder()
      
    } catch (error) {
      console.log("error: ", error)
    } finally {
      onCancelDragAction()
    }
    
  }

  const onDragEnd = (result: any) => {
    console.log("drag called: ", data)
    const { destination, source, draggableId } = result;
  
    if (!destination) return;
  
    const task = data?.tasks[draggableId];
    const endColumn = data?.columns[destination.droppableId];
    console.log("endColumn: ", endColumn)
  
    if (task?.isLocked) {
      alert('Este card está bloqueado e não pode ser movido.');
      return;
    }

    setTempMovement(result)
    setShowConfirmation({
      show: true,
      title: 'Você tem certeza?',
      description: `Movimentar todos os itens dessa OS para "${endColumn?.title}"`
    })
  
    
  };


  return (
    <>
     <ConfirmDialog open={showConfirmation?.show}  title={showConfirmation?.title} description={showConfirmation?.description} onCancel={onCancelDragAction} onConfirm={handleSubmit} />

     {omieDetailsOrder?.id && <OmieOrderDetailsModal data={omieDetailsOrder} onClose={() => setOmieOrderData(undefined)}  /> }

    <Flex sx={{ backgroundColor: '#f5f5f5', padding: '20px', justifyContent: 'flex-end' }}>
    <ButtonCustom LeftIcon={() => <BsCurrencyDollar size={18} />} sx={{ width: '280px', height: '60px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => setShowConfirmation({
      show: true,
      title: 'Você tem certeza?',
      description: 'Essa ação irá faturar os itens selecionados'
    })} variant='outlined'>CRIAR PEDIDO E FATURAR</ButtonCustom>
    </Flex>
     <DragDropContext onDragEnd={onDragEnd}>
        <Card sx={{ padding: '20px', height: '80vh'}}>
            <Grid2 container spacing={2}>
            {data?.columnOrder.map((columnId) => {
                const column = data.columns[columnId];
                const tasks = column.taskIds.map((taskId) => data.tasks[taskId]);

                return (
                
                    <Grid2 size={4} key={column.id}>
                    <Typography variant="h6" align="center" mb='20px'>
                        {column.title}
                    </Typography>
                    <StrictModeDroppable droppableId={column.id}>
                        {(provided) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                            background: '#2A2A2A',
                            padding: 2,
                            borderRadius: 1,
                            minHeight: 700,
                            }}
                        >
                            {tasks.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                                isDragDisabled={task.isLocked || !canDrag} // Bloqueia o drag
                              >
                                {(provided) => (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...(task.isLocked ? {} : provided.dragHandleProps)} // Remove o handle para itens bloqueados
                                    sx={{
                                      marginBottom: 2,
                                      opacity: task.isLocked ? 0.5 : 1, // Visualmente indicar bloqueio
                                      cursor: task.isLocked ? 'not-allowed' : 'pointer',
                                    }}
                                    // onClick={() => handleCardClick(task)}
                                  >
                                    {task.content.type === 'checkbox' && (
                                      <CardContent>
                                      <Flex alignItems='center' paddingLeft='0px'>
                                        {!task.isLocked && <Checkbox sx={{ padding: 0, marginRight: '10px'}} onChange={() => handleSelectProductToBill(task.id)} checked={!!productsToBill?.find(i => i === task.id)} />}
                                        
                                        <Typography onClick={() => handleSelectProductToBill(task.id)}>{task?.content?.os}</Typography>
                                      </Flex>
                                    </CardContent>
                                    )}

                                    {task.content.type === 'click' && (
                                      <CardContent>
                                      <Flex alignItems='center' paddingLeft='0px'>
                                        <Typography onClick={() => handleDetailsOmieOrder(task.id)}>{task?.content?.os}</Typography>
                                      </Flex>
                                    </CardContent>
                                    )}
                                    
                                  </Card>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                        )}
                    </StrictModeDroppable>
                    </Grid2>
                
                );
            })}
            </Grid2>
        </Card>
    </DragDropContext>

    {/* {isModalOpen && <OsDetailsModal onClose={handleCloseModal} code='1010' list={list}  /> } */}

   
    </>
   
  );
};

export  {KanbanBoardFinancial};