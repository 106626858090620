import React, { FC, useState } from 'react';
import { useForm, SubmitHandler, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Box, Paper, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Input } from '../../Components/Form/Input/input.component';
import { Button } from '../../Components/Form/Button/button.scomponent';
import { Flex } from '../../Components/Flex/flex.component';
import Brand from '../../Assets/brand.webp';
import { useNavigate } from 'react-router-dom';
import { useChat } from '../../Hooks/useChat';
import { LoginUseCase } from '../../Usecase/Auth/login-usecase';
import { useScreenSizes } from '../../utils/screenSizes';

import { Environment } from '../../Config/constants';
import { useToast } from '../../Hooks/ToasContext';

interface SignInProps {
  login: string;
  password: string;
}

const SignInSchemaValidation = Yup.object().shape({
  login: Yup.string().required('Login obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
});

interface Props {
  loginUseCase: LoginUseCase;
}

const SignIn: FC<Props> = ({ loginUseCase }) => {
  const [loading, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const theme = useTheme();
  const { isMobile } = useScreenSizes();
  const { showToast } = useToast()

  // Use the SignInProps type with useForm
  const { handleSubmit, register, formState } = useForm<SignInProps>({
    resolver: yupResolver(SignInSchemaValidation),
  });

  const { setUserToken } = useChat();

  const handleLogin: SubmitHandler<SignInProps> = async (values) => {
    try {
      setLoading(true)
      console.log('Form submitted with:', values);
      // Example login logic (replace with your actual login use case)
      const loginResult = await loginUseCase.handle({ username: values.login, password: values.password });
      if (loginResult.isFailure) {
        console.log("loginResult: ", loginResult)
        showToast(String(loginResult?.error?.error), 'error');
        return;
      }
     
    
      navigate('/dashboard');
    } catch (error) {
      // toast({
      //   position: 'top-right',
      //   render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao logar. Por favor, tente novamente'} />,
      // });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Flex
      sx={{
        width: '100vw',
        height: '100vh',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        backgroundColor: theme.palette.primary.light,
      }}
    >
      
      <Paper
        sx={{
          padding: '40px',
          width: '480px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '5px',
        }}
        component="form"
        onSubmit={handleSubmit(handleLogin)}
      >
        <img src={Brand} alt="Logo" width="350px" height="auto" />
        {/* <Typography fontWeight="500" color="text.primary">
          Iniciar Sessão
        </Typography> */}
        <Input
          label="E-mail"
          placeholder="Preencha o seu e-mail"
          sx={{ marginTop: '20px' }}
          {...register('login')}
        />
        <Input
          label="Senha"
          placeholder="Preencha a sua senha"
          type="password"
          sx={{ marginTop: '20px' }}
          {...register('password')}
        />
        <Button type="submit" sx={{ marginTop: '10px' }} isLoading={loading}>
          ENTRAR
        </Button>
        <Button type="button" variant="text" sx={{ marginTop: '10px', width: '300px' }}>
          Perdi minha senha
        </Button>
      </Paper>
    </Flex>
  );
};

export { SignIn };
