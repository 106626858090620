import { IServiceOrderEntity } from '../../Services/api/service-orders/service-order.interface'
import { ServiceOdersServiceApiRemoteService } from '../../Services/api/service-orders/service-orders-service'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ShowServiceOrdersUseCase
implements IFormUsecase<string, IServiceOrderEntity> {
  constructor (private readonly ServiceOrdersApi: ServiceOdersServiceApiRemoteService) {}
    

  public async handle (
    osId: string
  ): Promise<Result<IServiceOrderEntity>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ServiceOrdersApi.show(osId)

      if (response.isFailure) {
        return response
      }

      const list = response.getValue()

      if(!list) return response

    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
