import {
  Box,
  Grid2,
  Typography,
  useTheme
} from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { FaArrowCircleLeft } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { ConfirmDialog } from '../../../Components/Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { Flex } from '../../../Components/Flex/flex.component';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { Table } from '../../../Components/Table/table.component';
import { ProductionStatusEnum } from '../../../Services/api/service-orders/service-order.interface';
import { ListServiceOrdersUseCase } from '../../../Usecase/ServiceOrders/list-service-orders-usecase';
import { ShowServiceOrdersUseCase } from '../../../Usecase/ServiceOrders/show-service-orders-usecase';
import { UpdateServiceOrdersUseCase } from '../../../Usecase/ServiceOrders/update-service-orders-usecase';
import { normalizeProductionStatus } from '../../../utils/normalizeProductionStatus';
import { useProductionDetails } from './production-details.hook';

interface IProductionDetailsProps {
    listServiceOrdersUseCase: ListServiceOrdersUseCase
  showServiceOrdersUseCase: ShowServiceOrdersUseCase
  updateServiceOrdersUseCase: UpdateServiceOrdersUseCase
 
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const ProductionDetails : FC<IProductionDetailsProps> = ({ listServiceOrdersUseCase, showServiceOrdersUseCase, updateServiceOrdersUseCase}) => {
    const [value, setValue] = React.useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
   
    const [tabIndex, setTabIndex] = useState(0);
    const theme = useTheme()

  const { handleShowOrderDetails, orderDetails, handleConfirmProductionItems,  } = useProductionDetails({
    listServiceOrdersUseCase, showServiceOrdersUseCase, updateServiceOrdersUseCase, osId: String(params?.osId)
  })


  const columns = useMemo(() => {
    const createColumns: any[] = [ 
     
      {
        field: 'code',
        headerName: 'Código Personalização',
        width: 400,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography color={params.row.status === ProductionStatusEnum.FINALIZACAO ? theme.palette.grey[500] : '#000' }>{params?.row?.nsOrderProductId}</Typography></Box>
      },
      
     
      {
        field: 'description',
        headerName: 'Descrição',
        width: 200,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography color={params.row.status === ProductionStatusEnum.FINALIZACAO ? theme.palette.grey[500] : '#000' }>{params?.row?.product?.name}</Typography></Box>
      },
      // {
      //   field: 'quantity',
      //   headerName: 'Tiragem',
      //   width: 100
      // },
      {
        field: 'product_id',
        headerName: 'Produto',
        width: 200,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography color={params.row.status === ProductionStatusEnum.FINALIZACAO ? theme.palette.grey[500] : '#000' }>{params?.row?.product?.code}</Typography></Box>
      },
     
      {
        field: 'card',
        headerName: 'Cartela',
        width: 100,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography color={params.row.status === ProductionStatusEnum.FINALIZACAO ? theme.palette.grey[500] : '#000' }>{params?.row?.card}</Typography></Box>
      },
    //   {
    //     field: 'created_at',
    //     headerName: 'Data de inclusão',
    //     width: 200,
    //     renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{format(new Date(orderDetails?.startDate), 'dd/MM/yyyy')}</Typography></Box>
    //   },
      // {
      //   field: 'substrate',
      //   headerName: 'Substrato',
      //   width: 200,
      // },
      // {
      //   field: 'weight',
      //   headerName: 'Gramatura',
      //   width: 100,
      // },
      // {
      //   field: 'category',
      //   headerName: 'Categoria',
      //   width: 200,
      // },
      // {
      //   field: 'file',
      //   headerName: 'Arquivo',
      //   width: 100,
      // },
      // {
      //   field: 'finalFile',
      //   headerName: 'Arquivo Correto',
      //   width: 300,
      // },
      // {
      //   field: 'dropboxFolder',
      //   headerName: 'Pasta no Dropbox',
      //   width: 300,
      // },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography color={params.row.status === ProductionStatusEnum.FINALIZACAO ? theme.palette.grey[500] : '#000' }>{normalizeProductionStatus(params?.row?.status)}</Typography></Box>
      },
    ];
  
    return createColumns;
  }, []);


  const handleConfirmation = () => {
    setShowConfirmation(false)
  }

  useEffect(() => {
    if(params?.osId){
        handleShowOrderDetails(params.osId)
    }
    
  }, [params])
  
  

  return (
    <>
  
    <ConfirmDialog open={showConfirmation}  title='Marcar como item produzido' description='Você tem certeza?' onCancel={() => setShowConfirmation(false)} onConfirm={handleConfirmation} />

    <Grid2 container>
      
        <Grid2 size={12} sx={{ padding: '20px'}}>
            <Flex alignItems='center'>
                <Button variant='text' sx={{ width: '50px', marginTop: '5px'}} onClick={() => navigate('/production-list')}><FaArrowCircleLeft size={25} /></Button>
                <Typography sx={{ fontSize: '19px', fontWeight: 600}}>Ordem de Serviço (OS) - #{orderDetails?.externalProductionId}</Typography>
            </Flex> 
        </Grid2>
 
        <Grid2 size={12} sx={{ padding: '20px', paddingTop: 0}}>
            {orderDetails?.id &&  <Table data={orderDetails?.products} totalCount={0} columns={columns} sx={{ minHeight: '91%', with: '100%'}} onSelect={(e: any) => handleConfirmProductionItems(e)} onRowClick={(e: any) => handleConfirmProductionItems(e)} isRowSelectable={(params: any) => params.row.status !== ProductionStatusEnum.FINALIZACAO} />}
        </Grid2>
    </Grid2>

   

   

    </>
  );
};

export default ProductionDetails;
