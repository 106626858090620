import { Box, Paper, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { GoPlus } from "react-icons/go";
import { Table } from '../../../Components/Table/table.component';
import { useNavigate } from 'react-router-dom';
import { ListProductUseCase } from '../../../Usecase/Products/list-product-usecase';
import { useListProducts } from './products-list.hook';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';
import { ActionButtons } from '../../../Components/Form/ActionButtons/action-buttons.component';

interface IProductsList {
    listProductUseCase: ListProductUseCase
}
const ProductsList : FC<IProductsList> = ({ listProductUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listProdcuts, list, totalRegisters } = useListProducts({
        listProductUseCase
    })


    console.log("list: ", list)

    useEffect(() => {
        listProdcuts({ page: 1})
    }, [])


    const columns = useMemo(() => {
        const createColumns: any[] = [
          {
            field: 'photo',
            headerName: 'Imagem',
            renderCell: (params: any) => <Box component='img' src={params.value} sx={{ width: '80px', height: '80px', objectFit: 'contain'}} />
          },
          
          {
            field: 'name',
            headerName: 'Produto',
            width: 400,
          },
          {
            field: 'code',
            headerName: 'Código',
            width: 200,
          },
          {
            field: 'price',
            headerName: 'Preço Unitário',
            width: 150,
          },
          {
            field: "actions",
            headerName: "Ações",
            headerAlign: "right",
            flex: 1,
            renderCell: (params: any) => <ActionButtons params={params} setItemToDestroy={() => {}} editLink={`/products-form/${params.row.id}`} />
          }
        ];
      
        return createColumns;
      }, []);
   

    return (
        <BaseScreen>
            <Paper >
                <BaseContentPage 
                    title='Produtos'
                    search
                    searchFunction={listProdcuts}
                    breadcrumb={[{ title: 'Produtos', link: "/products-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '130px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => navigate('/products-form')}
                    >Adicionar</Button>}
                >
                  {!!list?.length ? <Table data={list} columns={columns} onPageChange={listProdcuts} totalCount={totalRegisters} /> : <Placeholder description='Nenhum dado encontrado.' />}
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { ProductsList }