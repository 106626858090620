import CloseIcon from '@mui/icons-material/Close';
import HotelIcon from '@mui/icons-material/Hotel';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from '@mui/lab';
import {
  AppBar,
  Box,
  Dialog,
  DialogContent,
  Grid2,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import React, { FC, useState } from 'react';
import { IBillProductOrderPayload } from '../../../../Services/api/orders/orders.interface';
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from '../../../../Services/api/sales/sales.interface';
import { KanbanBoardFinancial } from '../../../KanbanBoardFinancial/kanban-board.component';
import IKanbanBoardProductionSchemaProps from '../../../KanbanBoardProduction/interfaces/kanban-board-production-schema.interface';
import { KanbanBoardProduction } from '../../../KanbanBoardProduction/kanban-board.component';
import { SaleProductTable } from '../../../Table/variants/sale-product-table/sale-product-table.component';
interface ISaleDetailsModalProps {
    // data: ISaleEntity
    data: any
    onClose: () => void
    fromFinancial?: boolean
    type: 'sale' | 'financial' // usado para mudar o tipo de visualização do kanban
    kanbanSchema: IKanbanBoardProductionSchemaProps
    billProductsOrder: (data: IBillProductOrderPayload) => Promise<void>
    canDragProductionKanban: boolean
    canClickProductionKanban: boolean
    canDragFinancialKanban: boolean
    canClickFinancialKanban: boolean
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const SaleDetailsModal : FC<ISaleDetailsModalProps> = ({ data, onClose, fromFinancial = false, kanbanSchema, billProductsOrder, canDragProductionKanban = true, canClickProductionKanban = true, canClickFinancialKanban = true, canDragFinancialKanban = true  }) => {
    const [value, setValue] = React.useState(0);

  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme()


  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ marginTop: '20px'}}>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const sale : ISaleEntity = 
  {
    id: '1',
    code: '100',
    createdAt: '29 out',
    customer: {
      name: 'Diego Hennrich',
      email: 'diegohennrich@gmail.com',
      phone: '51981872327',
    },
    total: 'R$ 10.735,00',
    delivery_address: {
      address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
    },
    payment_status: TSalePaymentStatus.APPROVED,
    delivery_status: TDeliveryPaymentStatus.WAITING,
    products: [
      {
        id: '10',
        name: 'Copo e Papel Kraft Personalizado 100ml',
        quantity: 1,
        variant: '5000',
        sku: '0010076',
        price_unit: 'R$ 2.034,28',
        total: 'R$ 2.034,28',
        image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
      },
      {
        id: '11',
        name: 'Copo e Papel Personalizado 500ml',
        quantity: 1,
        variant: '10000',
        sku: '1000010014',
        price_unit: 'R$ 8.702,10',
        total: 'R$ 8.702,10',
        image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
      }
    ]
  }
  
  

  return (
    <>
      {/* <button onClick={handleOpen}>Abrir Modal</button> */}

      <Dialog fullScreen open onClose={onClose} sx={{ zIndex: 9999999, backgroundColor: theme.palette.primary.light}}>
        <AppBar position="sticky" sx={{ backgroundColor: '#fff'}}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {`Pedido - Venda #`}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent sx={{ backgroundColor: theme.palette.primary.light}}>

          <Paper sx={{ padding: '15px'}}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="Pedido" {...a11yProps(0)} />
                      <Tab label="Produção" {...a11yProps(1)} />
                      {fromFinancial && <Tab label="Financeiro" {...a11yProps(2)} />}
              </Tabs>
            </Box>

          </Paper>
      

          {/* Conteúdo das Tabs */}
          
            <CustomTabPanel value={value} index={0} >
                <Grid2 container spacing={4}>
                    <Grid2 size={8} sx={{ borderBottom: 'solid 1px dashed'}}>
                      <Paper sx={{ padding: '20px'}}>

                    
                        <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '35px' }}>Detalhes ao pedido</Typography>

            
                          <SaleProductTable sale={sale}/>
                        </Paper>
                    </Grid2>

                    <Grid2 size={4}>
                      <Paper sx={{ padding: '20px'}}>
                        <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Dados do cliente</Typography>

                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.name}</Typography>

                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.email}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.phone}</Typography>
                      
                        </Paper>

                        <Paper sx={{ padding: '20px', marginTop: '30px'}}>
                          <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Endereço de entrega</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.name}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.phone}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.delivery_address?.address}</Typography>
                        </Paper>

                        <Paper sx={{ padding: '20px', marginTop: '30px'}}>
                          <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Endereço de cobrança</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.name}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.customer?.phone}</Typography>
                          <Typography sx={{  fontSize: '1rem', fontWeight: 500, marginBottom: '5px' }}>{sale?.delivery_address?.address}</Typography>
                        </Paper>

                        <Paper sx={{ padding: '20px', marginTop: '30px'}}>
                          <Typography sx={{  fontSize: '1.5rem', fontWeight: 700, marginBottom: '15px' }}>Histórico</Typography>
                          
                          <Timeline position="right">
                              <TimelineItem>
                              <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                  <HotelIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                              </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px' }}>
                                <Typography variant="h6" component="span">
                                  Venda Registrada
                                </Typography>
                                <Typography>Por iehwefdr@guerrillamailblock.com de Pedidos manuais</Typography>
                              </TimelineContent>
                            </TimelineItem>
                            <TimelineItem>
                              <TimelineSeparator>
                                <TimelineConnector />
                                <TimelineDot color="primary" variant="outlined">
                                  <HotelIcon />
                                </TimelineDot>
                                <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
                              </TimelineSeparator>
                              <TimelineContent sx={{ py: '12px', px: 2 }}>
                                <Typography variant="h6" component="span">
                                  Venda Registrada
                                </Typography>
                                <Typography>Por iehwefdr@guerrillamailblock.com de Pedidos manuais</Typography>
                              </TimelineContent>
                            </TimelineItem>
                      
                          </Timeline>
                        </Paper>
                    </Grid2>
                    
               
                </Grid2>
               
            </CustomTabPanel>
         
            <CustomTabPanel value={value} index={1}>
           
            
              <KanbanBoardProduction  
                  initialData={{
                    tasks: {
                      'task-1': { id: 'task-1', content: { os: '500 Copos 100ml', items: []}},
                      'task-2': { id: 'task-2', content: { os: '500 Copos 200ml', items: []}},
                      'task-3': { id: 'task-3', content: { os: '1000 Copos 1000ml', items: []}},
                      'task-4': { id: 'task-4', content: { os: '200 Copos 100ml', items: []}},
                    },
                    columns: {
                      'column-1': {
                        id: 'column-1',
                        title: 'Aguardando Início',
                        taskIds: [],
                      },
                      'column-2': {
                        id: 'column-2',
                        title: 'Em Produção',
                        taskIds: [],
                      },
                      'column-3': {
                        id: 'column-3',
                        title: 'Concluído',
                        taskIds: ['task-1', 'task-2', 'task-3', 'task-4'],
                      },
                    },
                    columnOrder: ['column-1', 'column-2', 'column-3'],
                  }} 
                  canClick={canClickProductionKanban}
                  canDrag={canDragProductionKanban}
                  handleShowOrderDetails={() => {}} 
                  handleConfirmProductionItems={() => {}}
                  setOrderDetails={() => {}}
                  
                />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>

              {/* {kanbanSchema?.columnOrder?.length &&  <KanbanBoardFinancial  handleConfirmProductionItems={() => {}} initialData={kanbanSchema}  />}
               */}
              <KanbanBoardFinancial billProductsOrder={billProductsOrder} initialData={{
                tasks: {
                  'task-1': { id: 'task-1', isLocked: false, content: { os: 'Produto 500 Copos 200ml', items: [], type: 'click'}},
                  'task-2': { id: 'task-2',  isLocked: true ,content: { os: 'PEDIDO #22131', items: [], type: 'click'}},
                  'task-3': { id: 'task-3',  isLocked: false,content: { os: 'Produto 1000 Copos 500ml', items: [], type: 'click'}},
                  'task-4': { id: 'task-4',  isLocked: false, content: { os: 'Produto 1500 Copos 100ml', items: [], type: 'click'}},
                },
                columns: {
                  'column-1': {
                    id: 'column-1',
                    title: 'Pronto para Faturar',
                    taskIds: ['task-1', 'task-3', 'task-4'],
                  },
                  'column-2': {
                    id: 'column-2',
                    title: 'Faturado',
                    taskIds: ['task-2'],
                  },
                  
                },
                columnOrder: ['column-1', 'column-2'],
                }}
                canClick={canClickFinancialKanban}
                canDrag={canDragFinancialKanban}
                handleDetailsOmieOrder={async () => {}}
                handleSelectProductToBill={() => {}}
                setOmieOrderData={() => {}}
                productsToBill={[]}
              />
            </CustomTabPanel>
         
         
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SaleDetailsModal;
