import { TextField, TextFieldProps } from '@mui/material'
import React, { useState, forwardRef, ForwardRefRenderFunction } from 'react'

const currencyFormatter = (value: string): string => {
  // Remove everything that's not a digit
  const cleanValue = value.replace(/\D/g, '');

  // Convert it to a number and format as BRL
  const formattedValue = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(parseFloat(cleanValue) / 100);

  return formattedValue;
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, TextFieldProps> = (
  {
    sx = {},
    value: initialValue = '0', // Start value as empty string or provided initial value
    onChange,
    ...rest
  },
  ref
) => {
  const [value, setValue] = useState<string>(
    currencyFormatter(initialValue as string)
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    const formattedValue = currencyFormatter(inputValue);

    setValue(formattedValue);

    if (onChange) {
      // Pass the raw numeric value (without formatting) to the parent
      onChange({
        ...event,
        target: {
          ...event.target,
          value: formattedValue,
        },
      });
    }
  }

  return (
    <TextField
      ref={ref}
      value={value}
      onChange={handleChange}
      sx={{
        width: '100%',
        ...sx,
      }}
      {...rest}
    />
  );
}

export const InputCurrency = forwardRef(BaseInput);
