import { CubagesApiRemoteService } from '../../Services/api/cubages/cubages-service'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class CreateCubagesUseCase
implements IFormUsecase<string, void> {
  constructor (private readonly CubagesApi: CubagesApiRemoteService) {}
    

  public async handle (
    name: string
  ): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.CubagesApi.create(name)

      if (response.isFailure) {
        return response
      }

    
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
