import { CubagesApiRemoteService } from '../../Services/api/cubages/cubages-service'
import { ICubageEntity } from '../../Services/api/cubages/cubages.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ShowCubagesUseCase
implements IFormUsecase<string, ICubageEntity> {
  constructor (private readonly CubageOrdersApi: CubagesApiRemoteService) {}
    

  public async handle (
    cubageId: string
  ): Promise<Result<ICubageEntity>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.CubageOrdersApi.show(cubageId)

      if (response.isFailure) {
        return response
      }

      const Show = response.getValue()

      if(!Show) return response
    
      return Result.ok(Show)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
