import { ProductsApiRemoteService } from '../../Services/api/products/products-service'
import { IProductEntity } from '../../Services/api/products/products.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ShowProductUseCase
implements IFormUsecase<string, IProductEntity> {
  constructor (private readonly ProductsApi: ProductsApiRemoteService) {}
    

  public async handle (
    productId: string
  ): Promise<Result<IProductEntity>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ProductsApi.show(productId)

      if (response.isFailure) {
        return response
      }

      const data = response.getValue()

      if(!data) return response

    
      return Result.ok(data)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
