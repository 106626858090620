import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import { FC } from 'react';
import { Controller, Control, FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

export interface IOptionsProps {
  label: string;
  value: string;
}

interface ISelectComponentProps {
  options: IOptionsProps[];
  label: string;         // Label do Select
  name: string;          // Nome do campo no formulário
  control: Control<any>; // Control do React Hook Form
  placeholder: string;   // Placeholder para ser exibido quando não há valor
  defaultValue?: string; // Valor padrão
  // helperText?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  helperText?: any
  error?: boolean;       // Estado de erro opcional
  onChangeCallback?: (value: string) => void; // Callback adicional se necessário
}

const SelectComponent: FC<ISelectComponentProps> = ({
  options,
  label,
  name,
  control,
  placeholder,
  defaultValue = '',
  helperText,
  error = false,
  onChangeCallback
}) => {
  // Função auxiliar para encontrar o label correspondente ao valor selecionado
  const getSelectedLabel = (value: string) => {
    const selectedOption = options.find(option => option.value === value);
    return selectedOption ? selectedOption.label : '';
  };

  return (
    <FormControl 
      fullWidth 
      variant="outlined" 
      error={error}
    >
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue} // Valor padrão
        render={({ field }) => (
          <Select
            labelId={`${name}-label`}
            id={name}
            value={field.value}
            onChange={(event: SelectChangeEvent) => {
              const selectedValue = event.target.value as string;
              field.onChange(selectedValue); // Atualiza o valor no form
              if (onChangeCallback) {
                onChangeCallback(selectedValue); // Dispara o callback adicional, se houver
              }
            }}
            label={label}
            displayEmpty
            renderValue={(value) => {
              if (!value) {
                return <em>{placeholder}</em>; // Exibe placeholder quando não há valor
              } else {
                return getSelectedLabel(value); // Exibe o label da opção selecionada
              }
            }}
            MenuProps={{
              disablePortal: true, 
            }}
          >
            <MenuItem disabled value="">
              <em>{placeholder}</em> {/* Placeholder como MenuItem desabilitado */}
            </MenuItem>
            {options.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>} {/* Exibe o helperText */}
    </FormControl>
  );
};

export { SelectComponent };
