import React, { FC } from 'react';
import { Flex } from '../Flex/flex.component';
import {  Box, Typography, useTheme } from '@mui/material';
import { Button } from '../Form/Button/button.scomponent';
import { IoClose } from 'react-icons/io5';

interface ITagProps {
    title: string;
    boxStyle?: object
    titleStyle?: object;
}

const Tag : FC<ITagProps> = ({ boxStyle = {}, titleStyle = {}, title }) => {
    const theme = useTheme()

    return (
            <Flex>
                <Box sx={{ backgroundColor: theme.palette.primary.dark, display: 'flex', alignItems: 'center', justifyContent: 'center',  padding: '0px 20px', height: '30px', borderRadius: '20px', ...boxStyle}}><Typography  sx={{ fontSize: '14px',  ...titleStyle }}>{title}</Typography></Box>
            </Flex>
    )
}

export { Tag }