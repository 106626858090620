import React, { useCallback, useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from "../../../Services/api/sales/sales.interface";
import { Environment } from "../../../Config/constants";
import { ListServiceOrdersUseCase } from "../../../Usecase/ServiceOrders/list-service-orders-usecase";
import IServiceOrdersListProps from "../../../Shared/Interfaces/service-orders-list.interface";
import IKanbanBoardProductionSchemaProps from "../../../Components/KanbanBoardProduction/interfaces/kanban-board-production-schema.interface";
import { IServiceOrderEntity, IUpdateServiceOrdersPayload, ProductionStatusEnum } from "../../../Services/api/service-orders/service-order.interface";
import { ShowServiceOrdersUseCase } from "../../../Usecase/ServiceOrders/show-service-orders-usecase";
import { title } from "process";
import { UpdateServiceOrdersUseCase } from "../../../Usecase/ServiceOrders/update-service-orders-usecase";
import { Task } from "../../Financial/Details/financial-details.hook";


interface IUseProductionDetailsProps {
  listServiceOrdersUseCase: ListServiceOrdersUseCase
  showServiceOrdersUseCase: ShowServiceOrdersUseCase
  updateServiceOrdersUseCase: UpdateServiceOrdersUseCase
  osId: string

}
export function useProductionDetails({ listServiceOrdersUseCase, showServiceOrdersUseCase, updateServiceOrdersUseCase, osId }: IUseProductionDetailsProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<any[]>([])
    
    const [kanbanSchema, setKanbanSchema] = useState<IKanbanBoardProductionSchemaProps>()
    const [orderDetails, setOrderDetails] = useState<IServiceOrderEntity | undefined>()

    const { showToast } =  useToast()

    const transformData = (dados: IServiceOrderEntity[]) => {
      
      const tasks: Record<string, Task> = {};
      const initialOS : any = [];
      const onProductionOS : any = [];
      const finishsedOS : any = [];
      
      dados.forEach((item, index) => {
          // const taskId = `task-${index + 1}`;
          const taskId = item.id;
          tasks[taskId] = {
              id: taskId,
              content: {
                  os: `OS #${item.externalProductionId}`,
                  items: item.products
              }
          };

          if(item.stage === ProductionStatusEnum.INICIO){
            initialOS.push(taskId);
          } else if (item.stage === ProductionStatusEnum.PRODUCAO){
            onProductionOS.push(taskId)
          } else {
            finishsedOS.push(taskId)
          }
          
      });

      const result = {
          tasks,
          columns: {
            [ProductionStatusEnum.INICIO]: {
                  id: ProductionStatusEnum.INICIO,
                  title: 'Aguardando Início',
                  taskIds: initialOS,
              },
              [ProductionStatusEnum.PRODUCAO]: {
                  id: ProductionStatusEnum.PRODUCAO,
                  title: 'Em Produção',
                  taskIds: onProductionOS,
              },
              [ProductionStatusEnum.FINALIZACAO]: {
                  id: ProductionStatusEnum.FINALIZACAO,
                  title: 'Concluído',
                  taskIds: finishsedOS,
              },
          },
          columnOrder: [ProductionStatusEnum.INICIO, ProductionStatusEnum.PRODUCAO, ProductionStatusEnum.FINALIZACAO],
      };

      return result;
    }

    const ProductionDetails = async (currentPage: number) => {
        
        try {
          setLoading(true)
          
          const listResult = await listServiceOrdersUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()

         const normalizeSchema : IKanbanBoardProductionSchemaProps = transformData(list?.data ?? [])
         setKanbanSchema(normalizeSchema)

       

         setPage(currentPage)
        } catch (error) {
          console.log("error ProductionDetails function: ", error)
        } finally {
          setLoading(false);
        }
      };

    const list = useMemo(() => {
        const rows = data?.map(sale => {

            return {
                ...sale,
             
            }
        })

        return rows
    }, [data])

    const handleShowDetails = (code: string) => {
      console.log("code: ", code)
      // setShowDetails(code)
    } 

    const handleConfirmProductionItems = async (e:string[]) => {
      try {
        // console.log("handleConfirmProductionItems e: ", e)

        // setLoading(true)
        
        // const updateResult = await updateServiceOrdersUseCase.handle({ productsIds: e, osId, status: 'FINALIZACAO' })

        // if (updateResult.isFailure) {
        //   showToast(String(updateResult?.error?.error), 'error');
        //   return;
        // }

        // const dados = updateResult.getValue()
        
        // showToast(String('Itens atualizados'), 'info');
        
      } catch (error) {
        console.log("erro handleConfirmProductionItems: ", error)
      } finally {
        setLoading(false);
      }
    
    }

    const handleShowOrderDetails = async (osId: string) => {
        
      try {
        setLoading(true)
        
        const listResult = await showServiceOrdersUseCase.handle(osId)

        if (listResult.isFailure) {
          showToast(String(listResult?.error?.error), 'error');
          return;
        }

       const dados = listResult.getValue()

       if(dados){
        setOrderDetails(dados)
       }

  
     
    
      } catch (error) {
        console.log("error ProductionDetails function: ", error)
      } finally {
        setLoading(false);
      }
    };


    return {
        loading,
        ProductionDetails,
        list,
        data,
        handleShowDetails,
        setOrderDetails,
        handleConfirmProductionItems,
        kanbanSchema,
        handleShowOrderDetails,
        orderDetails
        
    }
}