/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { IProductEntity, IProductsListPayload, IProductsListResponse, IUpdateProductPayload } from "./products.interface";
import { Environment } from "../../../Config/constants";

export class ProductsApiRemoteService {
  constructor(private service: AxiosInstance) {}
    
  public update = async ({ id, data }: IUpdateProductPayload): Promise<Result<void>> => {
    try {
        await this.service.put(`/products/${id}`, data, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          });

      return Result.ok();
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public create = async (data: any): Promise<Result<void>> => {
    try {
        await this.service.post(`/products`, data, {
            headers: {
              'Content-Type': 'multipart/form-data', 
            },
          });

      return Result.ok();
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public list = async ({ page, search }: IProductsListPayload): Promise<Result<IProductsListResponse>> => {
    try {
        const response = await this.service.get(`/products`, { 
          params: {
            offset: Number(Environment.OFFSET_REGISTERS),
            page,
            searchBy: search
          }
        });

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public show = async (productId: string): Promise<Result<IProductEntity>> => {
    try {
        const response = await this.service.get(`/products/${productId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
}
