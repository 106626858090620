// router.tsx
import React from 'react'

import {
  createBrowserRouter,
  Navigate,
  RouterProvider
} from 'react-router-dom'
import { Environment } from '../Config/constants'
import { NotFoundPage } from '../Pages/NotFound'
import { SignIn } from '../Pages/SignIn'
import api from '../Services/api'
import { AuthenticationApiRemoteService } from '../Services/api/auth/service-authentication'
import { LoginUseCase } from '../Usecase/Auth/login-usecase'
import { Box } from '@mui/material'
import { Flex } from '../Components/Flex/flex.component'
import path from 'path'
import { Dashboard } from '../Pages/Dashboard'
import {  ProductsList } from '../Pages/Products/List'
import {  ProductsForm } from '../Pages/Products/Form'
import { CreateProductUseCase } from '../Usecase/Products/create-product-usecase'
import { ProductsApiRemoteService } from '../Services/api/products/products-service'
import { ListProductUseCase } from '../Usecase/Products/list-product-usecase'
import {  CubageList } from '../Pages/Cubage/List'
import { BoxList } from '../Pages/Boxes/List'
import { BoxForm } from '../Pages/Boxes/Form'
import { OrdersForm,  } from '../Pages/Orders/Form'
import { OrdersList,  } from '../Pages/Orders/List'
import { ProductionList } from '../Pages/Production/List'
import { CollaboratorsList } from '../Pages/Collaborators/List'
import { CollaboratorsForm } from '../Pages/Collaborators/Form'
import { FinancialList } from '../Pages/Financial/List'
import { FinancialForm } from '../Pages/Financial/Form'
import { ListServiceOrdersUseCase } from '../Usecase/ServiceOrders/list-service-orders-usecase'
import { ServiceOdersServiceApiRemoteService } from '../Services/api/service-orders/service-orders-service'
import { ListOrdersUseCase } from '../Usecase/Orders/list-orders-usecase'
import { OrdersApiRemoteService } from '../Services/api/orders/orders-service'
import { ShowServiceOrdersUseCase } from '../Usecase/ServiceOrders/show-service-orders-usecase'
import { UpdateServiceOrdersUseCase } from '../Usecase/ServiceOrders/update-service-orders-usecase'
import { BillProductOrderOrdersUseCase } from '../Usecase/Orders/bill-products-order-usecase'
import ProductionDetails from '../Pages/Production/Details'
import FinancialDetails from '../Pages/Financial/Details'
import { CreateCubagesUseCase } from '../Usecase/Cubages/create-cubages-usecase'
import { CubagesApiRemoteService } from '../Services/api/cubages/cubages-service'
import { ListCubagesUseCase } from '../Usecase/Cubages/list-cubages-usecase'
import { CreateBoxesUseCase } from '../Usecase/Boxes/create-boxes-usecase'
import { BoxesApiRemoteService } from '../Services/api/boxes/boxes-service'
import { ListBoxesUseCase } from '../Usecase/Boxes/list-boxes-usecase'
import { DestroyBoxesUseCase } from '../Usecase/Boxes/destroy-boxes-usecase'
import { CreateCubagesVariationsUseCase } from '../Usecase/CubagesVariations/create-cubages-variations-usecase'
import { CubagesVariantsApiRemoteService } from '../Services/api/cubages-variants/cubages-variants-service'
import { ServicesList } from '../Pages/ProductsServices/List'
import { ServicesForm } from '../Pages/ProductsServices/Form'
import { ListServiceInfoOrdersUseCase } from '../Usecase/ServicesInfo/list-service-info-usecase'
import { ServiceInfoServiceApiRemoteService } from '../Services/api/services-info/services-info-service'
import { CreateProductsServiceOrdersUseCase } from '../Usecase/ProductsService/create-products-service-usecase'
import { ProductsServicesServiceApiRemoteService } from '../Services/api/products-services/products-services-service'
import { ListProductsServiceUseCase } from '../Usecase/ProductsService/list-products-service-usecase'
import { ShowOrdersUseCase } from '../Usecase/Orders/show-order-usecase'
import { ShowOmieOrderDetailsUseCase } from '../Usecase/Orders/show-omie-order-details-usecase'
import { ShowBoxesUseCase } from '../Usecase/Boxes/show-boxes-usecase'
import { UpdateBoxesUseCase } from '../Usecase/Boxes/update-boxes-usecase'
import { ShowCubagesVariationsUseCase } from '../Usecase/CubagesVariations/show-cubages-variations-usecase'
import { UpdateubagesVariationsUseCase } from '../Usecase/CubagesVariations/update-cubages-variations-usecase'
import { DestroyCubagesVariationsUseCase } from '../Usecase/CubagesVariations/destroy-cubages-variations-usecase'
import { ShowCubagesUseCase } from '../Usecase/Cubages/show-cubages-usecase'
import { UpdateCubagesUseCase } from '../Usecase/Cubages/update-cubages-usecase'
import { DestroyCubagesUseCase } from '../Usecase/Cubages/destroy-cubages-usecase'
import { ShowProductUseCase } from '../Usecase/Products/show-product-usecase'
import { UpdateProductUseCase } from '../Usecase/Products/update-product-usecase'

const isAuthenticated = (): any => {
  return localStorage.getItem(`${Environment.CUSTOMER}:token`) !== null
}

console.log("isAuthenticated: ", isAuthenticated())

// Rota privada que requer autenticação
const PrivateRoute = ({ element }: any): JSX.Element => {
  return isAuthenticated()
    ? (
      <Flex sx={{ width: '100%', overflowX: 'hidden'}}>
         {element}
        </Flex>

      )
    : (
    <Navigate to="/signin" replace />
      )
}

const router = createBrowserRouter([
  {
    path: '/signin',
    element: (
      <SignIn
        loginUseCase={new LoginUseCase(new AuthenticationApiRemoteService(api))}
      />
    )
  },
  {
    path: '/dashboard',
    element: <PrivateRoute element={<Dashboard/> } />
  },
  {
    path: '/products-list',
    element: <PrivateRoute element={<ProductsList listProductUseCase={new ListProductUseCase(new ProductsApiRemoteService(api))} /> } />
  },
  {
    path: '/products-form',
    element: <PrivateRoute element={<ProductsForm updateProductUseCase={new UpdateProductUseCase(new ProductsApiRemoteService(api))} showProductUseCase={new ShowProductUseCase(new ProductsApiRemoteService(api))} listServiceInfoOrdersUseCase={new ListServiceInfoOrdersUseCase(new ServiceInfoServiceApiRemoteService(api))} listProductsServiceUseCase={new ListProductsServiceUseCase(new ProductsServicesServiceApiRemoteService(api))} listCubagesUseCase={new ListCubagesUseCase(new CubagesApiRemoteService(api))}  createProductUseCase={new CreateProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/products-form/:id',
    element: <PrivateRoute element={<ProductsForm updateProductUseCase={new UpdateProductUseCase(new ProductsApiRemoteService(api))}  showProductUseCase={new ShowProductUseCase(new ProductsApiRemoteService(api))} listServiceInfoOrdersUseCase={new ListServiceInfoOrdersUseCase(new ServiceInfoServiceApiRemoteService(api))} listProductsServiceUseCase={new ListProductsServiceUseCase(new ProductsServicesServiceApiRemoteService(api))} listCubagesUseCase={new ListCubagesUseCase(new CubagesApiRemoteService(api))}  createProductUseCase={new CreateProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/cubage-list',
    element: <PrivateRoute element={<CubageList destroyCubagesUseCase={new DestroyCubagesUseCase(new CubagesApiRemoteService(api))} updateCubagesUseCase={new UpdateCubagesUseCase(new CubagesApiRemoteService(api))} showCubagesUseCase={new ShowCubagesUseCase(new CubagesApiRemoteService(api))} destroyCubagesVariationsUseCase={new DestroyCubagesVariationsUseCase(new CubagesVariantsApiRemoteService(api))} updateubagesVariationsUseCase={new UpdateubagesVariationsUseCase(new CubagesVariantsApiRemoteService(api))} showCubagesVariationsUseCase={new ShowCubagesVariationsUseCase(new CubagesVariantsApiRemoteService(api))} createCubagesVariationsUseCase={new CreateCubagesVariationsUseCase(new CubagesVariantsApiRemoteService(api))} listBoxesUseCase={new ListBoxesUseCase(new BoxesApiRemoteService(api))} listCubagesUseCase={new ListCubagesUseCase(new CubagesApiRemoteService(api))} listProductUseCase={new ListProductUseCase(new ProductsApiRemoteService(api))} createCubagesUseCase={new CreateCubagesUseCase(new CubagesApiRemoteService(api))} /> } />
  },
  {
    path: '/box-list',
    element: <PrivateRoute element={<BoxList destroyBoxesUseCase={new DestroyBoxesUseCase(new BoxesApiRemoteService(api))} listBoxesUseCase={new ListBoxesUseCase(new BoxesApiRemoteService(api))} /> } />
  },
  {
    path: '/box-form',
    element: <PrivateRoute element={<BoxForm updateBoxesUseCase={new UpdateBoxesUseCase(new BoxesApiRemoteService(api))} showBoxesUseCase={new ShowBoxesUseCase(new BoxesApiRemoteService(api))}  createBoxesUseCase={new CreateBoxesUseCase(new BoxesApiRemoteService(api))}/> } />
  },
  {
    path: '/services-list',
    element: <PrivateRoute element={<ServicesList ListProductsServiceUseCase={new ListProductsServiceUseCase(new ProductsServicesServiceApiRemoteService(api))}/> } />
  },
  {
    path: '/services-form',
    element: <PrivateRoute element={<ServicesForm createProductsServiceOrdersUseCase={new CreateProductsServiceOrdersUseCase(new ProductsServicesServiceApiRemoteService(api))}  listServiceInfoOrdersUseCase={new ListServiceInfoOrdersUseCase(new ServiceInfoServiceApiRemoteService(api))}/> } />
  },
  {
    path: '/box-form/:id',
    element: <PrivateRoute element={<BoxForm updateBoxesUseCase={new UpdateBoxesUseCase(new BoxesApiRemoteService(api))} showBoxesUseCase={new ShowBoxesUseCase(new BoxesApiRemoteService(api))} createBoxesUseCase={new CreateBoxesUseCase(new BoxesApiRemoteService(api))}/> } />
  },
  {
    path: '/orders-list',
    element: <PrivateRoute element={<OrdersList listOrdersUseCase={new ListOrdersUseCase(new OrdersApiRemoteService(api))}  listProductUseCase={new ListProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/orders-form',
    element: <PrivateRoute element={<OrdersForm  createProductUseCase={new CreateProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/production-list',
    element: <PrivateRoute element={<ProductionList updateServiceOrdersUseCase={new UpdateServiceOrdersUseCase(new ServiceOdersServiceApiRemoteService(api))} showServiceOrdersUseCase={ new ShowServiceOrdersUseCase(new ServiceOdersServiceApiRemoteService(api))} listServiceOrdersUseCase={new ListServiceOrdersUseCase(new ServiceOdersServiceApiRemoteService(api))}/> } />
  },
  {
    path: '/production-form',
    element: <PrivateRoute element={<OrdersForm  createProductUseCase={new CreateProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/production-details/:osId',
    element: <PrivateRoute element={<ProductionDetails updateServiceOrdersUseCase={new UpdateServiceOrdersUseCase(new ServiceOdersServiceApiRemoteService(api))} showServiceOrdersUseCase={ new ShowServiceOrdersUseCase(new ServiceOdersServiceApiRemoteService(api))} listServiceOrdersUseCase={new ListServiceOrdersUseCase(new ServiceOdersServiceApiRemoteService(api))} /> } />
  },
  {
    path: '/collaborators-list',
    element: <PrivateRoute element={<CollaboratorsList listProductUseCase={new ListProductUseCase(new ProductsApiRemoteService(api))} /> } />
  },
  {
    path: '/collaborators-form',
    element: <PrivateRoute element={<CollaboratorsForm  createProductUseCase={new CreateProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/financial-list',
    element: <PrivateRoute element={<FinancialList billProductOrderOrdersUseCase={new BillProductOrderOrdersUseCase(new OrdersApiRemoteService(api))} listOrdersUseCase={new ListOrdersUseCase(new OrdersApiRemoteService(api))}/> } />
  },
  {
    path: '/financial-form',
    element: <PrivateRoute element={<FinancialForm  createProductUseCase={new CreateProductUseCase(new ProductsApiRemoteService(api))}/> } />
  },
  {
    path: '/financial-details/:orderId',
    element: <PrivateRoute element={<FinancialDetails showOmieOrderDetailsUseCase={new ShowOmieOrderDetailsUseCase(new OrdersApiRemoteService(api))} showOrdersUseCase={new ShowOrdersUseCase(new OrdersApiRemoteService(api))}  billProductOrderOrdersUseCase={new BillProductOrderOrdersUseCase(new OrdersApiRemoteService(api))} listOrdersUseCase={new ListOrdersUseCase(new OrdersApiRemoteService(api))}/> } />
  },
  
  {
    path: '/',
    element: isAuthenticated() ? <PrivateRoute element={<Dashboard/> } /> : <SignIn
    loginUseCase={new LoginUseCase(new AuthenticationApiRemoteService(api))}
    />
  },

  {
    path: '*',
    element: <NotFoundPage />
  }
])

const Router = (): JSX.Element => {
  return <RouterProvider router={router} />
}

export default Router
