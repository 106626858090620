
import { Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';
import { SelectComponent } from '../../../Form/Select/select.component';
import { Flex } from '../../../Flex/flex.component';
import { FaChevronLeft } from 'react-icons/fa';


interface ProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    description: string
  }
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  
interface IDrawerComponent extends DrawerProps {
    onToggle: (state: boolean) => void;
    title: string;
    subTitle?: string;
    search?: boolean;
    searchFunction?: () => void;
    titleStyle?: object
    subTitleStyle?: object
    open: boolean;
    handleSelect: (category: string) => void;
   

}
const VariantsListDrawer : FC<IDrawerComponent> = ({ onToggle, title, subTitle, open, titleStyle = {}, subTitleStyle = {}, handleSelect  }) => {
  const [newVariation, setNewVariation] = useState(false)
  const [variantSelected,setVariantSelected] = useState('')
    const theme = useTheme();
    const { handleSubmit, register, control, formState } = useForm<any>({
        resolver: yupResolver(SignInSchemaValidation),
      });

      const { errors } =  formState;

      const options = [
        {
            label: 'Cor',
            value: 'color'
        },
        {
            label: 'Tamanho',
            value: 'size'
        },
        {
            label: 'Quantidade',
            value: 'quantity'
        },
        {
            label: 'Voltagem',
            value: 'volts'
        },
        {
            label: 'Volume Lado 1',
            value: 'volume1'
        },
        {
            label: 'Volume Lado 2',
            value: 'volume2'
        },
        {
            label: 'QTD de Caixas e Tampas',
            value: 'qtdbox'
        },
        {
            label: 'Padrão',
            value: 'usual'
        }
    ]


    return (
        <Drawer open={open} onClose={onToggle} anchor='right' style={{ zIndex: 99999999 }} sx={{ '& .MuiDrawer-paper': { overflowY: 'auto' }}}>
          {newVariation ? (
            <Grid2 sx={{ minWidth: '650px', padding: '30px 30px'}} >

              <Button onClick={() => setNewVariation(false)} variant='text' LeftIcon={() => <FaChevronLeft size={18}/>} textSx={{ marginLeft: '-10px'}} sx={{ marginBottom: '20px', width: '150px'}}>
                Voltar
              </Button>
            
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >Nova Variação</Typography> 
                
            
               
                {subTitle && <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: theme.palette.text.secondary,  ...subTitleStyle}} >Selecione a variação abaixo</Typography> }

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                  <SelectComponent name='variation' label='Variação' options={options} control={control} error={!!errors.customer_marital_status} helperText={errors?.customer_marital_status?.message || ''} onChangeCallback={e => setVariantSelected(e)} placeholder='Selecionar Variação'/>
                </Grid2>

                <Grid2 size={12}>
                  {!!variantSelected && (
                    <>
                      <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginTop: '40px'}} >Opções Disponíveis</Typography>
                      <Divider sx={{ marginTop: '20px', marginBottom: '30px'}} />
                    </>
                  )}

                  {variantSelected === 'color' && (
                    <>
                    
                     {['Amarelo','Azul','Azul Claro','Bege','Branco','Cinza','Laranja','Marrom','Pink','Prata','Preto','Rosa','Roxo','Verde','Vermelho','Vinho']?.map(item => (
                        <Grid2 size={12} sx={{ marginTop: '20px'}} key={item}>
                          <Switch label={item} name={`swtich-${item}`} control={control} onChangeCallback={() => handleSelect(item)}/>
                        </Grid2>
                    )) }
                    
                    </>  
                  )}

                  {variantSelected === 'volts' && (
                    <>
                    
                     {['110V','220V']?.map(item => (
                        <Grid2 size={12} sx={{ marginTop: '20px'}} key={item}>
                          <Switch label={item} name={`swtich-${item}`} control={control} onChangeCallback={() => handleSelect(item)}/>
                        </Grid2>
                    )) }
                    
                    </>  
                  )}

                  {variantSelected === 'volume1' && (
                    <>
                    
                     {['100 ML','120 ML','160 ML','180 ML','210 ML','220 ML','2270 ML','300 ML','360 ML','400 ML','500 ML','550 ML','600 ML','660 ML','700 ML','800 ML', '900 ML', '1000 ML', '1500 ML', '2000 ML',]?.map(item => (
                        <Grid2 size={12} sx={{ marginTop: '20px'}} key={item}>
                          <Switch label={item} name={`swtich-${item}`} control={control} onChangeCallback={() => handleSelect(item)}/>
                        </Grid2>
                    )) }
                    
                    </>  
                  )}

                  {variantSelected === 'volume2' && (
                    <>
                    
                     {['500 ML','600 ML','650 ML','700 ML','800 ML', '900 ML', '1000 ML', '1500 ML', '2000 ML',]?.map(item => (
                        <Grid2 size={12} sx={{ marginTop: '20px'}} key={item}>
                          <Switch label={item} name={`swtich-${item}`} control={control} onChangeCallback={() => handleSelect(item)}/>
                        </Grid2>
                    )) }
                    
                    </>  
                  )}
                 
                 
                </Grid2>
            <Grid2 size={12}>
         
            <Divider sx={{ marginTop: '20px', marginBottom:'10px'}} />
            <Button onClick={() => {}} sx={{ width: '200px'}}>Salvar</Button>

           

          
        </Grid2>
            </Grid2>
          ) : (
            <Grid2 sx={{ minWidth: '650px', padding: '30px 30px'}} >
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >{title}</Typography>
                {/* {subTitle && <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: theme.palette.text.secondary,  ...subTitleStyle}} >{subTitle}</Typography> } */}

               
                <Grid2 size={12}>
                    <Grid2 size={12} sx={{ marginTop: '20px'}}>
                        <Button onClick={() => setNewVariation(true)} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} style={{  width: '200px'}} LeftIcon={() => <GoPlus size={18} />}>Nova Variação</Button> 
                    </Grid2>

                    <Divider sx={{ marginTop: '20px', marginBottom:'30px'}} />

                    <Typography sx={{ fontSize: '1.6rem', fontWeight: 'bold', ...titleStyle}} >Variações Existentes: </Typography>
                    <Typography sx={{ fontSize: '1rem', fontWeight: '400', ...titleStyle}} >Nenhuma variação cadastrada para este produto.</Typography>
                  
                </Grid2>
                    </Grid2>
          )}
            
           
           </Drawer>
)
}

export { VariantsListDrawer }