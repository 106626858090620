import { Box, Paper, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { GoPlus } from "react-icons/go";
import { Table } from '../../../Components/Table/table.component';
import { useNavigate } from 'react-router-dom';
import { ListProductUseCase } from '../../../Usecase/Products/list-product-usecase';
import { useListOrders } from './orders-list.hook';
import { Tag } from '../../../Components/Tag/tag.component';
import {  normalizeSalePaymentStatusColors, normalizeSalePaymentStatusTitle } from '../../../utils/normalizeSalePaymentStatus';
import { background } from '@chakra-ui/react';
import { normalizeDeliveryPaymentStatusColors, normalizeDeliveryPaymentStatusTitle } from '../../../utils/normalizeSaleDeliveryStatus';
import { ButtonActionMenu } from '../../../Components/Form/ButtonActionsMenu/button-action-menu.component';
import { FiEye } from 'react-icons/fi';
import SaleDetailsModal from '../../../Components/Modals/variants/sale-details/sale-details-variant.component';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';
import { ListOrdersUseCase } from '../../../Usecase/Orders/list-orders-usecase';
import { FormatMoney } from '../../../utils/formatMoney';

interface IOrdersList {
    listProductUseCase: ListProductUseCase
    listOrdersUseCase: ListOrdersUseCase
}
const OrdersList : FC<IOrdersList> = ({ listProductUseCase, listOrdersUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listOrders, list, data, handleShowDetails, showDetails, setShowDetails } = useListOrders({
        listProductUseCase,
        listOrdersUseCase
    })

    useEffect(() => {
        listOrders({ page: 1})
    }, [])

    console.log("list: ", list)


    const columns = useMemo(() => {
        const createColumns: any[] = [ 
          {
            field: 'orderId',
            headerName: 'Pedido',
       
            flex: 1,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{`#${params.value}`}</Typography></Box>
          },
          {
            field: 'createdAt',
            headerName: 'Data',
            flex: 1,
          },
          {
            field: 'customer',
            headerName: 'Cliente',
            flex: 1,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography >{`${params?.value?.name || 'Não informado'}`}</Typography></Box>
          },
          {
            field: 'totalValue',
            headerName: 'Total',
            flex: 1,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography >{`${FormatMoney(Number(params?.value))}`}</Typography></Box>
          },
          {
            field: 'products',
            headerName: 'Produtos',
            flex: 1,
            renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{`${params?.value?.length}`}</Typography></Box>
          },
          // {
          //   field: 'payment_status',
          //   headerName: 'Status de Pagamento',
          //   width: 250,
          //   renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center', margin: 0, padding: 0}}><Tag title={normalizeSalePaymentStatusTitle(params.value)} boxStyle={{ backgroundColor: normalizeSalePaymentStatusColors(params.value)?.bgColor }} titleStyle={{ color: normalizeSalePaymentStatusColors(params.value)?.textColor, fontSize: '15px' }} /></Box>
          // },
          // {
          //   field: 'delivery_status',
          //   headerName: 'Status de Envio',
          //   width: 200,
          //   renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center', margin: 0, padding: 0}}><Tag title={normalizeDeliveryPaymentStatusTitle(params.value)} boxStyle={{ backgroundColor: normalizeDeliveryPaymentStatusColors(params.value)?.bgColor }} titleStyle={{ color: normalizeDeliveryPaymentStatusColors(params.value)?.textColor, fontSize: '15px' }} /></Box>
          // },
          // {
          //   field: 'id',
          //   flex: 1,
          //   headerName: 'Ações',
          //   width: 150,
          //   // renderCell: (params: any) => <ButtonActionMenu options={[
          //   //   {
          //   //     title: 'Marcar como embalada',
          //   //     onClick: () => {}
          //   //   },
          //   //   {
          //   //     title: 'Notificar Envio',
          //   //     onClick: () => {}
          //   //   },
          //   //   {
          //   //     title: 'Arquivar',
          //   //     onClick: () => {}
          //   //   }
          //   // ]} />
          //   renderCell: (params: any) => <Box sx={{height: '100%', display: 'flex', alignItems: 'center', margin: 0, padding: 0}}><Button style={{ height: '40px', width: '140px', gap: '10px'}} variant='outlined' onClick={() => handleShowDetails(params.row.code)} LeftIcon={() => <FiEye size={23} />} leftIconSx={{ marginRight: 2}} textSx={{ fontSize: '14px'}}>Detalhes</Button></Box>
          // },
        ];
      
        return createColumns;
      }, []);
   

    return (
        <BaseScreen>
            <Paper >
            
              {/* {showDetails && <SaleDetailsModal  onClose={() => setShowDetails(undefined)} data={showDetails} type='sale' />} */}
                <BaseContentPage 
                    title='Vendas'
                    breadcrumb={[{ title: 'Vendas', link: "/Orders-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '130px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => navigate('/box-form')}>Adicionar</Button>}
                >
                  {!!list?.length ? <Table data={list} totalCount={0} columns={columns} onPageChange={listOrders} /> : <Placeholder description='Nenhum dado encontrado.' />}

                  
                  
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { OrdersList }