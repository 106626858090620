import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Flex } from '../../../Flex/flex.component';
import { Button } from '../../../Form/Button/button.scomponent';
import { GoPlus } from 'react-icons/go';
import { CubageVariantFormDrawer } from '../../../Drawer/variants/cubage-variant-form/cuabge-variant-form-drawer.component';
import { ISaleEntity, ISaleProductProps } from '../../../../Services/api/sales/sales.interface';

interface ISaleProductTableProps {
    sale: ISaleEntity
}


const SaleProductTable : FC<ISaleProductTableProps> = ({ sale }) => {
  const [showNewVariant, setShowNewVariant] = useState(false)
  function createData(item: ISaleProductProps) : ISaleProductProps {
    return item
  }

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
         
          <TableCell component="th" scope="row" width={'90%'}>

            <Flex sx={{ alignItems:  'center'}}>
                <Box component='img' src={row.image} sx={{ width: '80px', height: '80px', objectFit: 'contain'}} />
                <Flex sx={{ height: '100%', alignItems: 'center', marginLeft: '20px'}}>
                  <Typography>{row.name}</Typography>
                </Flex>
                
            </Flex>
           
          </TableCell>
          
          <TableCell  width={'10%'} >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                

                <Box>
                  {/* <Typography sx={{ fontSize: '16px'}} gutterBottom component="div">Sku: {row.sku}</Typography> */}
                  <Typography sx={{ fontSize: '16px'}} gutterBottom component="div">{row.quantity}x {row.total}</Typography>
                  <Typography sx={{ fontSize: '16px'}} gutterBottom component="div">{row.variant}</Typography>
                </Box>


                {/* <Box sx={{ marginTop: '40px', borderBottom: 'solid 1px #dedede', marginBottom: '20px'}}>
                  <Typography variant="h6" gutterBottom component="div">Arte</Typography>
                </Box> */}
               
                
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const rows = sale?.products?.map(i => {
    return createData(i)
        
     
  })

  const handleToggle = () => {
    setShowNewVariant(prev => !prev)
  }
   
      return(
        <>
        {/* <CubageVariantFormDrawer open={showNewVariant} handleSelect={() => {}} onToggle={handleToggle} title='Nova Variação de Produto' subTitle='Preencha os dados abaixo' boxOptions={[{ label: 'test', value: '1'}]} cubageVariantSelected={cubageVariantSelected}  /> */}
       
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                
                <TableCell align='left' sx={{ fontWeight: 'bold', fontSize: '19px'}} width={'80%'}>Produtos</TableCell>
              
                <TableCell width='20%' />
              
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <Row key={row.name} row={row} />
            ))}
            </TableBody>
        </Table>
        </TableContainer>
        </>
      )

}

export { SaleProductTable }