import { Box, List, ListItem, Divider, Paper, useTheme, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { IoHomeOutline, IoNewspaperOutline, IoOptionsOutline } from 'react-icons/io5';
import { FaChevronUp, FaChevronDown, FaBox, FaUsers } from 'react-icons/fa';
import { FiShoppingCart } from 'react-icons/fi';
import { Button } from '../Form/Button/button.scomponent';
import { useNavigate } from 'react-router-dom';
import { AiOutlineDropbox } from "react-icons/ai";
import { BsCurrencyDollar } from "react-icons/bs";
import { MdOutlinePrecisionManufacturing } from "react-icons/md";

interface MakeButtonProps {
  title: string;
  LeftIcon: () => JSX.Element; 
  action: () => void;
}

interface SectionItem {
  title: string;
  icon: () => JSX.Element;
  action: () => void;
}

const SidebarContainer = styled(Paper)(({ theme }) => ({
    width: 257,
  backgroundColor: theme.palette.background.paper,
  height: '100vh',
  paddingTop: theme.spacing(2),
  borderRadius: 0
}));

const MakeButton: React.FC<MakeButtonProps> = ({ title, LeftIcon, action }) => {
  const theme = useTheme();
 
  return (
    <Button 
      onClick={action}
      LeftIcon={() => <LeftIcon />}
      variant="text"
      textSx={{ 
        color: theme.palette.text.secondary, 
        fontWeight: 400, 
        textTransform: 'capitalize', 
        fontSize: '0.875rem',
      }}
      sx={{
        padding: '10px 24px',
        borderRadius: 2,
        width: '100%',
        '&:hover': {
          backgroundColor: 'rgba(132, 168, 2, 0.3)',
          '& .MuiTypography-root': {
            color: theme.palette.primary.dark
          },
        },
      }}
    >
      {title}
    </Button>
  );
};

const Section: FC<{ items: { title: string; icon: () => JSX.Element; action: () => void }[] }> = ({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <ListItem key={index}>
          <MakeButton title={item.title} LeftIcon={item.icon} action={item.action} />
        </ListItem>
      ))}
    </>
  );
};

const Sidebar: FC = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  const sections = [
    {
        title: 'Geral',
      items: [
        { title: 'Home', action: () => navigate('/dashboard'), icon: () => <IoHomeOutline size={18} color={theme.palette.text.secondary} /> },
        
        // { title: 'Colaboradores', action: () => navigate('/collaborators-list'), icon: () => <FaUsers size={18} color={theme.palette.text.secondary} />  },
        { title: 'Pedidos', action: () => navigate('/orders-list'), icon: () => <IoNewspaperOutline size={18} color={theme.palette.text.secondary} />  },
        // { title: 'Serviços', action: () => navigate('/services-list'), icon: () => <IoNewspaperOutline size={18} color={theme.palette.text.secondary} />  },
        { title: 'Caixas', action: () => navigate('/box-list'), icon: () => <AiOutlineDropbox size={18} color={theme.palette.text.secondary} />  },
        { title: 'Cubagem', action: () => navigate('/cubage-list'), icon: () => <FaBox size={18} color={theme.palette.text.secondary} /> },
        { title: 'Produtos', action: () => navigate('/products-list'), icon: () => <FiShoppingCart size={18} color={theme.palette.text.secondary} />  },
        // { title: 'Variações', action: () => navigate('/variations-list'), icon: () => <IoOptionsOutline size={20} color={theme.palette.text.secondary} /> },
        { title: 'Produção', action: () => navigate('/production-list'), icon: () => <MdOutlinePrecisionManufacturing size={20} color={theme.palette.text.secondary} /> },
        // { title: 'Financeiro', action: () => navigate('/financial-list'), icon: () => <BsCurrencyDollar size={20} color={theme.palette.text.secondary} /> }
      ],
    },
    // {
    //     title: 'Configurações',
    //   items: [
    //     { title: 'Configurações', icon: () => <FaChevronUp size={18} color={theme.palette.text.secondary} /> },
    //     { title: 'Sobre', icon: () => <FaChevronDown size={18} color={theme.palette.text.secondary} /> },
    //   ],
    // },
  ];

  return (
 
        <SidebarContainer>
        
        <List>
          {sections.map((section, index) => (
            <Box key={index}>
              <Typography sx={{ fontWeight: 500, color: theme.palette.primary.dark, marginLeft: '20px', fontSize: '1rem', marginBottom: '10px' }}>{section.title}</Typography>
              <Section items={section.items} />
              {index < sections.length - 1 && <Divider sx={{ width: '90%', margin: 'auto', marginTop: '20px',  marginBottom: '20px'}} />} {/* Adiciona Divider entre as seções */}
            </Box>
          ))}
        </List>
      </SidebarContainer>
   
   
  );
};

export { Sidebar };
