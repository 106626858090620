import React, { useEffect, useMemo, useRef, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { CreateBoxesUseCase } from "../../../Usecase/Boxes/create-boxes-usecase";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from 'yup'
import { useNavigate, useParams } from "react-router-dom";
import { ICreateBoxPayload } from "../../../Services/api/boxes/boxes.interface";
import { IOptionsProps } from "../../../Components/Form/Select/select.component";
import { ListServiceInfoOrdersUseCase } from "../../../Usecase/ServicesInfo/list-service-info-usecase";
import { CreateProductsServiceOrdersUseCase } from "../../../Usecase/ProductsService/create-products-service-usecase";
import { IProductServiceEntity, IProductsServicePayload } from "../../../Services/api/products-services/products-services-interface";
import { CreateProductUseCase } from "../../../Usecase/Products/create-product-usecase";
import { ListCubagesUseCase } from "../../../Usecase/Cubages/list-cubages-usecase";
import { ICubageEntity } from "../../../Services/api/cubages/cubages.interface";
import { ListProductsServiceUseCase } from "../../../Usecase/ProductsService/list-products-service-usecase";
import { ShowProductUseCase } from "../../../Usecase/Products/show-product-usecase";
import { UpdateProductUseCase } from "../../../Usecase/Products/update-product-usecase";
import { ICubageVariantAssociated, ICubageVariantEntity } from "../../../Services/api/cubages-variants/cubages-variants.interface";

interface IUseCreateProductsProps {
    createProductUseCase: CreateProductUseCase
    listCubagesUseCase: ListCubagesUseCase
    listProductsServiceUseCase: ListProductsServiceUseCase
    listServiceInfoOrdersUseCase: ListServiceInfoOrdersUseCase
    showProductUseCase: ShowProductUseCase
    updateProductUseCase: UpdateProductUseCase
}

export interface IProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    promotionalPrice: string
    description: string
    billingType:  'service' | 'sale'
    service: IServicesProps
    [key: `cubage-${string}-variant-${string}`]: boolean;
}
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

  export const billingOptions = [
    
    {
      "value": "sale",
      "label": "Faturar como venda"
    },
    {
      "value": "service",
      "label": "Faturar como serviço"
    }
  ];
  


const CreateBoxSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    description: Yup.string().required('Descrição é obrigatório'),
    tributaryRegime: Yup.string().required('Regime tributário é obrigatório'),
    lcCode: Yup.string().required('Código LC é obrigatório'),
    nbsCode: Yup.string().required('Código NBS é obrigatório'),
    unitaryPrice: Yup.string().required('Preço unitário é obrigatório'),
    municipalityCode: Yup.string().required('Código Municipal é obrigatório'),
  });


interface IServicesProps {
	name: string
  description: string
  tributaryRegime: string;
  lcCode: string
  nbsCode: string
  unitaryPrice: string
  municipalityCode: string
}

export function useCreateProducts({ createProductUseCase, listCubagesUseCase, listProductsServiceUseCase, listServiceInfoOrdersUseCase, showProductUseCase, updateProductUseCase }: IUseCreateProductsProps){
   
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState<File[]>([]);
    const [showCategoriesModal, setShowCategoriesModal] = useState(false)
    const [categories,setCategories] = useState<string[]>([])
    const [showSEOModal, setShowSEOModal] = useState(false)
    const [showCubageModal, setShowCubageModal] = useState(false)
    const [showInternalCode, setShowInternalCode] = useState(false)
    const [showVariationsModal, setShowVariationsModal] = useState(false)
    const [internalCode, setInternalCode] = useState('')
    const [variations,setVariations] = useState<string[]>([])
    const [cubagesList, setCubagesList] = useState<ICubageEntity[]>([])
    const [productsServicesList, setProductsServicesList] = useState<IProductServiceEntity[]>([])
    const [productsServicesOptions, setProductsServicesOptions] = useState<IOptionsProps[]>([])
    const [tributaryRegimeOptions, setTributaryRegimeOptions] = useState<IOptionsProps[]>()
    const [lcCode, setLcCode] = useState<IOptionsProps[]>()
    const [nbsCode, setNbsCode] = useState<IOptionsProps[]>()
    const [data, setData] = useState<IProductEntity>()
    const [cubagesSelected, setCubagesSelected] = useState<ICubageVariantAssociated[]>([])

    const params = useParams()
    
    const navigate = useNavigate()

    const { handleSubmit, register, control, formState, watch, setValue, unregister } = useForm<IProductProps>({
        // resolver: yupResolver(CreateBoxSchemaValidation),
      });

    const billingTypeValue = watch('billingType')
 
      const { errors } =  formState

      const listServicesInfo = async () => {
        
        try {
          setLoading(true)
          
          const listResult = await listServiceInfoOrdersUseCase.handle();
  
          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }
  
         const list = listResult.getValue()
  
         const normalizeTributary = list?.tributaryRegimeCodes?.map(i => {
          return {
            value: i.cIdTrib,
            label: i.cDescricao
          }
         })

         const normalizeTLcCides = list?.lcCodes?.map(i => {
          return {
            value: i.cCodigo,
            label: i.cDescricao
          }
         })

         const normalizeNbsCodes = list?.nbsCodes?.map(i => {
          return {
            value: i.cNBS,
            label: i.cDescricao
          }
         })

         setTributaryRegimeOptions(normalizeTributary)
         setLcCode(normalizeTLcCides)
         setNbsCode(normalizeNbsCodes)


        } catch (error) {
          console.log("error listServices function: ", error)
        } finally {
          setLoading(false);
        }
      };

    const { showToast } =  useToast()

    const dragAndDropRef = useRef<any>(null);


    // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //   setValue(newValue);
    // };

    const  removeCubageFields = (payload: Record<string, any>): Record<string, any> => {
      return Object.fromEntries(
        Object.entries(payload).filter(([key]) => !key.startsWith("cubage-"))
      );
    }

    const extractCubage = (data: any) => {
      const extractedData = [];
    
      for (const key in data) {
        const match = key.match(/cubage-([a-f0-9-]+)-variant-([a-f0-9-]+)/);
        if (match && data[key] === true) {
          extractedData.push({
            cubage: match[1],
            variant: match[2]
          });
        }
      }
    
      return extractedData;
    };

    const handleAction: SubmitHandler<IProductProps> = async (values) => {
  
    try {
        setLoading(true)
        console.log("values: ", JSON.stringify(values))
        let normalizeVales : any = {...values}
        const cubages = extractCubage(normalizeVales).map(item => item.variant)
        normalizeVales.service = false
        normalizeVales.nsStoreIds = [5061090]
        normalizeVales = removeCubageFields(normalizeVales)

        console.log('normalizeVales:', normalizeVales);
        // Example login logic (replace with your actual login use case)

        
        console.log("cubages data: ", cubages)

        const formData = new FormData();

        Object.keys(normalizeVales).forEach((key) => {
        let value = normalizeVales[key];
        if (key === 'price' || key === 'promotionalPrice') {
          return
        }
        formData.append(key, value);
        });
        console.log("formData: ", formData)

        // Anexar as imagens ao FormData
        const files = dragAndDropRef?.current?.files;
        files.forEach((file: File) => {
            formData.append('images', file);
        });

        console.log("cubages: ", cubages)
        if(cubages?.length) {
          cubages.forEach(item => {
            formData.append('cubageVariantsIds[]', JSON.stringify(item));
          });
        } 

       
       

        const storeResult = params?.id ? await updateProductUseCase.handle({data: formData, id: params?.id}) : await createProductUseCase.handle(formData);

        if (storeResult.isFailure) {
        
        showToast(String(storeResult?.error?.error), 'error');
        return;
        }

        showToast(params?.id ? 'Produto Editado' : 'Produto Cadastrado', 'success');
        
    
        navigate('/products-list');
    } catch (error) {
        // toast({
        //   position: 'top-right',
        //   render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao logar. Por favor, tente novamente'} />,
        // });
    } finally {
        setLoading(false);
    }
    };

    const handleSelectCategories = (e: string) => {
    const existsCategory = categories.find(category => category === e)

    if(existsCategory){
        const filtered = categories?.filter(category => category !== e)
        setCategories([...filtered])
    } else {
        setCategories(prev => [...prev, e])
    }
    }

    const handleListCubages = async () => {
        
        try {
          setLoading(true)
          
          const listResult = await listCubagesUseCase.handle(1);
  
          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }
  
         const list = listResult.getValue()
  
         if(list?.length){
            setCubagesList(list)
         }


        } catch (error) {
          console.log("error listCubages function: ", error)
        } finally {
          setLoading(false);
        }
    };

    const handleListProductsServices = async () => {
        
        try {
          setLoading(true)
          
          const listResult = await listProductsServiceUseCase.handle(1);
  
          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }
  
         const list = listResult.getValue()
  
         if(list?.data?.length){
            const normalize = list.data.map(i => ({
                value: i.id,
                label: i.name
            }))

            setProductsServicesOptions(normalize)
            setProductsServicesList(list.data)
         }


        } catch (error) {
          console.log("error handleListProductsServices function: ", error)
        } finally {
          setLoading(false);
        }
    };

    const handleShowProduct = async (productId: string) => {
        
      try {
        setLoading(true)
        
        const showResult = await showProductUseCase.handle(productId);

        if (showResult.isFailure) {
          showToast(String(showResult?.error?.error), 'error');
          return;
        }

       const product = showResult.getValue()

       if(product){
          setData(product)

          setValue('name', product.name)
          setValue('description', product.description)
          setValue('price', product.price)
          setValue('code', product.code)

          product?.cubagesVariantsAssociated?.map(item => {
            setValue(`cubage-${item.cubageVariant.cubage?.id}-variant-${item?.cubageVariantId}`, true)
          })

          if(product.createdOnOmie){
            setValue('createOnOmie', product.createdOnOmie)
          }
          

          if(product?.promotionalPrice){
            setValue('promotionalPrice', product.promotionalPrice)
          }

          if(product?.ncm){
            setValue('ncm', product.ncm)
          }

          product?.cubageVariants

          if(product?.cubagesVariantsAssociated?.length){
            setCubagesSelected(product.cubagesVariantsAssociated || [])
          }

          
      

          
       }

      } catch (error) {
        console.log("error handleShowProduct function: ", error)
      } finally {
        setLoading(false);
      }
    };

    const handleRemoveTemporary = (cubageVariant: ICubageVariantAssociated) => {
      console.log("handleRemoveTemporary: cubageVariant: ", cubageVariant)
      setCubagesSelected(cubagesSelected.filter((t) => t.cubageVariantId !== cubageVariant.cubageVariantId));

      console.log(`dado: cubage-${cubageVariant?.cubageVariant?.cubage?.id}-variant-${cubageVariant.cubageVariantId}`)
      setValue(`cubage-${cubageVariant?.cubageVariant?.cubage?.id}-variant-${cubageVariant.cubageVariantId}`, false)
    }

    const handleAddTemporary = (item: ICubageVariantAssociated) => {
      console.log("handleAddTemporary: cubageVariant: ", item)
      setCubagesSelected(prev => [...prev, item]);

      setValue(`cubage-${item?.cubageVariant?.cubage?.id}-variant-${item.cubageVariantId}`, true)
    }

    useEffect(() => {
      if(params.id){
        handleShowProduct(params.id)
      }
    },[params])
      

    return {
        loading,
        handleSubmit,
        handleAction,
        register,
        errors,
        control,
        listServicesInfo,
        handleSelectCategories,
   
        showCategoriesModal,
        setShowCategoriesModal,
        showSEOModal,
        setShowSEOModal,
        showCubageModal,
        setShowCubageModal,
        showInternalCode,
        setShowInternalCode,
        showVariationsModal,
        setShowVariationsModal,
        internalCode,
        setInternalCode,
        variations,
        setVariations,
        images,
        setImages,
        cubagesList,
        handleListCubages,
        categories,
        setCategories,
        dragAndDropRef,
        handleListProductsServices,
        billingTypeValue,
        productsServicesOptions,
        cubagesSelected,
        handleRemoveTemporary,
        handleAddTemporary
        
        
    }
}