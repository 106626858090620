import { SxProps, Theme, Box, Button as ButtonBase, ButtonProps, Typography, useTheme, CircularProgress } from '@mui/material';
import React, { FC } from 'react';
import { Flex } from '../../Flex/flex.component';

interface IButtonProps extends ButtonProps {
    children: React.ReactNode;
    variant?: 'contained' | 'outlined' | 'text'; 
    LeftIcon?: React.ElementType;
    RightIcon?: React.ElementType;
    leftIconSx?: SxProps<Theme>;
    rightIconSx?: SxProps<Theme>;
    textSx?: SxProps<Theme>;
    boxLeftSx?: SxProps<Theme>;
    isLoading?: boolean;
    size?: 'small' | 'medium' | 'large';
}

const Button: FC<IButtonProps> = ({
    isLoading = false,
    textSx,
    leftIconSx,
    rightIconSx,
    boxLeftSx,
    LeftIcon,
    RightIcon,
    sx = {},
    children,
    variant = 'contained',
    size = 'large',
    ...rest
}) => {
    const theme = useTheme();

    const variantStyles = {
        contained: {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.background.paper,
        },
        outlined: {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        text: {
            backgroundColor: 'transparent',
            color: theme.palette.primary.main,
        }
    };

    return (
        <ButtonBase
            sx={{
                gap: size === 'large' ? '20px' : '10px',
                display: 'flex',
                justifyContent: LeftIcon || RightIcon ? 'space-between' : 'center',
                alignItems: 'center',
                width: '100%',
                cursor: 'pointer',
                height: size === 'large' ? '44px' : '30px',
                ...variantStyles[variant],
                ...sx,
            }}
            {...rest}
        >
            {isLoading ? (
                <CircularProgress size={24} sx={{ color: theme.palette.background.paper }} />
            ) : (
                <Flex sx={{ gap: '10px', alignItems: 'center', ...boxLeftSx }}>
                    {LeftIcon && <LeftIcon sx={leftIconSx} />}
                    <Typography sx={textSx}>{children}</Typography>
                </Flex>
            )}
            {!isLoading && RightIcon && <RightIcon sx={rightIconSx} />}
        </ButtonBase>
    );
};

export { Button };
