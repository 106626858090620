/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { ICubageEntity, IUpdateCubageProductPayload } from "./cubages.interface";

export class CubagesApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public destroy = async (cubageProductId:string): Promise<Result<void>> => {
    try {
      console.log("cubageProductId: ", cubageProductId)
        const response = await this.service.delete(`/cubages/${cubageProductId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public update = async ({ cubageProductId, name}: IUpdateCubageProductPayload): Promise<Result<void>> => {
    try {
        const response = await this.service.put(`/cubages/${cubageProductId}`, { name });

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public create = async (name: string): Promise<Result<void>> => {
    try {
        const response = await this.service.post(`/cubages`, { name });

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public list = async (page: number): Promise<Result<ICubageEntity[]>> => {
    try {
        const response = await this.service.get(`/cubages`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public show = async (cubageId: string): Promise<Result<ICubageEntity>> => {
    try {
        const response = await this.service.get(`/cubages/${cubageId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
}
