/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { IBoxEntity, ICreateBoxPayload, IUpdateBoxPayload } from "./boxes.interface";

export class BoxesApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public destroy = async (boxId: string): Promise<Result<void>> => {
    try {
        const response = await this.service.delete(`/boxes/${boxId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public create = async (data: ICreateBoxPayload): Promise<Result<void>> => {
    try {
        const response = await this.service.post(`/boxes`, data );

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public list = async (page: number): Promise<Result<IBoxEntity[]>> => {
    try {
        const response = await this.service.get(`/boxes`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public show = async (boxId: string): Promise<Result<IBoxEntity | undefined>> => {
    try {
        const response = await this.service.get(`/boxes/${boxId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public update = async (data: IUpdateBoxPayload): Promise<Result<void>> => {
    try {
        const response = await this.service.put(`/boxes/${data.id}`, data );

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
}
