import React, { useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'


interface IuseListCollaboratorsProps {
    listProductUseCase: ListProductUseCase
}
export function useListCollaborators({ listProductUseCase }: IuseListCollaboratorsProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<IProductEntity[]>([])

    const { showToast } =  useToast()

    const listProdcuts = async (currentPage: number) => {
        
        try {
          setLoading(true)
          
        //   const listResult = await listProductUseCase.handle(currentPage);

        //   if (listResult.isFailure) {
       
        //     showToast(String(listResult?.error?.error), 'error');
        //     return;
        //   }

        //  const list = listResult.getValue()

        //  if(list){
        //     setData(list.data)
        //  }

        //  setPage(currentPage)
        } catch (error) {
          console.log("error listProducts function: ", error)
        } finally {
          setLoading(false);
        }
      };

    

    const list = useMemo(() => {
        const rows = data?.map(product => {

            return {
                id: product.id,
                name: product.name,
                code: product.code,
                price: product?.price || 'R$ 0.00',
                photo: product?.images[0]?.link || 'https://acdn.mitiendanube.com/stores/002/907/105/themes/common/logo-1336738559-1706047471-a90c2b04f7208c4f190adf866d8df0b51706047472-320-0.webp'
            }
        })

        return rows
    }, [data])

      

    return {
        loading,
        listProdcuts,
        list,
        data
        
    }
}