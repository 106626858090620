/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { IServiceInfoListResponse } from "./services-info-interface";

export class ServiceInfoServiceApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public list = async (): Promise<Result<IServiceInfoListResponse>> => {
    try {
        const response = await this.service.get(`/products-services/info-lists`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
  
}

