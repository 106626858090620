import { Grid2, Paper } from '@mui/material';
import React, { FC, useEffect } from 'react';
import * as Yup from 'yup';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { Input } from '../../../Components/Form/Input/input.component';
import { Loader } from '../../../Components/Loader';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';

import { CreateBoxesUseCase } from '../../../Usecase/Boxes/create-boxes-usecase';
import { useCreateServices } from './services-create.hook';
import { SelectComponent } from '../../../Components/Form/Select/select.component';
import { ListServiceInfoOrdersUseCase } from '../../../Usecase/ServicesInfo/list-service-info-usecase';
import { CreateProductsServiceOrdersUseCase } from '../../../Usecase/ProductsService/create-products-service-usecase';
import { InputCurrency } from '../../../Components/Form/InputCurrency/input-currency.component';

  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}


interface IServicesFormProps {
    listServiceInfoOrdersUseCase: ListServiceInfoOrdersUseCase
    createProductsServiceOrdersUseCase: CreateProductsServiceOrdersUseCase
}

const ServicesForm : FC<IServicesFormProps> = ({ listServiceInfoOrdersUseCase, createProductsServiceOrdersUseCase }) => {

    const {handleAction, handleSubmit, loading, register, tributaryRegimeOptions, control, errors, listServicesInfo, lcCode, nbsCode } = useCreateServices({
        listServiceInfoOrdersUseCase,
        createProductsServiceOrdersUseCase

    })

    useEffect(() => {
        listServicesInfo()
    }, [])

    
    return (
        <BaseScreen>
         
            <Paper >
                <BaseContentPage 
                    title='Serviços'
                    breadcrumb={[{ title: 'Serviços', link: "/services-list"}, { title: 'Novo Serviço'}]}
                >

                {loading && <Loader />}

                <Grid2 container spacing={5} sx={{ marginTop: 2}} component="form" onSubmit={handleSubmit(handleAction)}>


                <Grid2 size={6}>
                    <Input
                        label="Nome do Serviço"
                        placeholder="Preencha o nome"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        {...register('name')}
                    />
                </Grid2>

                <Grid2 size={6}>
                    <InputCurrency 
                        label="Preço Unitário"
                        placeholder="Preencha o preço unitário"
                        defaultValue={0}
                        error={!!errors.unitaryPrice}
                        helperText={errors.unitaryPrice?.message}
                        {...register('unitaryPrice')} />
                </Grid2>

                <Grid2 size={12}>
                    <Input
                        label="Descrição"
                        placeholder="Preencha a descrição"
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        {...register('description')}
                    />
                </Grid2>

                {tributaryRegimeOptions?.length && (
                     <Grid2 size={12} >
                     <SelectComponent 
                         label='Regime Tributário' 
                         options={tributaryRegimeOptions} 
                         control={control} 
                         error={!!errors.tributaryRegime} 
                         helperText={errors?.tributaryRegime?.message || ''} 
                         placeholder='Selecionar Regime Tributário'
                         {...register('tributaryRegime')} 
                     />
                 </Grid2>          
                )}

                {lcCode?.length && (
                     <Grid2 size={12} >
                     <SelectComponent 
                         label='Código LC' 
                         options={lcCode} 
                         control={control} 
                         error={!!errors.lcCode} 
                         helperText={errors?.lcCode?.message || ''} 
                          placeholder='Selecionar Código LC'
                         {...register('lcCode')} 
                     />
                 </Grid2>    
                )}

                {nbsCode?.length && (
                     <Grid2 size={12} >
                     <SelectComponent 
                         label='Código NBS' 
                         options={nbsCode} 
                         control={control} 
                         error={!!errors.nbsCode} 
                         helperText={errors?.nbsCode?.message || ''} 
                         placeholder='Selecionar Código BNS'
                         {...register('nbsCode')} 
                     />
                 </Grid2>    
                )}

                <Grid2 size={12}>
                    <Input
                        label="Código Municipal"
                        placeholder="Preencha o código"
                        error={!!errors.municipalityCode}
                        helperText={errors.municipalityCode?.message}
                        {...register('municipalityCode')}
                    />
                </Grid2>

                  

                <Button type="submit" sx={{ marginTop: '10px', width: '200px' }} isLoading={loading}>
                    SALVAR
                </Button>
                </Grid2>

              </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { ServicesForm };
