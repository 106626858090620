import { FormControl, FormControlLabel, RadioGroup, Radio as MuiRadio, RadioProps, Typography } from '@mui/material';
import { Controller, Control, FieldError, Merge, FieldErrorsImpl } from 'react-hook-form';
import React, { FC } from 'react';
import { Flex } from '../../Flex/flex.component';

interface IRadioOption {
  label: string;
  value: string | number;
}

interface IRadioProps extends RadioProps {
  label: string;
  name: string;
  control: Control<any>;
  defaultValue?: string | number;
  options: IRadioOption[];
  onChangeCallback?: (value: string | number) => void;
  flex?: boolean;
  labelStyle?: object;
  error?: boolean
  helperText?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}

const Radio: FC<IRadioProps> = ({ onChangeCallback, label, name, control, defaultValue = '', options, flex = false, labelStyle = {}, helperText, ...rest }) => {
  return (
    <FormControl component="fieldset">
      <Typography sx={labelStyle}>{label}</Typography>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <RadioGroup
            {...field}
            value={field.value}
            onChange={(e) => {
              const selectedValue = e.target.value;
              field.onChange(selectedValue); // Atualiza o valor no form
              if (onChangeCallback) {
                onChangeCallback(selectedValue); // Dispara o callback
              }
            }}
          >
            {flex ? (
              <Flex>
                {options.map((option) => (
                  <FormControlLabel
                    key={option.value}
                    value={option.value}
                    control={<MuiRadio {...rest} />}
                    label={option.label}
                  />
                ))}
              </Flex>
            ) : (
              options.map((option) => (
                <FormControlLabel
                  key={option.value}
                  value={option.value}
                  control={<MuiRadio {...rest} />}
                  label={option.label}
                />
              ))
            )}
          </RadioGroup>
        )}
      />
    </FormControl>
  );
};

export { Radio };
