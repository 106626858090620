

import { Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';
import { TagInput } from '../../../Form/TagInput/tag-input.component';
import TextArea from '../../../Form/Textarea/textarea.component';
import { IOptionsProps, SelectComponent } from '../../../Form/Select/select.component';
import { Select } from '../../../Form/Select';
import { ICreateCubageVariantPayload, ICubageVariantEntity, IUpdateCubageVariantPayload } from '../../../../Services/api/cubages-variants/cubages-variants.interface';


  
const CubageVariantFormSchemaValidation = Yup.object().shape({
    boxId: Yup.string().required('Selecione e caixa.'),
    description: Yup.string().required('Descrição é obrigatória.'),
    howManyInBox: Yup.string().required('Quantidade na caixa é obrigatória.'),
    howManyPackages: Yup.string().required('Quantidade de pacotes é obrigatória.'),
    howManyPerPackage: Yup.string().required('Quantidade por pacote é obrigatória.'),
    boxWeight: Yup.string().required('Peso da caixa é obrigatória.')
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  
interface IDrawerComponent extends DrawerProps {
    onToggle: (state: boolean) => void;
    title: string;
    subTitle?: string;
    search?: boolean;
    searchFunction?: () => void;
    titleStyle?: object
    subTitleStyle?: object
    open: boolean;
    handleSelect?: (category: string) => void;
    containerStyle?: object
    handleAction?: (data?: any) => Promise<void>
    cubageProductId?: string
    boxOptions: IOptionsProps[]
    cbFunction?: (data?: any) => void
    cubageVariantSelected: ICubageVariantEntity | undefined
}

const CubageVariantFormDrawer : FC<IDrawerComponent> = ({ onToggle, title, subTitle, open, titleStyle = {}, subTitleStyle = {}, containerStyle = {}, handleAction, cubageProductId, boxOptions, cbFunction, cubageVariantSelected  }) => {
    
    const theme = useTheme();
    const { handleSubmit, register, control, formState, setValue, reset } = useForm<any>({
        resolver: yupResolver(CubageVariantFormSchemaValidation),
      });

      const { errors } =  formState

      const handleSubmitForm: SubmitHandler<ICreateCubageVariantPayload> = async (values: any) => {

        if(handleAction){

            const payloadCreate : ICreateCubageVariantPayload = {
                cubageProductId: String(cubageProductId),
                data: {
                    ...values
                }
            }

            const payloadUpdate : IUpdateCubageVariantPayload = {
                cubageVariationId: String(cubageVariantSelected?.id),
                ...values
            }


            await handleAction(cubageVariantSelected?.id ? payloadUpdate : payloadCreate)
        }

       
        if(cbFunction){
            cbFunction()
        }
        
        reset()
      };

     
      useEffect(() => {
        if(cubageVariantSelected?.id){
            setValue('boxId', cubageVariantSelected.boxId)
            setValue('description', cubageVariantSelected.description)
            setValue('howManyInBox', cubageVariantSelected.howManyInBox)
            setValue('howManyPackages', cubageVariantSelected.howManyPackages)
            setValue('howManyPerPackage', cubageVariantSelected.howManyPerPackage)
            setValue('boxWeight', cubageVariantSelected.boxWeight)
        }
      }, [cubageVariantSelected])


    return (
        <Drawer open={open} onClose={onToggle} anchor='right' style={{ zIndex: 99999999 }}>
            <Grid2 sx={{ minWidth: '540px', padding: '30px 30px', ...containerStyle}} component='form' onSubmit={handleSubmit(handleSubmitForm)}>
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >{title}</Typography>
                {subTitle && <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: theme.palette.text.secondary,  ...subTitleStyle}} >{subTitle}</Typography> }

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                

                    <SelectComponent 
                        label='Caixa' 
                        options={boxOptions} 
                        control={control} 
                        error={!!errors.boxId} 
                        placeholder='Selecione a caixa'
                        helperText={errors?.boxId?.message || ''} 
                        {...register('boxId')} 
                    />
                    
                   
                </Grid2>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Descrição/Tamanho"
                        // prefix="https://.../produtos/"
                        placeholder='Ex: 100 ml'
                        error={!!errors.description}
                        helperText={errors.description?.message}
                        {...register('description')}
                    />
                </Grid2>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Quantidade na Caixa"
                        // prefix="https://.../produtos/"
                        error={!!errors.howManyInBox}
                        placeholder='Ex: 1.000'
                        helperText={errors.howManyInBox?.message}
                        {...register('howManyInBox')}
                    />
                </Grid2>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Quantidade de Pacotes"
                        // prefix="https://.../produtos/"
                        error={!!errors.howManyPackages}
                        placeholder='Ex: 20'
                        helperText={errors.howManyPackages?.message}
                        {...register('howManyPackages')}
                    />
                </Grid2>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Quantidade por Pacote"
                        // prefix="https://.../produtos/"
                        error={!!errors.howManyPerPackage}
                        placeholder='Ex: 50'
                        helperText={errors.howManyPerPackage?.message}
                        {...register('howManyPerPackage')}
                    />
                </Grid2>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Peso final da caixa (Kg)"
                  
                        // prefix="https://.../produtos/"
                        error={!!errors.boxWeight}
                        placeholder='Ex: 4.18'
                        helperText={errors.boxWeight?.message}
                        {...register('boxWeight')}
                    />
                </Grid2>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Button type='submit'>{cubageVariantSelected?.id ? 'Editar' :  'Adicionar'}</Button>
                </Grid2>
        
            </Grid2> 
        </Drawer>
)
}

export { CubageVariantFormDrawer }