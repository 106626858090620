import React, { useCallback, useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from "../../../Services/api/sales/sales.interface";
import { Environment } from "../../../Config/constants";
import { ListServiceOrdersUseCase } from "../../../Usecase/ServiceOrders/list-service-orders-usecase";
import IServiceOrdersListProps from "../../../Shared/Interfaces/service-orders-list.interface";
import IKanbanBoardProductionSchemaProps from "../../../Components/KanbanBoardProduction/interfaces/kanban-board-production-schema.interface";
import { IServiceOrderEntity, IUpdateServiceOrdersPayload, ProductionStatusEnum } from "../../../Services/api/service-orders/service-order.interface";
import { ShowServiceOrdersUseCase } from "../../../Usecase/ServiceOrders/show-service-orders-usecase";
import { title } from "process";
import { UpdateServiceOrdersUseCase } from "../../../Usecase/ServiceOrders/update-service-orders-usecase";
import { Task } from "../../Financial/Details/financial-details.hook";


interface IUseListProductionProps {
  listServiceOrdersUseCase: ListServiceOrdersUseCase
  showServiceOrdersUseCase: ShowServiceOrdersUseCase
  updateServiceOrdersUseCase: UpdateServiceOrdersUseCase
}
export function useListProduction({ listServiceOrdersUseCase, showServiceOrdersUseCase, updateServiceOrdersUseCase }: IUseListProductionProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<any[]>([])
    
    const [kanbanSchema, setKanbanSchema] = useState<IKanbanBoardProductionSchemaProps>()
    const [orderDetails, setOrderDetails] = useState<IServiceOrderEntity | undefined>()

    const { showToast } =  useToast()

    const transformData = (dados: IServiceOrderEntity[]) => {
      
      const tasks: Record<string, Task> = {};
      const initialOS : any = [];
      const onProductionOS : any = [];
      const finishsedOS : any = [];
      
      dados.forEach((item, index) => {
          // const taskId = `task-${index + 1}`;
          const taskId = item.id;
          tasks[taskId] = {
              id: taskId,
              content: {
                  os: `OS #${item.externalProductionId}`,
                  items: item.products
              }
          };

          if(item.stage === ProductionStatusEnum.INICIO){
            initialOS.push(taskId);
          } else if (item.stage === ProductionStatusEnum.PRODUCAO){
            onProductionOS.push(taskId)
          } else {
            finishsedOS.push(taskId)
          }
          
      });

      const result = {
          tasks,
          columns: {
            [ProductionStatusEnum.INICIO]: {
                  id: ProductionStatusEnum.INICIO,
                  title: 'Aguardando Início',
                  taskIds: initialOS,
              },
              [ProductionStatusEnum.PRODUCAO]: {
                  id: ProductionStatusEnum.PRODUCAO,
                  title: 'Em Produção',
                  taskIds: onProductionOS,
              },
              [ProductionStatusEnum.FINALIZACAO]: {
                  id: ProductionStatusEnum.FINALIZACAO,
                  title: 'Concluído',
                  taskIds: finishsedOS,
              },
          },
          columnOrder: [ProductionStatusEnum.INICIO, ProductionStatusEnum.PRODUCAO, ProductionStatusEnum.FINALIZACAO],
      };

      return result;
    }

    const listProduction = async (currentPage: number) => {
        
        try {
          setLoading(true)
          
          const listResult = await listServiceOrdersUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()

         const normalizeSchema : IKanbanBoardProductionSchemaProps = transformData(list?.data ?? [])
         setKanbanSchema(normalizeSchema)

        //  if(list?.data?.length){
        //     const normalizeResponse : IServiceOrderEntity[] = list?.data?.map(item => {

        //       const payload : any = {
        //         ...item,
        //         products: item?.products?.map(i => {
        //           const obj : IServiceOrdersListProps = {
        //             id: i.id,
        //             code: i.nsOrderProductId,
        //             description: i?.product?.name,
        //             created_at: new Date(i.createdAt), 
        //             status: i.status
    
        //           }
        
    
        //           return obj
                  
        //         })
        //       }
    
        //       return payload
        //     })
    
        //     setData(normalizeResponse)
        //  }

        
        
        // const test = [
        //   {
        //     id: '1',
        //     nuvem_sale_id: '1870',
        //     code: '1710047906',
        //     customer: 'DEISE AREAS',
        //     hub_sale_id: '6197',
        //     description: 'POTES PERSONALIZADOS 210 ML CARTAO TRIPLEX P1',
        //     quantity: '500',
        //     product_id: 'P01140',
        //     price: 342.40,
        //     os: '55683',
        //     card: 'P025-210',
        //     created_at: new Date('2024-04-24T16:29:00'),
        //     substrate: 'Triplex P1',
        //     weight: '305 gsm',
        //     category: 'PT210',
        //     file: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\155943805\\1466964433\\1870-DS-2D-Deisi-Sebold-Arêas-POTE-210ml-VERDE.pdf',
        //     finalFile: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\155943805\\1466964433\\1870-DS-2D-Deisi-Sebold-Arêas-POTE-210ml.pdf',
        //     dropboxFolder: '2024-05-09T15:36:00',
        //     status: 'ERRO'
        //   },
        //   {
        //     id: '2',
        //     nuvem_sale_id: '1659',
        //     code: '1695662531',
        //     customer: 'VITOR COTRIM MACIAS',
        //     hub_sale_id: '5810',
        //     description: 'COPOS PERSONALIZADO 100 ML CARTAO TRIPLEX P1',
        //     quantity: '500',
        //     product_id: 'P00020',
        //     price: 233.26,
        //     os: '55643',
        //     card: '418-100',
        //     created_at: new Date('2024-04-26T16:08:00'),
        //     substrate: 'Triplex P1',
        //     weight: '305 gsm',
        //     category: 'CP100',
        //     file: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\157526525\\1462052935\\1462052935(#1659)-DN-2D-Vítor-Cotrim-Macias--arte3-copo-100ml.pdf',
        //     finalFile: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\157526525\\1462052935\\1462052935(#1659)-DN-2D-Vítor-Cotrim-Macias-copo-100ml.pdf',
        //     dropboxFolder: '2024-05-06T15:46:00',
        //     status: 'ERRO'
        //   },
        //   {
        //     id: '3',
        //     nuvem_sale_id: '1659',
        //     code: '1695662530',
        //     customer: 'VITOR COTRIM MACIAS',
        //     hub_sale_id: '5810',
        //     description: 'COPOS PERSONALIZADO 100 ML CARTAO TRIPLEX P1',
        //     quantity: '500',
        //     product_id: 'P00020',
        //     price: 233.26,
        //     os: '55643',
        //     card: '418-100',
        //     created_at: new Date('2024-04-26T16:08:00'),
        //     substrate: 'Triplex P1',
        //     weight: '305 gsm',
        //     category: 'CP100',
        //     file: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\157526525\\1462052935\\1462052935(#1659)-DN-2D-Vítor-Cotrim-Macias--arte3-copo-100ml.pdf',
        //     finalFile: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\157526525\\1462052935\\1462052935(#1659)-DN-2D-Vítor-Cotrim-Macias--arte2-copo-100ml.pdf',
        //     dropboxFolder: '2024-05-06T15:46:00',
        //     status: 'ERRO'
        //   },
        //   {
        //     id: '4',
        //     nuvem_sale_id: '991',
        //     code: '1657779813',
        //     customer: 'FERNANDA DOS SANTOS RODRIGUES',
        //     hub_sale_id: '4735',
        //     description: 'COPOS PERSONALIZADO 500 ML CARTAO TRIPLEX P1',
        //     quantity: '2000',
        //     product_id: 'P00025',
        //     price: 1891.76,
        //     os: '55654',
        //     card: '330-500',
        //     created_at: new Date('2024-05-01T17:39:00'),
        //     substrate: 'Triplex P1',
        //     weight: '305 gsm',
        //     category: 'CP500',
        //     file: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\151765593\\1448985247\\991-2D-Fernanda-dos-Santos-Rodrigues-COPO-500ml-Arte-02.pdf',
        //     finalFile: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\151765593\\1448985247\\991-2D-Fernanda-dos-Santos-Rodrigues-COPO-500ml.pdf',
        //     dropboxFolder: '2024-05-06T16:33:00',
        //     status: 'ERRO'
        //   },
        //   {
        //     id: '5',
        //     nuvem_sale_id: '2100',
        //     code: '1720806006',
        //     customer: 'ACADEMIA EM FORMA LTDA-ME',
        //     hub_sale_id: '6539',
        //     description: 'COPOS PERSONALIZADO 100 ML CARTAO TRIPLEX P1',
        //     quantity: '500',
        //     product_id: 'P00020',
        //     price: 233.26,
        //     os: '55669',
        //     card: '421-100',
        //     created_at: new Date('2024-05-06T11:30:00'),
        //     substrate: 'Triplex P1',
        //     weight: '305 gsm',
        //     category: 'CP100',
        //     file: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\159508539\\1470524414\\1470524414(#2100)-DN-2D-Academia-Em-Forma-Ltda-me-aquatico-copo-100ml.pdf',
        //     finalFile: 'J:\\Papello Dropbox\\Automação Papello\\fluxo-customizacao\\159508539\\1470524414\\(#2100) DN 2D Academia Em Forma Ltda me copo 100ml (1).pdf',
        //     dropboxFolder: '2024-05-08T10:39:00',
        //     status: 'ERRO'
        //   },
          
        // ];
        

        //  if(list){
        //     setData(list)
        //  }

         setPage(currentPage)
        } catch (error) {
          console.log("error listProduction function: ", error)
        } finally {
          setLoading(false);
        }
      };

    const list = useMemo(() => {
        const rows = data?.map(sale => {

            return {
                ...sale,
                // products: sale.products.map(product => {
                //   return {
                //     ...product,
                     
                //   }
                // })
              
            }
        })

        return rows
    }, [data])

    const handleShowDetails = (code: string) => {
      console.log("code: ", code)
      // setShowDetails(code)
    } 

    const handleConfirmProductionItems = async (e:IUpdateServiceOrdersPayload) => {
      try {

      //   setLoading(true)
        
      //   const updateResult = await updateServiceOrdersUseCase.handle(e)

      //   if (updateResult.isFailure) {
      //     showToast(String(updateResult?.error?.error), 'error');
      //     return;
      //   }

      //  const dados = updateResult.getValue()

        
      //   showToast(String('Itens atualizados'), 'info');
        
      } catch (error) {
        console.log("erro handleConfirmProductionItems: ", error)
      } finally {
        setLoading(false);
      }
    
    }

    const handleShowOrderDetails = async (osId?: string) => {
        
      try {
        setLoading(true)
        
        const listResult = await showServiceOrdersUseCase.handle(String(osId))

        if (listResult.isFailure) {
          showToast(String(listResult?.error?.error), 'error');
          return;
        }

       const dados = listResult.getValue()

       if(dados){
        setOrderDetails(dados)
       }

  
     
    
      } catch (error) {
        console.log("error listProduction function: ", error)
      } finally {
        setLoading(false);
      }
    };

    

      

    return {
        loading,
        listProduction,
        list,
        data,
        handleShowDetails,
        setOrderDetails,
        handleConfirmProductionItems,
        kanbanSchema,
        handleShowOrderDetails,
        orderDetails
        
    }
}