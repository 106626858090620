import { Box, Paper, Typography, useTheme } from '@mui/material';
import React from 'react';
import { BaseScreen } from '../../Components/Templates/BaseScreen/basescreen.component';
import { BaseContentPage } from '../../Components/Templates/BaseContentPage/base-content-page.componen';
import { Button } from '../../Components/Form/Button/button.scomponent';
import { GoPlus } from "react-icons/go";
import { Table } from '../../Components/Table/table.component';

const Dashboard = () => {
    const theme = useTheme()
    return (
        <BaseScreen>
            <Paper >
                <BaseContentPage 
                    title='Dashboard'
                   
            
                >
                    <Typography>Dashboard Inicial com futuros gráficos</Typography>
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { Dashboard }