import { useMemo, useState } from "react";
import { useToast } from "../../../Hooks/ToasContext";
import { ListBoxesUseCase } from "../../../Usecase/Boxes/list-boxes-usecase";
import { IBoxEntity } from "../../../Services/api/boxes/boxes.interface";
import { DestroyBoxesUseCase } from "../../../Usecase/Boxes/destroy-boxes-usecase";


interface IUseListBoxesProps {
  listBoxesUseCase: ListBoxesUseCase
  destroyBoxesUseCase: DestroyBoxesUseCase
}
export function useListBoxes({ listBoxesUseCase, destroyBoxesUseCase }: IUseListBoxesProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<IBoxEntity[]>([])
    const [boxToDestroy, setBoxToDestroy] = useState('')
    const [totalRegisters, setTotalRegisters] = useState(1)
   
    const { showToast } =  useToast()

    const listBoxes = async ({ page: currentPage }: any) => {
        
        try {
          setLoading(true)
          
          const listResult = await listBoxesUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()

         if(list){
            setData(list)
            setTotalRegisters(list.length)
         }

    
         setPage(currentPage)
        } catch (error) {
          console.log("error listBoxes function: ", error)
        } finally {
          setLoading(false);
        }
      };

    const list = useMemo(() => {
        const rows = data?.map(product => {

            return {
                id: product.id,
                code: product.code,
                size: `${product.length}x${product.width}x${product.height} cm`,
            }
        })

        return rows
    }, [data])

    const handleDestroyBox = async () => {
        
      try {
        setLoading(true)
        
        const destroyResult = await destroyBoxesUseCase.handle(boxToDestroy);

        if (destroyResult.isFailure) {
     
          showToast(String(destroyResult?.error?.error), 'error');
          return;
        }

        setBoxToDestroy('')
        showToast('Removido com sucesso.', 'success');

       await listBoxes(1)
      } catch (error) {
        console.log("error destroyBoxes function: ", error)
      } finally {
        setLoading(false);
      }
    };

      

    return {
        loading,
        listBoxes,
        list,
        data,
        setBoxToDestroy,
        boxToDestroy,
        handleDestroyBox,
        totalRegisters
        
    }
}