import { OrdersApiRemoteService } from '../../Services/api/orders/orders-service'
import { IOrdersListResponse } from '../../Services/api/orders/orders.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListOrdersUseCase
implements IFormUsecase<number, IOrdersListResponse> {
  constructor (private readonly ServiceOrdersApi: OrdersApiRemoteService) {}
    

  public async handle (
    page: number
  ): Promise<Result<IOrdersListResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ServiceOrdersApi.list(page)

      if (response.isFailure) {
        return response
      }

      const list = response.getValue()

      if(!list) return response

    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
