import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { FC, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MoreVertIcon from "@mui/icons-material/MoreVert"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Flex } from '../../Flex/flex.component';

interface IActionButtonsProps {
    params: any;
    setItemToDestroy: (id: string) => void
    editLink?: string;
    editFunction?: (data?: any) => void
}

const ActionButtons : FC<IActionButtonsProps> = ({ params, setItemToDestroy, editLink, editFunction }) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
    setAnchorEl(null);
    };

    return (
    <Flex justifyContent='flex-end' alignItems='center' height='100%' >
        <IconButton onClick={handleMenuOpen}>
        <MoreVertIcon />
        </IconButton>
        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        >
        <MenuItem
            onClick={() => {
            if (editFunction) {
                editFunction(params.row);
                return;
            }

            if(editLink) {
                navigate(editLink);
            }

            
            handleMenuClose();
            }}
        >
            <EditIcon fontSize="small" style={{ marginRight: 8 }} />
            Editar
        </MenuItem>
        <MenuItem
            onClick={() => {
            setItemToDestroy(params.row.id);
            handleMenuClose();
            }}
            sx={{ color: "error.main" }}
        >
            <DeleteIcon fontSize="small" style={{ marginRight: 8 }} />
            Excluir
        </MenuItem>
        </Menu>
    </Flex>
    );
}

export { ActionButtons }