import { useMemo, useState } from "react";
import { useToast } from "../../../Hooks/ToasContext";
import { IBoxEntity } from "../../../Services/api/boxes/boxes.interface";
import { DestroyBoxesUseCase } from "../../../Usecase/Boxes/destroy-boxes-usecase";
import { ListBoxesUseCase } from "../../../Usecase/Boxes/list-boxes-usecase";
import { ServicesList } from ".";
import { ListServiceInfoOrdersUseCase } from "../../../Usecase/ServicesInfo/list-service-info-usecase";
import { ListProductsServiceUseCase } from "../../../Usecase/ProductsService/list-products-service-usecase";
import { IProductServiceEntity } from "../../../Services/api/products-services/products-services-interface";


interface IUseServicesListProps {
  ListProductsServiceUseCase: ListProductsServiceUseCase
}
export function useServicesList({ ListProductsServiceUseCase }: IUseServicesListProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<IProductServiceEntity[]>([])
    const [boxToDestroy, setBoxToDestroy] = useState('')

    const { showToast } =  useToast()

    const listServices = async (currentPage: number) => {
        
        try {
          setLoading(true)
          
          const listResult = await ListProductsServiceUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()

         if(list){
            setData(list.data)
         }

         setPage(currentPage)
        } catch (error) {
          console.log("error listServices function: ", error)
        } finally {
          setLoading(false);
        }
      };

    const list = useMemo(() => {
        const rows = data?.map(product => {

            return {
                ...product
            }
        })

        return rows
    }, [data])

  
      

    return {
        loading,
        listServices,
        list,
        data,
        setBoxToDestroy,
        boxToDestroy,
        
    }
}