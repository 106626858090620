import React, { useEffect, useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { CreateBoxesUseCase } from "../../../Usecase/Boxes/create-boxes-usecase";
import { yupResolver } from "@hookform/resolvers/yup";
import { SubmitHandler, useForm } from "react-hook-form";
import * as Yup from 'yup'
import { useNavigate, useParams } from "react-router-dom";
import { IBoxEntity, ICreateBoxPayload, IUpdateBoxPayload } from "../../../Services/api/boxes/boxes.interface";
import { ShowBoxesUseCase } from "../../../Usecase/Boxes/show-boxes-usecase";
import { UpdateBoxesUseCase } from "../../../Usecase/Boxes/update-boxes-usecase";

interface IUseCreateBoxesProps {
    createBoxesUseCase: CreateBoxesUseCase
     showBoxesUseCase: ShowBoxesUseCase
     updateBoxesUseCase: UpdateBoxesUseCase
}

const CreateBoxSchemaValidation = Yup.object().shape({
    code: Yup.string().required('Código é obrigatório'),
    width: Yup.string().required('Largura é obrigatório'),
    length: Yup.string().required('Comprimento é obrigatório'),
    height: Yup.string().required('Altura é obrigatório'),
  });


interface IBoxProps {
	code: string
	width: number
	length: number
	height: number
}

export function useCreateBoxes({ createBoxesUseCase, showBoxesUseCase, updateBoxesUseCase }: IUseCreateBoxesProps){
    const [loading, setLoading] = useState(false)
    
    const [lengthValue, setLenghValue] = useState(0);
    const [widthValue, setWidthValue] = useState(0)
    const [heightValue, setHeightValue] = useState(0)
    const [data, setData] = useState<IBoxEntity>()

    const params = useParams()
    
    const navigate = useNavigate()
    const { handleSubmit, register, control, formState, setValue } = useForm<IBoxProps>({
        resolver: yupResolver(CreateBoxSchemaValidation),
      });

      const { errors } =  formState

    const { showToast } =  useToast()

    const listBoxes = async (boxId: string) => {
        
        try {
          setLoading(true)
          
          const showResult = await showBoxesUseCase.handle(boxId);

          if (showResult.isFailure) {
            showToast(String(showResult?.error?.error), 'error');
            return;
          }

         const box = showResult.getValue()

         if(box){
            setData(box)

            setValue('code', box.code)
            setValue('width', box.width)
            setValue('length', box.length)
            setValue('height', box.height)

            setWidthValue(box.width)
            setLenghValue(box.length)
            setHeightValue (box.height)
         }

        } catch (error) {
          console.log("error listBoxes function: ", error)
        } finally {
          setLoading(false);
        }
      };


    const handleAction: SubmitHandler<IBoxProps> = async (values) => {
        console.log("values: ", values)
        try {
          setLoading(true)

          const payloadCreate: ICreateBoxPayload  = {
            ...values
          }

          const payloadUpdate: IUpdateBoxPayload  = {
            ...values,
            id: String(params.id)
          }

          console.log("payloadUpdate: ", payloadUpdate)

        
          const storeResult = params.id ? await updateBoxesUseCase.handle(payloadUpdate) :  await createBoxesUseCase.handle(payloadCreate);

          if (storeResult.isFailure) {
            
            showToast(String(storeResult?.error?.error), 'error');
            return;
          }

          showToast(params.id ? 'Caixa Atualizada' : 'Caixa Cadastrads', 'success')
          navigate('/box-list');

        } catch (error) {
            console.log("error: ", error)
            showToast(error.response?.data?.error ?? 'Erro. Por favor, tente novamente', 'error');
          // toast({
          //   position: 'top-right',
          //   render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao logar. Por favor, tente novamente'} />,
          // });
        } finally {
          setLoading(false);
        }
      };

  
    useEffect(() => {
      if(params.id){
        listBoxes(params.id)
      }
    },[])

      

    return {
        loading,
        handleSubmit,
        handleAction,
        register,
        lengthValue,
        setLenghValue,
        widthValue,
        setWidthValue,
        heightValue,
        setHeightValue,
        errors,
        data,
        
        
    }
}