import { CubagesVariantsApiRemoteService } from '../../Services/api/cubages-variants/cubages-variants-service'
import { IUpdateCubageVariantPayload } from '../../Services/api/cubages-variants/cubages-variants.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class UpdateubagesVariationsUseCase
implements IFormUsecase<IUpdateCubageVariantPayload, void> {
  constructor (private readonly CubagesVariationsApi: CubagesVariantsApiRemoteService) {}
    

  public async handle (
    data: IUpdateCubageVariantPayload
  ): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.CubagesVariationsApi.update(data)

      if (response.isFailure) {
        return response
      }

    
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
