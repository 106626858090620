
export enum TSalePaymentStatus {
    APPROVED = 'APPROVED',
    WAITING = 'WAITING',
    REFUSED = 'REFUSED'
}


export enum TDeliveryPaymentStatus {
    SENT = 'SENT',
    WAITING = 'WAITING',
}

export interface ISaleProductProps {
    id: string;
    name: string;
    quantity: number;
    variant: string;
    sku: string;
    price_unit: string;
    total: string;
    image: string;
  }

export interface ISaleEntity {
    id: string;
    code?: string
    createdAt: string;
    customer: {
      name: string;
      email: string;
      phone: string
    }
    delivery_address: {
      address: string
    },
    total: string;
    payment_status: TSalePaymentStatus,
    delivery_status: TDeliveryPaymentStatus,
    products: ISaleProductProps[]
  }

  export interface ISalesListRespone {
      hasNextPage: boolean;
      total: number;
      data: any;
  }