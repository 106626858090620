/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import axios from 'axios'
import { Environment } from '../Config/constants'
import { StorageLocalstorage } from '../Shared/storage/storage-localstorage'
import { RefreshTokenUseCase } from '../Usecase/Auth/refresh-token-usecase'
import { AuthenticationApiRemoteService } from './api/auth/service-authentication'

// http://localhost:49120/api
// http://146.190.36.61/api <-- DEV
// https://www.papelloembalagens.com.br/api <-- PROD
const api = axios.create({
  baseURL: 'https://www.papelloembalagens.com.br/api',
  timeout: 0
})

export const adminVersion = '1.0'

let isRefreshing = false
let failedQueue: any[] = []

api.interceptors.request.use((config) => {
  const token: { token: string } = StorageLocalstorage.get(`${Environment.CUSTOMER}:token`)
  console.log("token: ", token)
  if (token) {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${token}`
    } else {
      config.headers = { Authorization: `Bearer ${token}` }
    }
  }
  return config
})

const processQueue = (error: any, token: string): void => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(api(prom.config))
    } else {
      prom.reject(error)
    }
  })

  failedQueue = []
}

// api.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const refreshTokenUseCase: RefreshTokenUseCase = new RefreshTokenUseCase(new AuthenticationApiRemoteService(api))

//     if (error.response.status === 401) {
//       switch (error.response.data.type) {
//         case 'RequestReLoginException':
//           StorageLocalstorage.clearAll()
//           window.location.href = '/signin'
//           break
//           // case 'TokenExpiredError':
//           //   // eslint-disable-next-line no-case-declarations
//           //   const oldtoken: { token: string } = StorageLocalstorage.get(`${CUSTOMER}:token`)
//           //   await refreshTokenUseCase.handle(oldtoken.token)
//           //   break

//         default:
//           break
//       }
//     }
//     return await Promise.reject(error)
//   }
// )

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true

      if (error.response.data.type === 'TokenExpiredError') {
        if (!isRefreshing) {
          isRefreshing = true

          try {
            const oldToken = StorageLocalstorage.get(`${Environment.CUSTOMER}:token`)
            const refreshTokenUseCase = new RefreshTokenUseCase(new AuthenticationApiRemoteService(api))
            const refreshTokenResponse = await refreshTokenUseCase.handle(oldToken.token)

            if (refreshTokenResponse.isFailure) {
              StorageLocalstorage.clearAll()
              window.location.href = '/signin'
              return await Promise.reject(refreshTokenResponse.error)
            }

            const newToken = refreshTokenResponse.getValue()?.token || ''

            processQueue(null, newToken)

            isRefreshing = false
            return await api(originalRequest)
          } catch (refreshError) {
            processQueue(refreshError, '')
            isRefreshing = false
            return await Promise.reject(refreshError)
          }
        } else {
          return await new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject })
          })
            .then(async (token) => {
              originalRequest.headers.Authorization = `Bearer ${String(token)}`
              return await api(originalRequest)
            })
            .catch(async (err) => {
              return await Promise.reject(err)
            })
        }
      } else if (error.response.data.type === 'RequestReLoginException') {
        StorageLocalstorage.clearAll()
        window.location.href = '/signin'
        return await Promise.reject(error)
      }
    }

    return await Promise.reject(error)
  }
)

api.defaults.headers.common = {
  'Content-Type': 'application/json'
}

export default api
