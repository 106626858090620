import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

interface BaseInputProps extends Omit<TextFieldProps, 'prefix' | 'onChange' | 'helperText'> { 
  prefix?: string;  // Prop para o prefixo
  callbackfunction?: (value: string) => void;  // Função de callback opcional
  onChange: (data: any) => void;
  // helperText?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  helperText?: any
}

const BaseInput: ForwardRefRenderFunction<HTMLInputElement, BaseInputProps> = (
  { sx = {}, prefix, callbackfunction, onChange, helperText, ...rest },
  ref
) => {

  // Função para tratar mudanças de input
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (callbackfunction) {
      callbackfunction(event.target.value);
    }
  };

  // Função para transformar helperText em uma string, caso seja necessário
  const getHelperText = () => {
    if (typeof helperText === 'string') return helperText;
    if (helperText && 'message' in helperText) return helperText.message;
    return '';
  };

  return (
    <TextField  
      ref={ref}
      sx={{
        width: '100%', 
        ...sx
      }}
      InputProps={{
        startAdornment: prefix ? (
          <InputAdornment position="start">
            {prefix}
          </InputAdornment>
        ) : undefined,
      }}
      InputLabelProps={{ shrink: true }}  // Força o comportamento do shrink
      helperText={getHelperText()}  // Transforma helperText em string
      onChange={handleChange}  
      {...rest}
    />
  );
};

export const Input = forwardRef(BaseInput);