import { format } from "date-fns"
import { useState, useMemo } from "react"
import IKanbanBoardProductionSchemaProps from "../../../Components/KanbanBoardProduction/interfaces/kanban-board-production-schema.interface"
import { Environment } from "../../../Config/constants"
import { IOrderEntity, IBillProductOrderPayload, IOrdersRegisteredOnOmieEntity } from "../../../Services/api/orders/orders.interface"
import { ProductionStatusEnum } from "../../../Services/api/service-orders/service-order.interface"
import { BillProductOrderOrdersUseCase } from "../../../Usecase/Orders/bill-products-order-usecase"
import { ListOrdersUseCase } from "../../../Usecase/Orders/list-orders-usecase"
import { Normalize } from "../../../utils/normalizeProperties"
import { useToast } from "../../../Hooks/ToasContext"
import { ShowOrdersUseCase } from "../../../Usecase/Orders/show-order-usecase"
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from "../../../Services/api/sales/sales.interface"
import { ptBR } from 'date-fns/locale';
import { FormatMoney } from "../../../utils/formatMoney"
import IKanbanBoardFinancialSchemaProps from "../../../Components/KanbanBoardFinancial/interfaces/kanban-board-financial-schema.interface"
import { ShowOmieOrderDetailsUseCase } from "../../../Usecase/Orders/show-omie-order-details-usecase"


interface IUseDetailsFinancialProps {
  listOrdersUseCase: ListOrdersUseCase
  billProductOrderOrdersUseCase: BillProductOrderOrdersUseCase
  showOrdersUseCase: ShowOrdersUseCase
  orderId: string;
  showOmieOrderDetailsUseCase: ShowOmieOrderDetailsUseCase
}

export interface Task {
  id: string;
  content: {
    os: string;
    items: any; // ajuste o tipo conforme sua necessidade
    type?: 'checkbox' | 'click'
  };
}

const tasks: Record<string, Task> = {};
export function useDetailsFinancial({ listOrdersUseCase, billProductOrderOrdersUseCase, showOrdersUseCase, orderId, showOmieOrderDetailsUseCase }: IUseDetailsFinancialProps){
    const [loading, setLoading] = useState(true)
    const [orderData, setOrderData] = useState<IOrderEntity>()
    const [kanbanFinancialSchema, setKanbanFinancialSchema] = useState<IKanbanBoardFinancialSchemaProps>()
    const [kanbanProductionSchema, setKanbanProductionSchema] = useState<IKanbanBoardProductionSchemaProps>()
    const [productsToBill, setProductsToBill] = useState<string[]>([])
    const [omieOrderData,setOmieOrderData] = useState<IOrdersRegisteredOnOmieEntity | undefined>(undefined)

    const { showToast } =  useToast()

    const transformProductionData = (dados: IOrderEntity) => {
      
      const tasks: Record<string, Task> = {};
      const initialOS : any = [];
      const onProductionOS : any = [];
      const finishedOS : any = [];
      
      dados.products.forEach((item, index) => {
          // const taskId = `task-${index + 1}`;
          const taskId = item.id;
          tasks[taskId] = {
              id: taskId,
              content: {
                  os: `Produto - ${item.product.name}`,
                  items: item.product
              }
          };

          if(item.status === ProductionStatusEnum.INICIO){
            initialOS.push(taskId);
          } else if (item.status === ProductionStatusEnum.PRODUCAO){
            onProductionOS.push(taskId)
          } else {
            finishedOS.push(taskId)
          }
          
      });

      const result = {
          tasks,
          columns: {
            [ProductionStatusEnum.INICIO]: {
                  id: ProductionStatusEnum.INICIO,
                  title: 'Aguardando Início',
                  taskIds: initialOS,
              },
              [ProductionStatusEnum.PRODUCAO]: {
                  id: ProductionStatusEnum.PRODUCAO,
                  title: 'Em Produção',
                  taskIds: onProductionOS,
              },
              [ProductionStatusEnum.FINALIZACAO]: {
                  id: ProductionStatusEnum.FINALIZACAO,
                  title: 'Concluído',
                  taskIds: finishedOS,
              },
          },
          columnOrder: [ProductionStatusEnum.INICIO, ProductionStatusEnum.PRODUCAO, ProductionStatusEnum.FINALIZACAO],
      };

      return result;
    }

    const transformFinancialData = (dados: IOrderEntity) => {
      
      const tasks: Record<string, Task> = {};
      const readyToBilled : any = [];
      const billedOrders : any = [];
      
      dados?.products?.forEach((item, index) => {
          // const taskId = `task-${index + 1}`;
          const taskId = item.id;
          tasks[taskId] = {
              id: taskId,
              content: {
                  os: `Produto ${item.product.name}`,
                  items: item.product,
                  type: 'checkbox'
              }
          };

          if(item.status === ProductionStatusEnum.FINALIZACAO){
            readyToBilled.push(taskId);
          } 
          
      });

      dados?.ordersRegisteredOnOmie?.forEach((item, index) => {
        // const taskId = `task-${index + 1}`;
        const taskId = item.id
        tasks[taskId] = {
            id: taskId,
            content: {
                os: `Pedido OMIE: ${item.id}`,
                items: item.serviceOrders,
                type: 'click'
            }
        };

        billedOrders.push(taskId);
        
    });



      const result = {
          tasks,
          columns: {
            [ProductionStatusEnum.FINALIZACAO]: {
                  id: ProductionStatusEnum.FINALIZACAO,
                  title: 'Pronto para Faturar',
                  taskIds: readyToBilled,
              },
              [ProductionStatusEnum.BILLED]: {
                  id: ProductionStatusEnum.BILLED,
                  title: 'Faturado',
                  taskIds: billedOrders,
              },
             
          },
          columnOrder: [ProductionStatusEnum.FINALIZACAO, ProductionStatusEnum.BILLED],
      };

      return result;
    }

    const handleShowOrder = async (orderId: string) => {
       
      try {
        setLoading(true)
        
        const orderResult = await showOrdersUseCase.handle(orderId);
  
        if (orderResult.isFailure) {
    
          showToast(String(orderResult?.error?.error), 'error');
          return;
        }
  
        const result = orderResult.getValue();
  
        if(result?.id){
  
          const normalizeSchema : IKanbanBoardFinancialSchemaProps = transformFinancialData(result)
          const normalizeProductionSchema : IKanbanBoardProductionSchemaProps = transformProductionData(result)
          
          setKanbanFinancialSchema(normalizeSchema)
          setKanbanProductionSchema(normalizeProductionSchema)
  
          setOrderData(result)
        }
  
      } catch (error) {
        console.log("error handleShowOrder function: ", error)
      } finally {
        setLoading(false);
      }
      };

    const handleDetailsOmieOrder = async (orderId?: string) => {
      
      try {
        setLoading(true)
        
        const orderResult = await showOmieOrderDetailsUseCase.handle(String(orderId));
  
        if (orderResult.isFailure) {
    
          showToast(String(orderResult?.error?.error), 'error');
          return;
        }
  
        const result = orderResult.getValue();
  
        if(result?.id){
          setOmieOrderData(result)
        }
  
      } catch (error) {
        console.log("error handleShowOrder function: ", error)
      } finally {
        setLoading(false);
      }
    };
   

    const billProductsOrder = async () => {

      if(!productsToBill?.length){
        showToast(String('Selecione pelo menos 1 produto para faturar'), 'error');
        return;
      }
         
      try {
        setLoading(true)

        const payload : IBillProductOrderPayload = {
          orderId,
          productsIds: productsToBill
        }
        
        const billResult = await billProductOrderOrdersUseCase.handle(payload);

        if (billResult.isFailure) {
     
          showToast(String(billResult?.error?.error), 'error');
          return;
        }

        showToast('Produtos faturados com sucesso', 'success');
        handleShowOrder(orderId)
      } catch (error) {
        console.log("error billProductsOrder function: ", error)
      } finally {
        setLoading(false);
      }
    };

   

  const sale = useMemo(() => {
    if(!orderData?.id) return {} as ISaleEntity
    const result : ISaleEntity = {
      id: orderData.id,
      createdAt: format(new Date(orderData.createdAt), "dd MMM yyyy", {
        locale: ptBR, 
      }),
      customer: {
        name: orderData?.customer?.name || 'não informado',
        email: orderData?.customer?.email || 'não informado',
        phone: orderData?.customer?.phone || 'não informado',
      },
      total: FormatMoney(Number(orderData.totalValue)),
      delivery_address: {
        address: orderData?.customer?.addresses?.length ? `${orderData?.customer?.addresses[0].addressName} ${orderData?.customer?.addresses[0].addressNumber}, ${orderData?.customer?.addresses[0].addressLocality} - ${orderData?.customer?.addresses[0].addressCity} - ${orderData?.customer?.addresses[0].addressProvince} | ${orderData?.customer?.addresses[0].addressZipcode}` : 'Não informado.'
      },
      payment_status: TSalePaymentStatus.APPROVED,
      delivery_status: TDeliveryPaymentStatus.WAITING,
      products: orderData?.products?.map(i => {
        return  {
          id: i.hubProductId,
          name: i.product.name,
          quantity: i.quantity,
          variant: 'Não informado',
          sku: 'Não informado',
          price_unit: FormatMoney(Number(i.finalPrice)),
          total:  FormatMoney( i.quantity * Number(i.finalPrice)),
          image: i.product.images[0]?.link || Environment.IMAGE_PLACEHOLDER
        }
      })
    }

    return result
  }, [orderData])

  const handleSelectProductToBill = (productId?: string) => {
      if(!productId) return
      const exists = productsToBill.find(i => i === productId)

      if(exists) {
        const normalize = productsToBill.filter(i => i !== productId)
        setProductsToBill(normalize)
      } else {
        setProductsToBill(prev => [...prev, productId])
      }
  }

    return {
        loading,
        billProductsOrder,
        handleShowOrder,
        orderData,
        kanbanFinancialSchema,
        kanbanProductionSchema,
        sale,
        handleSelectProductToBill,
        productsToBill,
        handleDetailsOmieOrder,
        omieOrderData,
        setOmieOrderData
    }
}