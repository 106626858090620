import React, { FC, useState } from 'react';
import { TextField, Chip, Box, MenuItem, Paper, TextFieldProps } from '@mui/material';

interface ITagInputProps extends Omit<TextFieldProps, 'prefix' | 'onChange' | 'helperText'> { 
  inputCreatePrefix?: string;
  label?: string; // Adiciona a propriedade label na interface
}

const TagInput: FC<ITagInputProps> = ({ inputCreatePrefix, label, placeholder, ...rest }) => {
  const [inputValue, setInputValue] = useState('');
  const [tags, setTags] = useState<string[]>([]);

  const addTag = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputValue('');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Previne o envio do formulário
      addTag();
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, mb: 1 }}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => setTags(tags.filter((t) => t !== tag))}
          />
        ))}
      </Box>

      <TextField
        fullWidth
        variant="outlined"
        value={inputValue}
        label={label} // Exibe o label, se fornecido
        placeholder={placeholder || "Digite para adicionar uma tag"}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{ marginTop: '10px' }}
        {...rest} // Spread para qualquer outra propriedade de TextFieldProps
      />

      {inputValue && (
        <Paper elevation={2} sx={{ mt: 1 }}>
          <MenuItem onClick={addTag}>
            <span>
              {inputCreatePrefix || "Criar nova tag"}: <strong>{inputValue}</strong>
            </span>
          </MenuItem>
        </Paper>
      )}
    </Box>
  );
};

export { TagInput };
