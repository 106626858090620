import { IProductEntity } from "../products/products.interface"

export type TServiceOrderStatus = "PROCESSING" | "COMPLETED" | "DEAD"

export type TProductionStatus = "INICIO" | "PRODUCAO" | 'FINALIZACAO' | 'BILLED'

export enum ProductionStatusEnum  {
    "INICIO" = "INICIO",
    "PRODUCAO" = "PRODUCAO",
    "FINALIZACAO" = "FINALIZACAO",
    "BILLED" = "BILLED"
}

export type TStoreType = "EMPO" | "PAPELLO"


export interface IServiceOrderEntity {
    id: string
    createdAt: string
    externalProductionId: string
    isActive: boolean
    cartela?: string
    startDate: string
    dueDate?: string
    priority: "SEM_PRIORIDADE"
    stage: TProductionStatus
    price: string
    productType?: string
    products: IServiceOrderProduct[]
}

export interface IServiceOrdersListResponse{
    hasNextPage: boolean;
    data: IServiceOrderEntity[];
    total: number;   
}

export interface IUpdateServiceOrdersPayload {
    productsIds: string[]
    status: TProductionStatus
    osId: string
}

export interface IServiceOrdersUpdateResponse{
    successProducts: IServiceOrderProduct[];
    errorProducts: IServiceOrderProduct[];
}

export interface IServiceOrderProduct {
    id: string
    createdAt: string
    hubProductId: string
    status: TProductionStatus
    hubVariantsIds?: string[]
    nsOrderId: string
    originalPrice: string
    finalPrice: string
    nsOrderProductId: string
    artLink?: string
    serviceOrderId: string
    orderRegisteredOnOmieId?: string
    product: IProductEntity
    variants?: string[]
    serviceOrder?: IServiceOrderEntity
    quantity: number
}