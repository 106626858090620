import { Box, CircularProgress, useTheme } from '@mui/material'
import React, { FC } from 'react'

const Loader: FC = () => {
  const theme = useTheme()
  return (
    <Box
    sx={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      zIndex: 999999999
      
    }}
    
    >
      <CircularProgress />
   
    </Box>
  )
}

export { Loader }
