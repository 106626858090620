function replaceSpecialCharacterByUnderline(word: string): string {
  return word.replace(/[^\w\s]/g, "_").replace(/\s/g, "_");
}

export const Normalize = {
  name: (name: string): string => {
    return replaceSpecialCharacterByUnderline(name.toLowerCase());
  },

  valueToStringWithPoint(value: number | string): string {
    const transformed = value.toString().replace(/[^0-9]/g, "");
    let newValue = transformed;

    if (transformed.length > 2) {
      const commaPosition = transformed.length - 2;

      newValue = `${transformed.substring(
        0,
        commaPosition,
      )}.${transformed.substring(commaPosition)}`;
    } else {
      newValue =
        Number(transformed) <= 9 ? `00.${transformed}` : `0.${transformed}`;
    }

    return newValue;
  },

  valueToStringWithoutPoint(value: number | string): string {
    const transformed = value.toString().replace(/[^0-9]/g, "");
    let newValue = transformed;

    if (transformed.length > 2) {
      const commaPosition = transformed.length - 2;

      newValue = `${transformed.substring(
        0,
        commaPosition,
      )}${transformed.substring(commaPosition)}`;
    } else {
      newValue =
        Number(transformed) <= 9 ? `00${transformed}` : `0${transformed}`;
    }

    return newValue;
  },

  value(
    value: string,
    opts: {
      withPoint?: boolean;
    } = {
      withPoint: false,
    },
  ): number {
    const newValue = opts.withPoint
      ? this.valueToStringWithPoint(value)
      : this.valueToStringWithoutPoint(value);

    // console.log("[normalizeValue] value", value);
    // console.log("[normalizeValue] newValue", newValue);

    return opts.withPoint ? parseFloat(newValue) : Number(newValue);
  },
};
