import React, { createContext, useContext, useState, FC, ReactNode } from 'react';
import { Snackbar, Alert } from '@mui/material';

interface IToastContext {
  showToast: (message: string, severity?: 'error' | 'success' | 'info' | 'warning') => void;
}

const ToastContext = createContext<IToastContext | undefined>(undefined);

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};

export const ToastProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<'error' | 'success' | 'info' | 'warning'>('info');

  const showToast = (msg: string, severityType: 'error' | 'success' | 'info' | 'warning' = 'info') => {
    setMessage(msg);
    setSeverity(severityType);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ zIndex: 999999999999}}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};
