import { createTheme } from '@mui/material/styles'

const lightTheme = createTheme({
  
  palette: {
    mode: 'light',
    primary: {
      main: '#84a802', // Cor primária no modo claro,
      dark: '#597007'
    },
    secondary: {
      main: '#9c27b0' // Cor secundária no modo claro
    },
    background: {
      default: '#f7f9fc', // Cor de fundo no modo claro
      paper: '#f5f5f5' // Cor de fundo dos papéis no modo claro
    },
    text: {
      primary: '#000000', // Cor do texto principal no modo claro,
      secondary: '#364152'
    },
   
  }
})

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#90caf9' // Cor primária no modo escuro
    },
    secondary: {
      main: '#ce93d8' // Cor secundária no modo escuro
    },
    background: {
      default: '#121212', // Cor de fundo no modo escuro
      paper: '#1d1d1d' // Cor de fundo dos papéis no modo escuro
    },
    text: {
      primary: '#ffffff', // Cor do texto principal no modo escuro
      secondary: '#364152'
    }
  }
})

export { lightTheme, darkTheme }
