
import { Box, Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { Control, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';
import { SelectComponent } from '../../../Form/Select/select.component';
import { ICubageEntity } from '../../../../Services/api/cubages/cubages.interface';
import { Flex } from '../../../Flex/flex.component';
import { AiOutlineDropbox } from 'react-icons/ai';
import { CubageAccordion } from '../../../Accordions/cubage-accordion/cubage-accordion.component';
import { IProductProps } from '../../../../Pages/Products/Form/create-products-hook';
import { ICubageVariantAssociated, ICubageVariantEntity } from '../../../../Services/api/cubages-variants/cubages-variants.interface';


interface ProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    description: string
  }
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  
interface IDrawerComponent extends DrawerProps {
    onToggle: (state: boolean) => void;
    title: string;
    subTitle?: string;
    search?: boolean;
    searchFunction?: () => void;
    titleStyle?: object
    subTitleStyle?: object
    open: boolean;
    handleSelect: (category: string) => void;
    cubages: ICubageEntity[]
    control: Control<IProductProps, any>
    handleAddTemporaryCubage: (cubageVariant: ICubageVariantAssociated) => void
    handleRemoveTemporary: (cubageVariant: ICubageVariantAssociated) => void
    
}

const ProductsCubageSelectDrawer : FC<IDrawerComponent> = ({ onToggle, title, subTitle, open, titleStyle = {}, subTitleStyle = {}, handleSelect, cubages, control, handleAddTemporaryCubage, handleRemoveTemporary  }) => {
    const theme = useTheme();
    const [categorySelected, setCategorySelected] = useState('')


    return (
        <Drawer open={open} onClose={onToggle} anchor='right' style={{ zIndex: 99999999 }}>
            <Grid2 sx={{ minWidth: '540px', padding: '30px 30px'}} >
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >{title}</Typography>
                {subTitle && <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: theme.palette.text.secondary,  ...subTitleStyle}} >{subTitle}</Typography> }

                
                <Grid2 size={12}>

                <Divider sx={{ marginTop: '20px', marginBottom:'10px'}} />

                <CubageAccordion data={cubages} control={control} handleAddTemporaryCubage={handleAddTemporaryCubage} handleRemoveTemporary={handleRemoveTemporary} />
                
                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Button onClick={() => onToggle(false)} type='button'>Salvar Alterações</Button>
                </Grid2>
                </Grid2>
            </Grid2>
        </Drawer>
)
}

export { ProductsCubageSelectDrawer }