import { CubagesApiRemoteService } from '../../Services/api/cubages/cubages-service'
import { ICubageEntity } from '../../Services/api/cubages/cubages.interface'
import { OrdersApiRemoteService } from '../../Services/api/orders/orders-service'
import { IOrdersListResponse } from '../../Services/api/orders/orders.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListCubagesUseCase
implements IFormUsecase<number, ICubageEntity[]> {
  constructor (private readonly CubageOrdersApi: CubagesApiRemoteService) {}
    

  public async handle (
    page: number
  ): Promise<Result<ICubageEntity[]>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.CubageOrdersApi.list(page)

      if (response.isFailure) {
        return response
      }

      const list = response.getValue()

      if(!list) return response
    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
