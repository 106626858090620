import { OrdersApiRemoteService } from '../../Services/api/orders/orders-service'
import { IOrderEntity } from '../../Services/api/orders/orders.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ShowOrdersUseCase
implements IFormUsecase<string, IOrderEntity> {
  constructor (private readonly ServiceOrdersApi: OrdersApiRemoteService) {}
    

  public async handle (
    orderId: string
  ): Promise<Result<IOrderEntity>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ServiceOrdersApi.show(orderId)

      if (response.isFailure) {
        return response
      }

      const content = response.getValue()

      if(!content) return response

    
      return Result.ok(content)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
