import { Box, BoxProps } from '@mui/material'
import React, { FC, ReactNode } from 'react'

interface IFlexProps extends BoxProps {
  children: ReactNode
}
const Flex: FC<IFlexProps> = ({ children, ...rest }) => {
  return <Box display='flex' {...rest}>{children}</Box>
}

export { Flex }
