import React, { useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from "../../../Services/api/sales/sales.interface";
import { Environment } from "../../../Config/constants";
import { ListOrdersUseCase } from "../../../Usecase/Orders/list-orders-usecase";
import { IOrderEntity } from "../../../Services/api/orders/orders.interface";
import { format } from "date-fns";


interface IUseListOrdersProps {
    listProductUseCase: ListProductUseCase
     listOrdersUseCase: ListOrdersUseCase
}
export function useListOrders({ listProductUseCase, listOrdersUseCase }: IUseListOrdersProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<IOrderEntity[]>([])
    const [showDetails, setShowDetails] = useState<undefined | string>(undefined)

    const { showToast } =  useToast()

    const listOrders = async ({ page: currentPage }: any) => {
        
        try {
          setLoading(true)
          
          const listResult = await listOrdersUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()
        
        //  const list : ISaleEntity[] = [
        //   {
        //     id: '1',
        //     code: '100',
        //     createdAt: '29 out',
        //     customer: {
        //       name: 'Diego Hennrich',
        //       email: 'diegohennrich@gmail.com',
        //       phone: '51981872327',
        //     },
        //     total: 'R$ 10.735,00',
        //     delivery_address: {
        //       address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
        //     },
        //     payment_status: TSalePaymentStatus.APPROVED,
        //     delivery_status: TDeliveryPaymentStatus.WAITING,
        //     products: [
        //       {
        //         id: '10',
        //         name: 'Copo e Papel Kraft Personalizado 100ml',
        //         quantity: 1,
        //         variant: '5000',
        //         sku: '0010076',
        //         price_unit: 'R$ 2.034,28',
        //         total: 'R$ 2.034,28',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
        //       },
        //       {
        //         id: '11',
        //         name: 'Copo e Papel Personalizado 500ml',
        //         quantity: 1,
        //         variant: '10000',
        //         sku: '1000010014',
        //         price_unit: 'R$ 8.702,10',
        //         total: 'R$ 8.702,10',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
        //       }
        //     ]
        //   }
        // ]

         if(list?.data){
            setData(list.data)
         }

         setPage(currentPage)
        } catch (error) {
          console.log("error listSales function: ", error)
        } finally {
          setLoading(false);
        }
      };

    
    const list = useMemo(() => {
        const rows = data?.map(sale => {

            return {
                ...sale,
                createdAt: format(new Date(sale.createdAt), 'dd/MM/yyyy'),
                products: sale.products.map(product => {
                  return {
                    ...product,
                      // image: product?.image || Environment.IMAGE_PLACEHOLDER
                  }
                })
              
            }
        })

        return rows
    }, [data])

    const handleShowDetails = (code: string) => {

      setShowDetails(code)
    } 

    

      

    return {
        loading,
        listOrders,
        list,
        data,
        handleShowDetails,
        showDetails,
        setShowDetails
        
    }
}