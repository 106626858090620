import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { useState } from 'react'


import Router from './Routes'
import { darkTheme, lightTheme } from './Styles/theme'
import { ToastProvider } from './Hooks/ToasContext'
import { UserProvider } from './Hooks/ContextUser'

function App () {
  const [darkMode, setDarkMode] = useState(false)

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      <UserProvider>
        <ToastProvider>
          <Router />
        </ToastProvider>
        </UserProvider>
    </ThemeProvider>
  )
}


export default App