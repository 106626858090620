import React from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldErrorsImpl,
  Merge,
} from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import dayjs from 'dayjs';

interface DatePickerProps {
  name: string;
  control: Control<any>;
  label?: string;
  // helperText?: string | FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
  helperText?: any;
  disabled?: boolean;
  minDate?: string;
  maxDate?: string;
}

const DatePicker: React.FC<DatePickerProps> = ({
  name,
  control,
  label,
  helperText,
  disabled,
  minDate,
  maxDate,
}) => {
  const getHelperText = () => {
    if (typeof helperText === 'string') return helperText;
    if (helperText && 'message' in helperText) return helperText.message;
    return '';
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <MuiDatePicker
            {...field}
            label={label}
            disabled={disabled}
            minDate={minDate ? dayjs(minDate) : undefined}
            maxDate={maxDate ? dayjs(maxDate) : undefined}
            value={field.value ? dayjs(field.value) : null}
            onChange={(date) => {
              field.onChange(date ? date.toISOString() : null);
            }}
            slotProps={{
              textField: {
                fullWidth: true,
                helperText: getHelperText(),
                error: !!helperText,
              },
              popper: {
                sx: {
                  zIndex: 999999999,
                },
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export { DatePicker };
