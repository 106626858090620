
import React, { FC } from 'react'
import { Box, IconButton, Paper, Typography, useTheme } from '@mui/material' // Importar useTheme do MUI correto
import { position } from '@chakra-ui/react'
import { Sidebar } from '../../Sidebar/sidebar.component'
import { Flex } from '../../Flex/flex.component'
import Brand from '../../../Assets/brand.webp'
import { IoIosLogOut } from "react-icons/io";
import { Button } from '../../Form/Button/button.scomponent'
import { UserFullContext } from '../../../Hooks/ContextUser'
import { useNavigate } from 'react-router-dom'
import { adminVersion } from '../../../Services/api'


interface IBaseScreenProps {
    children: React.ReactNode
    withSideBar?: boolean
}

const BaseScreen: FC<IBaseScreenProps> = ({ children, withSideBar = true }) => {
    const theme = useTheme(); // Obtendo o tema do MUI
    const { Logout } = UserFullContext()
    const navigate = useNavigate();

    const handleLogout = async() => {
         Logout()
        navigate('/')
    }

    return (
        <Box sx={{
            width: '100vw',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            backgroundColor: theme.palette.primary.light
           
        }}
        >   

            <Flex sx={{ width: '100%', position: 'fixed', zIndex: 9999}}>
                <Paper sx={{ width: '100%', height: '64px', borderRadius: 0, justifyContent: 'space-between'}}>
                    <Flex sx={{ width: '100%', justifyContent: 'space-between'}}>
                        <img src={Brand} width='200px' height='auto' /> 

                        <Flex sx={{ alignItems: 'center', marginRight: '15px'}}>
                                <Typography fontSize='15px' fontWeight={500}>Versão {adminVersion}</Typography>
                                <IconButton onClick={handleLogout}><IoIosLogOut color={theme.palette.primary.dark} /></IconButton>
                        </Flex>
                       
                    </Flex>
                   
                </Paper>
            </Flex>     
            <Flex sx={{ marginTop: '64px'}}>
                {withSideBar && <Sidebar />}
                
                <Box sx={{ width: '100%', padding: '35px', paddingRight: '25px'}}>{children}</Box>  
            </Flex>
        </Box>
    )
}

export { BaseScreen }
