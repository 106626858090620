import { IProductsServiceListResponse } from '../../Services/api/products-services/products-services-interface'
import { ProductsServicesServiceApiRemoteService } from '../../Services/api/products-services/products-services-service'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListProductsServiceUseCase
implements IFormUsecase<number, IProductsServiceListResponse> {
  constructor (private readonly ProductsServiceApi: ProductsServicesServiceApiRemoteService) {}
    

  public async handle (
   page:number
  ): Promise<Result<IProductsServiceListResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ProductsServiceApi.list(page)

      if (response.isFailure) {
        return response
      }


      const list = response.getValue()

      if(!list) return response

    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
