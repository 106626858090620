import { Chip, Grid2, Paper, Typography, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import DragAndDrop from '../../../Components/Form/DragAndDrop/drag-and-drop.component';
import { Input } from '../../../Components/Form/Input/input.component';
import { InputCurrency } from '../../../Components/Form/InputCurrency/input-currency.component';
import InputMaskComponent from '../../../Components/Form/InputMask/input-mask.component';
import RichTextEditor from '../../../Components/Form/RichTextEditor/rich-text-editor.component';
import { Switch } from '../../../Components/Form/Switch/switch.component';
import { Loader } from '../../../Components/Loader';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { CreateProductUseCase } from '../../../Usecase/Products/create-product-usecase';

import { FaQrcode } from 'react-icons/fa';
import { InternalCodeDrawer } from '../../../Components/Dialog/variants/internal-code/internal-code-dialog.component';
import { CatetoryListDrawer } from '../../../Components/Drawer/variants/category-list/category-list-drawer.component';
import { ProductsCubageSelectDrawer } from '../../../Components/Drawer/variants/products-cubage-select/products-cubage-select-drawer.component';
import { SeoFormDrawer } from '../../../Components/Drawer/variants/seo-form/seo-form-drawer.component';
import { VariantsListDrawer } from '../../../Components/Drawer/variants/variants-list/variants-list-drawer.component';
import { Radio } from '../../../Components/Form/Radio/radio-input.component';
import { SelectComponent } from '../../../Components/Form/Select/select.component';
import { ListCubagesUseCase } from '../../../Usecase/Cubages/list-cubages-usecase';
import { ListProductsServiceUseCase } from '../../../Usecase/ProductsService/list-products-service-usecase';
import { billingOptions, useCreateProducts } from './create-products-hook';
import { ListServiceInfoOrdersUseCase } from '../../../Usecase/ServicesInfo/list-service-info-usecase';
import { ShowProductUseCase } from '../../../Usecase/Products/show-product-usecase';
import { UpdateProductUseCase } from '../../../Usecase/Products/update-product-usecase';
import { Tag } from '../../../Components/Tag/tag.component';



  interface IProductsFormProps {
    createProductUseCase: CreateProductUseCase
    listCubagesUseCase: ListCubagesUseCase
    listProductsServiceUseCase: ListProductsServiceUseCase
    listServiceInfoOrdersUseCase: ListServiceInfoOrdersUseCase
    showProductUseCase: ShowProductUseCase
    updateProductUseCase: UpdateProductUseCase
  }
const ProductsForm : FC<IProductsFormProps> = ({ createProductUseCase, listCubagesUseCase, listProductsServiceUseCase,listServiceInfoOrdersUseCase, showProductUseCase, updateProductUseCase }) => {
   
    const theme = useTheme()
    const navigate = useNavigate()
   

    const { control, errors, handleAction, handleSubmit, loading, register,  handleSelectCategories, showCategoriesModal, showSEOModal, images, internalCode, listServicesInfo, setImages, setInternalCode, setShowCategoriesModal, setShowCubageModal, setShowInternalCode, setShowSEOModal, setShowVariationsModal, setVariations, showCubageModal, showInternalCode, showVariationsModal, cubagesList, variations, handleListCubages, categories, setCategories, dragAndDropRef, handleListProductsServices, billingTypeValue, productsServicesOptions, cubagesSelected, handleRemoveTemporary, handleAddTemporary } = useCreateProducts({
      createProductUseCase,
      listCubagesUseCase,
      listProductsServiceUseCase,
      listServiceInfoOrdersUseCase,
      showProductUseCase,
      updateProductUseCase
    })

    useEffect(() => {
      handleListCubages()
      handleListProductsServices()
    }, [])


    return (
        <BaseScreen>
            <CatetoryListDrawer handleSelect={e => handleSelectCategories(e)} open={showCategoriesModal} title='Categorias' onToggle={() => setShowCategoriesModal(prev => !prev)} subTitle='Cadastre uma nova categoria' />
            <SeoFormDrawer handleSelect={e => handleSelectCategories(e)} open={showSEOModal} title='Tags, Marca e SEO' onToggle={() => setShowSEOModal(prev => !prev)} subTitle='Cadastre os conteúdos de SEO para otimizar suas buscas.' containerStyle={{ width: '540px' }} />
            
            <ProductsCubageSelectDrawer  handleSelect={e => {}} open={showCubageModal} title='Cubagem' onToggle={() => setShowCubageModal(prev => !prev)} subTitle='Selecione a cubagem deste produto' cubages={cubagesList} control={control} handleAddTemporaryCubage={handleAddTemporary} handleRemoveTemporary={handleRemoveTemporary} />

            <InternalCodeDrawer  handleSelect={e => setInternalCode(e)} open={showInternalCode}  onToggle={() => setShowInternalCode(prev => !prev)} title='Código Interno' code={internalCode}  />
            <VariantsListDrawer handleSelect={e => handleSelectCategories(e)} open={showVariationsModal} title='Variações' onToggle={() => setShowVariationsModal(prev => !prev)} subTitle='Cadastre uma nova variação' />

   
            <Paper>
                <BaseContentPage 
                    title='Produtos'
                    breadcrumb={[{ title: 'Produtos', link: "/products-list"}, { title: 'Novo Produto'}]}
                >

                  {loading && <Loader />}

                  <Grid2 container spacing={5} sx={{ marginTop: 2}} component="form" onSubmit={handleSubmit(handleAction)}>

                <Typography sx={{fontSize: '19px', fontWeight: '600'}}>NuvemShop - Configurações</Typography>
                <Grid2 size={12} container sx={{
                    width: '100%',
                    padding: '20px',
                    border: '2px dashed #ccc',
                    borderRadius: '8px',
                    marginTop: -4
                  }}>
                    

                  <Grid2 size={3}>
                    
                    <Switch label="Produto Personalizável?" name="personalized" control={control} />
                  </Grid2>

                  <Grid2 size={3}>
                      <Switch
                          label="Cadastrar na EMPO - B2B"
                          name="createOnb2b"
                          control={control}

                      />
                  </Grid2>

                  <Grid2 size={3}>
                      <Switch
                          label="Cadastrar na Papello - B2C"
                          name="createOnb2c"
                          control={control}
                        
                      />
                  </Grid2>
                  

                
                </Grid2>

                <Typography sx={{fontSize: '19px', fontWeight: '600'}}>ERP (Omie) - Configurações</Typography>

                <Grid2 size={12} container sx={{
                    width: '100%',
                    padding: '20px',
                    border: '2px dashed #ccc',
                    borderRadius: '8px',
                    marginTop: -4
                  }}>

                  <Grid2 size={3}>
                      <Switch
                          label="Cadastrar na Empo"
                          name="createOnOmie"
                          control={control}

                      />
                  </Grid2>

                  <Grid2 size={3}>
                      <Switch
                          label="Cadastrar na Papello"
                          name="createOnNuvemShop"
                          control={control}
                        
                      />
                  </Grid2>

                      
                </Grid2>

                <Grid2 size={billingTypeValue !== 'service' ? 12 : 3}  sx={{ minWidth: '150px', flex: '0 0 auto' }}>
                        <Radio
                            label="Como será faturado"
                            labelStyle={{ fontWeight: 'bold', fontSize: '18px', marginBottom: '20px'}}
                            name="billingType"
                            control={control}
                            options={billingOptions}
                            error={!!errors.billingType}
                            helperText={errors?.billingType?.message}
                        />   
                </Grid2>

                {billingTypeValue === 'service' && (
                <Grid2 size={8}  sx={{ minWidth: '150px', flex: '0 0 auto' }}>
                    <Typography sx={{marginBottom: '10px', fontSize: '19px', fontWeight: '600'}}>Selecione o Serviço:</Typography>
                      <SelectComponent name='service' label='Serviço' options={productsServicesOptions} control={control} error={!!errors?.service} helperText={errors?.service?.message || ''} placeholder='Selecionar serviço'  />
                   
                </Grid2>
                )}

                <Typography sx={{fontSize: '19px', fontWeight: '600'}}>Categorias</Typography>
                <Grid2 size={12} container sx={{
                    width: '100%',
                    padding: '20px',
                    border: '2px dashed #ccc',
                    borderRadius: '8px',
                    marginTop: -4
                  }}>
                  

                <Grid2 size={12} container spacing={2}>
                    {!categories?.length && <Typography>Você ainda não possui nenhuma categoria cadastrada.</Typography>}
                
                    {categories?.map(item => {
                      return   <Chip 
                              key={item} 
                              label={item} 
                              onDelete={() => setCategories(categories.filter((t) => t !== item))} 
                              sx={{ fontSize: '14px'}}
                            />
                                  
                      // <Tag title={item} onClose={() => {}} />
                    })}
                  
                </Grid2>

                <Grid2 size={12}>
                <Button onClick={() => setShowCategoriesModal(true)} sx={{ width: '300px' }} isLoading={loading}>
                    Adicionar Categorias
                </Button>
                    </Grid2>

                  
                    

                  
                  </Grid2>

                  <Typography sx={{fontSize: '19px', fontWeight: '600'}}>Tags, Marca e SEO</Typography>
                  <Grid2 size={12} container sx={{
                      width: '100%',
                      padding: '20px',
                      border: '2px dashed #ccc',
                      borderRadius: '8px',
                      marginTop: -4
                    }}>
                      

                    <Grid2 size={12} container spacing={2}>
                        {!categories?.length && <Typography>Você ainda não possui nenhum conteúdo cadastrado.</Typography>}
                    
                      
                    </Grid2>

                    <Grid2 size={12}>
                    <Button onClick={() => setShowSEOModal(true)} sx={{ width: '300px' }} isLoading={loading}>
                        Adicionar Conteúdo
                    </Button>
                    </Grid2>

                  </Grid2>

                  <Typography sx={{fontSize: '19px', fontWeight: '600'}}>Cubagem</Typography>
                  <Grid2 size={12} container sx={{
                      width: '100%',
                      padding: '20px',
                      border: '2px dashed #ccc',
                      borderRadius: '8px',
                      marginTop: -4
                    }}>
                      

                    <Grid2 size={12} container spacing={2}>
                      {!!cubagesSelected?.length ?  cubagesSelected?.map(i => <Chip 
                              key={i.id} 
                              label={`${i?.cubageVariant?.description} - ${i?.cubageVariant?.howManyInBox} Unidades`} 
                              onDelete={() => handleRemoveTemporary(i)} 
                              sx={{ fontSize: '14px'}}
                            />) : <Typography>Você ainda não possui nenhuma cubagem cadastrado.</Typography>}
                    
                    </Grid2>

                    <Grid2 size={12}>
                    <Button onClick={() => setShowCubageModal(true)} sx={{ width: '300px' }} isLoading={loading}>
                        Selecionar cubagem
                    </Button>
                    </Grid2>

                  </Grid2>

                  <Typography sx={{fontSize: '19px', fontWeight: '600'}}>Variações</Typography>
                  <Grid2 size={12} container sx={{
                      width: '100%',
                      padding: '20px',
                      border: '2px dashed #ccc',
                      borderRadius: '8px',
                      marginTop: -4
                    }}>
                      <Typography>As variações devem ser cadastradas/editadas diretamente na Nuvem Shop</Typography>
                      

                    {/* <Grid2 size={12} container spacing={2}>
                        {!variations?.length && <Typography>Você ainda não possui nenhuma variação cadastrada.</Typography>}
                    
                      
                    </Grid2>

                    <Grid2 size={12}>
                      <Button onClick={() => setShowVariationsModal(true)} sx={{ width: '300px' }} isLoading={loading}>
                          Adicionar Variação
                      </Button>
                    </Grid2> */}
                    </Grid2>

                    

                    

                    <Grid2 size={8}>
                        <Input
                            label="Nome"
                            placeholder="Preencha o nome"
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            {...register('name')}
                        />
                    </Grid2>

                    {/* <Grid2 size={4} spacing={2} sx={{ display: 'flex', gap: '10px'}}>
                        <Input
                            label="Código interno"
                            placeholder="Selecione o código"
                            disabled
                            sx={{ width: '90%'}}
                            value={internalCode}
                            {...register('code')}
                        />
                        <Button  onClick={() => setShowInternalCode(true)} sx={{ width: '20px', height: '100%' }} isLoading={loading}>
                        <FaQrcode size={25} style={{ marginTop: '5px'}} color='#fff' />
                      </Button>
                    </Grid2> */}

                    

                    {/* <Grid2 size={4}>
                      <InputMaskComponent
                        label="NCM"
                        helperText='Nomenclatura Comum do Mercosul'
                        placeholder="Preencha o NCM"
                        mask='9999.99.99'
                        name="ncm"
                        control={control}
                      />
                      
                    </Grid2>

                    <Grid2 size={4}>
                        <InputCurrency 
                            label="Preço"
                            placeholder="Preencha o preço"
                            defaultValue={0}
                            error={!!errors.price}
                            helperText={errors.price?.message}
                            {...register('price')} />
                      
                    </Grid2>

                    <Grid2 size={4}>
                        <InputCurrency 
                            label="Preço Promocional"
                            placeholder="Preencha o preço promocional"
                            defaultValue={0}
                            error={!!errors.price}
                            helperText={errors.price?.message}
                            {...register('promotionalPrice')} />
                      
                    </Grid2> */}

                    <Grid2 size={12} >
                        <RichTextEditor  name="description"
                            control={control} />
                      
                    </Grid2>

                    <Grid2 size={12} sx={{ marginTop: 5}}>
                        <DragAndDrop  ref={dragAndDropRef} />
                    </Grid2>

                    <Button type="submit" sx={{ marginTop: '10px', width: '200px' }} isLoading={loading}>
                        SALVAR
                    </Button>

                    

                  </Grid2>
                   
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { ProductsForm };
