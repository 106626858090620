import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import React, { useState, useEffect, forwardRef, ForwardRefRenderFunction } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { IProductsListPayload } from '../../../Services/api/products/products.interface';

interface SearchInputProps extends Omit<TextFieldProps, 'onChange'> {
  onSearch: (data: IProductsListPayload) => void;
}

const SearchInputBase: ForwardRefRenderFunction<HTMLInputElement, SearchInputProps> = (
  { sx = {}, onSearch, ...rest },
  ref
) => {
  const [value, setValue] = useState('');
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    setValue(newValue);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (newValue.length >= 3) {
      setTypingTimeout(setTimeout(() => {
        onSearch({ page: 1, search: newValue});
      }, 500));
    } else {
      setTypingTimeout(setTimeout(() => {
        onSearch({ page: 1, search: '' });
      }, 500));
     
    }
  };

  return (
    <TextField
      ref={ref}
      sx={{
        width: '100%',
        ...sx
      }}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      InputLabelProps={{ shrink: true }}
      value={value}
      onChange={handleChange}
      {...rest}
    />
  );
};

export const SearchInput = forwardRef(SearchInputBase);