import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import { format } from 'date-fns';
import React, { FC, useMemo, useState } from 'react';
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from '../../../../Services/api/sales/sales.interface';
import { IServiceOrderEntity } from '../../../../Services/api/service-orders/service-order.interface';
import { ConfirmDialog } from '../../../Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { Table } from '../../../Table/table.component';

interface IOsDetailsModalProps {
    onClose: (data?: any) => void
   
    handleConfirmProductionItems: (data: any) => void
    data: IServiceOrderEntity
 
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

const OsDetailsModal : FC<IOsDetailsModalProps> = ({  onClose, handleConfirmProductionItems, data }) => {
    const [value, setValue] = React.useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false)

  const [tabIndex, setTabIndex] = useState(0);
  const theme = useTheme()

  const columns = useMemo(() => {
    const createColumns: any[] = [ 
     
      {
        field: 'code',
        headerName: 'Código Personalização',
        width: 400,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{params?.row?.nsOrderProductId}</Typography></Box>
      },
      
     
      {
        field: 'description',
        headerName: 'Descrição',
        width: 200,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{params?.row?.product?.name}</Typography></Box>
      },
      // {
      //   field: 'quantity',
      //   headerName: 'Tiragem',
      //   width: 100
      // },
      {
        field: 'product_id',
        headerName: 'Produto',
        width: 200,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{params?.row?.product?.code}</Typography></Box>
      },
     
      {
        field: 'card',
        headerName: 'Cartela',
        width: 100,
      },
      {
        field: 'created_at',
        headerName: 'Data de inclusão',
        width: 200,
        renderCell: (params: any) => <Box sx={{ height: '100%', display: 'flex', alignItems: 'center'}}><Typography>{format(new Date(data?.startDate), 'dd/MM/yyyy')}</Typography></Box>
      },
      // {
      //   field: 'substrate',
      //   headerName: 'Substrato',
      //   width: 200,
      // },
      // {
      //   field: 'weight',
      //   headerName: 'Gramatura',
      //   width: 100,
      // },
      // {
      //   field: 'category',
      //   headerName: 'Categoria',
      //   width: 200,
      // },
      // {
      //   field: 'file',
      //   headerName: 'Arquivo',
      //   width: 100,
      // },
      // {
      //   field: 'finalFile',
      //   headerName: 'Arquivo Correto',
      //   width: 300,
      // },
      // {
      //   field: 'dropboxFolder',
      //   headerName: 'Pasta no Dropbox',
      //   width: 300,
      // },
      {
        field: 'status',
        headerName: 'Status',
        width: 200,
      },
    ];
  
    return createColumns;
  }, []);



  const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ marginTop: '20px'}}>{children}</Box>}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const sale : ISaleEntity = 
  {
    id: '1',
    code: '100',
    createdAt: '29 out',
    customer: {
      name: 'Diego Hennrich',
      email: 'diegohennrich@gmail.com',
      phone: '51981872327',
    },
    total: 'R$ 10.735,00',
    delivery_address: {
      address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
    },
    payment_status: TSalePaymentStatus.APPROVED,
    delivery_status: TDeliveryPaymentStatus.WAITING,
    products: [
      {
        id: '10',
        name: 'Copo e Papel Kraft Personalizado 100ml',
        quantity: 1,
        variant: '5000',
        sku: '0010076',
        price_unit: 'R$ 2.034,28',
        total: 'R$ 2.034,28',
        image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
      },
      {
        id: '11',
        name: 'Copo e Papel Personalizado 500ml',
        quantity: 1,
        variant: '10000',
        sku: '1000010014',
        price_unit: 'R$ 8.702,10',
        total: 'R$ 8.702,10',
        image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
      }
    ]
  }

  const handleConfirmation = () => {
    setShowConfirmation(false)
  }
  
  

  return (
    <>
      {/* <button onClick={handleOpen}>Abrir Modal</button> */}

      <ConfirmDialog open={showConfirmation}  title='Marcar como item produzido' description='Você tem certeza?' onCancel={() => setShowConfirmation(false)} onConfirm={handleConfirmation} />


      {!showConfirmation && (
        <Dialog fullScreen open onClose={onClose} sx={{ zIndex: 9999999, backgroundColor: theme.palette.primary.light}}>
        <AppBar position="sticky" sx={{ backgroundColor: '#fff', marginBottom: '20px'}}>
          <Toolbar>
            <Typography variant="h6" sx={{ flexGrow: 1 }}>
              {`Ordem de Serviço (OS) #${data?.externalProductionId}`}
            </Typography>
            <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <Table data={data.products} totalCount={0} columns={columns} sx={{ minHeight: '91%'}} onSelect={(e) => handleConfirmProductionItems(e)} onRowClick={(e) => handleConfirmProductionItems(e)} />
      </Dialog>
      )}
     
    </>
  );
};

export default OsDetailsModal;
