import { ServiceOdersServiceApiRemoteService } from '../../Services/api/service-orders/service-orders-service'
import { IServiceOrdersListResponse } from '../../Services/api/service-orders/service-order.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListServiceOrdersUseCase
implements IFormUsecase<number, IServiceOrdersListResponse> {
  constructor (private readonly ServiceOrdersApi: ServiceOdersServiceApiRemoteService) {}
    

  public async handle (
    page: number
  ): Promise<Result<IServiceOrdersListResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ServiceOrdersApi.list(page)

      if (response.isFailure) {
        return response
      }

      const list = response.getValue()

      if(!list) return response

    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
