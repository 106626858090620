import { FormControlLabel, FormGroup, Switch as MuiSwitch } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import React, { FC } from 'react';

interface ISwitchProps {
  label: string;
  name: string;
  control: Control<any>;
  defaultValue?: boolean;
  onChangeCallback?: (value: boolean) => void;
  // Outras props opcionais
}

const Switch: FC<ISwitchProps> = ({ onChangeCallback, label, name, control, defaultValue = false, ...rest }) => {
  return (
    <FormGroup>
      <FormControlLabel
        label={label}
        control={
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => (
              <MuiSwitch
                {...field}
                checked={field.value}
                inputRef={field.ref}
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  field.onChange(isChecked); // Atualiza o valor no form
                  console.log("disparou change")
                  if (onChangeCallback) {
                    onChangeCallback(isChecked); // Dispara o callback
                  }
                }}
                {...rest}
              />
            )}
          />
        }
      />
    </FormGroup>
  );
};

export { Switch };