/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { IProductsServiceListResponse, IProductsServicePayload } from "./products-services-interface";
import { Environment } from "../../../Config/constants";

export class ProductsServicesServiceApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public list = async (page:number): Promise<Result<IProductsServiceListResponse>> => {
    try {
        const response = await this.service.get(`/products-services`, { params: { page, offset: Number(Environment.OFFSET_REGISTERS), }});

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public create = async (data: IProductsServicePayload): Promise<Result<void>> => {
    try {
        const response = await this.service.post(`/product-service`, data);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
  
}

