/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { IServiceOrderEntity, IServiceOrdersListResponse, IServiceOrdersUpdateResponse, IUpdateServiceOrdersPayload } from "./service-order.interface";
import { Environment } from "../../../Config/constants";

export class ServiceOdersServiceApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public update = async ({ osId, productsIds, status }: IUpdateServiceOrdersPayload): Promise<Result<IServiceOrdersUpdateResponse>> => {
    try {
        const response = await this.service.patch(`/service-order/${osId}/products/status`, { productsIds, status});

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public list = async (page: number): Promise<Result<IServiceOrdersListResponse>> => {
    try {
        const response = await this.service.get(`/service-orders`, { 
          params: {
            offset: Number(Environment.OFFSET_REGISTERS),
            page
          }
        });

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public show = async (osId: string): Promise<Result<IServiceOrderEntity>> => {
    try {
        const response = await this.service.get(`/service-order/${osId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
}
