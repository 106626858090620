import { ProductionStatusEnum } from "../Services/api/service-orders/service-order.interface";

export const normalizeProductionStatus = (status: ProductionStatusEnum): string => {
    const labels = {
        INICIO: 'Aguardando Produção',
        PRODUCAO: 'Em produção',
        FINALIZACAO: 'Produzido',
        BILLED: 'Produzido e Faturado'
    }

    return labels[status]
}

// export const normalizeSFinancialStatusColors = (status: TOrderProductionStatus): { bgColor: string; textColor: string} => {
//     const bgColor = {
//         FINISHED: 'rgba(76, 175, 80, 0.7)',
//         STAND_BY: 'rgba(255, 193, 7, 0.7)',
//         PRODUCTION: 'rgba(40, 85, 135, 0.7)',
//         HALF_FINISHED: 'rgba(128, 0, 128, 0.7)',
//     }

//     const textColor = {
//         FINISHED: '#FFFFFF',
//         STAND_BY: 'rgba(33, 33, 33, 0.8)',
//         PRODUCTION: '#FFFFFF',
//         HALF_FINISHED: '#FFFFFF',
//     }

//     return {
//         bgColor: bgColor[status],
//         textColor: textColor[status]
//     }
// }