import { object, string } from 'yup'
import {
  AuthenticationApiRemoteService,
  TAuthRefreshTokenResponse
} from '../../Services/api/auth/service-authentication'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'
import { StorageLocalstorage } from '../../Shared/storage/storage-localstorage'
import { Environment } from '../../Config/constants'

export class RefreshTokenUseCase
implements IFormUsecase<string, TAuthRefreshTokenResponse> {
  constructor (private readonly repository: AuthenticationApiRemoteService) {}

  public async handle (
    oldRefreshToken: string
  ): Promise<Result<TAuthRefreshTokenResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {
      const response = await this.repository.refreshToken(oldRefreshToken)

      if (response.isFailure) {
        return response
      }

      const result = response.getValue()

      if (result === null) {
        return Result.fail(new Error('Erro ao realizar o RefreshToken'))
      }

      const { token, refreshToken } = result

      StorageLocalstorage.set(`${Environment.CUSTOMER}:token`, {
        token
      })

      StorageLocalstorage.set(`${Environment.CUSTOMER}:refresh-token`, {
        refreshToken
      })

      return Result.ok(result)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

  public validation = object({
    username: string().required('E-mail é obrigatório'),
    password: string().required('Senha é obrigatória')
  })
}
