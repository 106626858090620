import { Box, Paper, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { KanbanBoardProduction } from '../../../Components/KanbanBoardProduction/kanban-board.component';
import SaleDetailsModal from '../../../Components/Modals/variants/sale-details/sale-details-variant.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { useToast } from '../../../Hooks/ToasContext';
import { ListProductUseCase } from '../../../Usecase/Products/list-product-usecase';
import { useListProduction } from './production-list.hook';
import { ListServiceOrdersUseCase } from '../../../Usecase/ServiceOrders/list-service-orders-usecase';
import { ShowServiceOrdersUseCase } from '../../../Usecase/ServiceOrders/show-service-orders-usecase';
import { UpdateServiceOrdersUseCase } from '../../../Usecase/ServiceOrders/update-service-orders-usecase';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';

interface IProductionList {
   
    listServiceOrdersUseCase : ListServiceOrdersUseCase
    showServiceOrdersUseCase: ShowServiceOrdersUseCase
    updateServiceOrdersUseCase: UpdateServiceOrdersUseCase
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const ProductionList : FC<IProductionList> = ({  listServiceOrdersUseCase, showServiceOrdersUseCase, updateServiceOrdersUseCase }) => {

    const { listProduction, list, data, handleShowDetails, handleConfirmProductionItems, kanbanSchema ,handleShowOrderDetails, orderDetails, setOrderDetails } = useListProduction({
      listServiceOrdersUseCase,
      showServiceOrdersUseCase,
      updateServiceOrdersUseCase
    })

    useEffect(() => {
        listProduction(1)
    }, [])
   

    return (
        <BaseScreen withSideBar={false}>
            <Paper >

              {/* {orderDetails?.id && <SaleDetailsModal onClose={() => setOrderDetails(undefined)} data={orderDetails} />} */}
                <BaseContentPage 
                    title='Produção'
                    backButton
                    backUrl='/'
                    breadcrumb={[{ title: 'Produção', link: "/production-list"}, { title: 'Listagem'}]}
                    // Action={ value === 0 ? () => <Flex> 
                    //   <Switch label='Apenas com arte aprovada' name={`swtich-1`} control={control} onChangeCallback={() => {}}/>
                    //   <Switch label='Com OS' name={`swtich-2`} control ={control} onChangeCallback={() => {}}/>
                    //  <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '130px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => setShowNewOS(true)}>Criar OS</Button></Flex> : () => <Box />}
                >

                  {!!kanbanSchema?.columnOrder?.length ?  <KanbanBoardProduction handleShowOrderDetails={handleShowOrderDetails} handleConfirmProductionItems={handleConfirmProductionItems} initialData={kanbanSchema} details={orderDetails} setOrderDetails={setOrderDetails} canClick={true} canDrag={true} /> : <Placeholder description='Nenhum dado encontrado.' />}
 
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { ProductionList };
