

import { Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';
import { TagInput } from '../../../Form/TagInput/tag-input.component';
import TextArea from '../../../Form/Textarea/textarea.component';
import { ICubageEntity } from '../../../../Services/api/cubages/cubages.interface';

interface CubageProductFormProps {
    name: string;
  }
   
const CubageProductFormSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório')
  });
  
  
interface IDrawerComponent extends DrawerProps {
    onToggle: (state: boolean) => void;
    title: string;
    search?: boolean;
    titleStyle?: object
    open: boolean;
    containerStyle?: object
    loading: boolean
    handleAction: (data: any) => Promise<void>
    variantProductSelected: ICubageEntity | undefined

}
const CubageVariantProductFormDrawer : FC<IDrawerComponent> = ({ onToggle, title, open, titleStyle = {}, containerStyle = {}, loading, handleAction, variantProductSelected  }) => {
    const theme = useTheme();
    const { handleSubmit, register, control, formState, setValue } = useForm<any>({
        resolver: yupResolver(CubageProductFormSchemaValidation),
      });

      const { errors } =  formState     

      const handleSubmitForm: SubmitHandler<CubageProductFormProps> = async (values: any) => {
        
        const payload : any = {
          name: values?.name
        }

        if(variantProductSelected?.id){
          payload.cubageProductId = variantProductSelected.id
        }

        if(values?.name) {
          handleAction(variantProductSelected?.id ? payload : values?.name)
        }
       
        
      };

    
      useEffect(() => {
        if(variantProductSelected?.id){
          setValue('name', variantProductSelected.name)
        }
      }, [variantProductSelected])



    return (
        <Drawer open={open} onClose={onToggle} anchor='right' style={{ zIndex: 99999999 }}>
            <Grid2 sx={{ minWidth: '540px', padding: '30px 30px', ...containerStyle}} component='form' onSubmit={handleSubmit(handleSubmitForm)} >
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >{title}</Typography>

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Nome do Produto"
                        placeholder='Ex: Copos'
                        error={!!errors.name}
                        {...register('name')}
                    />
                </Grid2>

                

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Button type='submit'>Salvar</Button>
                </Grid2>
        
        </Grid2>
           
           </Drawer>
)
}

export { CubageVariantProductFormDrawer }