import { ProductsApiRemoteService } from '../../Services/api/products/products-service'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class CreateProductUseCase
implements IFormUsecase<FormData, void> {
  constructor (private readonly ProductsApi: ProductsApiRemoteService) {}
    

  public async handle (
    data: FormData
  ): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ProductsApi.create(data)

      if (response.isFailure) {
        return response
      }

    
      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
