import React, { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Flex } from '../../../Flex/flex.component';
import { Button } from '../../../Form/Button/button.scomponent';
import { GoPlus } from 'react-icons/go';
import { CubageVariantFormDrawer } from '../../../Drawer/variants/cubage-variant-form/cuabge-variant-form-drawer.component';
import { Placeholder } from '../../../Placeholder/placeholder.component';
import { IOptionsProps } from '../../../Form/Select/select.component';
import { ICubageVariantEntity, IUpdateCubageVariantPayload } from '../../../../Services/api/cubages-variants/cubages-variants.interface';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTheme } from '@mui/material';
import { ActionButtons } from '../../../Form/ActionButtons/action-buttons.component';
import { ConfirmDialog } from '../../../Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { ICubageEntity } from '../../../../Services/api/cubages/cubages.interface';
interface ICubageTableProps {
  cubageProductIdSelected?: string;
  boxOptions: IOptionsProps[];
  handleAction: (data?: any) => Promise<void>;
  setCubageProductIdSelected: React.Dispatch<React.SetStateAction<string>>;
  data: {
    id: string;
    name: string;
    variants: ICubageVariantEntity[];
  }[];
  setCubageVariantSelected: (state: ICubageVariantEntity | undefined) => void;
  cubageVariantSelected: ICubageVariantEntity | undefined
  cubageVariantToDestroy: string | undefined
  setCubageVariantToDestroy: (id: string) => void
  handleDestroyVariant: (id: string) => void
  handleShowCubage: (id: string) => void
  setVariantProductToDestroy: (data: string) => void
 
}

const CubageTable : FC<ICubageTableProps> = ({ data, handleAction, cubageProductIdSelected, boxOptions, setCubageProductIdSelected, setCubageVariantSelected, cubageVariantSelected, cubageVariantToDestroy, setCubageVariantToDestroy, handleDestroyVariant, handleShowCubage, setVariantProductToDestroy  }) => {
  
  const [showNewVariant, setShowNewVariant] = useState(false)
  const [showVariantId, setShowVariantId] = useState('')
  const theme = useTheme()
 
  function createData(
    name: string,
    cubageProductId: string,
    variants: ICubageVariantEntity[],
  ) {
    return {
      name,
      cubageProductId,
      variants
      // // calories,
      // // fat,
      // // carbs,
      // // protein,
      // // price,
      // history: [
      //   {
      //     description: '100 ml',
      //     qtdUnit: '1.000',
      //     qtdPackage: 20,
      //     qtdPerPackage: 50,
      //     box: `CP 100/180 - 42x35x28 cm - 4.18kg`
      //   },
      //   {
      //     description: '180 ml',
      //     qtdUnit: '1.000',
      //     qtdPackage: 20,
      //     qtdPerPackage: 50,
      //     box: `CP 100/180 - 42x35x28 cm - 4.18kg`
      //   },
      //   {
      //     description: '220 ml',
      //     qtdUnit: '1.000',
      //     qtdPackage: 20,
      //     qtdPerPackage: 50,
      //     box: `CP 100/180 - 42x35x28 cm - 4.18kg`
      //   },
      //   {
      //     description: '300 ml',
      //     qtdUnit: '1.000',
      //     qtdPackage: 20,
      //     qtdPerPackage: 50,
      //     box: `CP 100/180 - 42x35x28 cm - 4.18kg`
      //   },
      //   {
      //     description: '100 ml',
      //     qtdUnit: '500',
      //     qtdPackage: 20,
      //     qtdPerPackage: 50,
      //     box: `CP 380/500 - 47x47x47 cm - 4.18kg`
      //   },
      // ],
    };
  }

  function Row(props: { row: ReturnType<typeof createData> }) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
  
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
         
          <TableCell component="th" scope="row" width={'95%'}>
            {row.name}

           
          </TableCell>
          
          <TableCell  width={'10%'} >
            <Flex>
              <ActionButtons editFunction={() => handleShowCubage(row.cubageProductId)} setItemToDestroy={() => setVariantProductToDestroy(row.cubageProductId)} params={{ row: { id: row.cubageProductId }}} />
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Flex>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Flex justifyContent='space-between'>
                  <Typography variant="h6" gutterBottom component="div">
                    Cubagens
                  </Typography>

                  <Button style={{ width: '230px'}}  variant='outlined'onClick={() => {
                    setShowNewVariant(true)
                    setCubageProductIdSelected(row.cubageProductId)
                  }}>Adicionar Cubagem</Button>

                </Flex>
               
                <Table size="small" aria-label="purchases" sx={{ marginTop: '20px'}}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Descrição/Tamanho</TableCell>
                      <TableCell>Qtde na Caixa</TableCell>
                      <TableCell>Qtde de Pacotes</TableCell>
                      <TableCell>Qtde por pacote</TableCell>
                      <TableCell>Caixa</TableCell>
                      <TableCell align='right'>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.variants.map((historyRow) => (
                      <TableRow key={historyRow.description}>
                        <TableCell component="th" scope="row">
                          {historyRow.description}
                        </TableCell>
                        <TableCell>{historyRow.howManyInBox}</TableCell>
                        <TableCell>{historyRow.howManyPackages}</TableCell>
                        <TableCell>{historyRow.howManyPerPackage}</TableCell>
                        <TableCell>{`${historyRow?.box?.length}x${historyRow?.box?.width}x${historyRow?.box?.height} cm - ${historyRow?.boxWeight}kg`}</TableCell>
                        <TableCell>
                        <ActionButtons params={{ row: { ...historyRow }}} setItemToDestroy={setCubageVariantToDestroy} editFunction={setCubageVariantSelected} />
                        
                          {/* <Flex sx={{ height: '100%', gap:'20px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}><Button size='medium' sx={{ width: '120px'}} variant='outlined' LeftIcon={() => <EditIcon sx={{ width: '20px', height: '20px'}} />} onClick={() => setShowVariantId(historyRow.id)}>Editar</Button>
                          <Button size='medium' sx={{ width: '130px', backgroundColor: theme.palette.error.main}} variant="contained" LeftIcon={() => <DeleteIcon sx={{ width: '20px', height: '20px'}} />} onClick={() => setVariantIdToDelete(historyRow.id)}>Excluir</Button>
                          </Flex> */}
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const rows = data?.map(i => (
    createData(i.name, i.id, i.variants)
  ))
   
  return(
    <>
    <ConfirmDialog title='Remover Variação de Cubagem' onCancel={() => setCubageVariantToDestroy('')} description='Você tem certeza?' open={!!cubageVariantToDestroy} onConfirm={() => handleDestroyVariant(String(cubageVariantToDestroy))}  /> 
      
      {(showNewVariant || !!cubageVariantSelected?.id) && <CubageVariantFormDrawer open={showNewVariant || !!cubageVariantSelected?.id} handleSelect={() => {}} onToggle={() => {
        if(cubageVariantSelected?.id){
          setCubageVariantSelected(undefined)
        } else {
          setShowNewVariant(prev => !prev)
        }
      }} title={cubageVariantSelected?.id ? 'Edição de cubagem' : 'Nova Cubagem'} subTitle='Preencha os dados abaixo' handleAction={handleAction} cubageProductId={cubageProductIdSelected || cubageVariantSelected?.id} boxOptions={boxOptions} cbFunction={() => {
        setShowNewVariant(false)
        setCubageProductIdSelected('')
        setCubageVariantSelected(undefined)
      }} cubageVariantSelected={cubageVariantSelected} /> }

      {!!data?.length ? (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
              <TableHead>
              <TableRow>
                  
                  <TableCell align='left' sx={{ fontWeight: 'bold', fontSize: '19px'}} width={'100%'}>Produto</TableCell>
                
              </TableRow>
              </TableHead>
              <TableBody>
              {rows.map((row) => (
                  <Row key={row.name} row={row} />
              ))}
              </TableBody>
          </Table>
        </TableContainer>
      ) : <Placeholder description='Nenhum dado encontrado.' /> }

    
    
    </>
  )

}

export { CubageTable }