/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { IBillProductOrderPayload, IOrderEntity, IOrdersListResponse, IOrdersRegisteredOnOmieEntity } from "./orders.interface";
import { Environment } from "../../../Config/constants";

export class OrdersApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public bill = async ({ orderId, productsIds }: IBillProductOrderPayload): Promise<Result<IOrdersListResponse>> => {
    try {
        const response = await this.service.post(`/order/${orderId}/bill-order`, {productsIds });

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public list = async (page: number): Promise<Result<IOrdersListResponse>> => {
    try {
        const response = await this.service.get(`/orders`, { 
          params: {
            offset: Number(Environment.OFFSET_REGISTERS),
            page
          }
        });

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public show = async (orderId: string): Promise<Result<IOrderEntity>> => {
    try {
        const response = await this.service.get(`/order/${orderId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public showDetails = async (omieOrderId: string): Promise<Result<IOrdersRegisteredOnOmieEntity>> => {
    try {
        const response = await this.service.get(`/order/registered-on-omie/${omieOrderId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };
}
