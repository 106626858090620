import { ProductsApiRemoteService } from '../../Services/api/products/products-service'
import { IProductsListPayload, IProductsListResponse } from '../../Services/api/products/products.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListProductUseCase
implements IFormUsecase<IProductsListPayload, IProductsListResponse> {
  constructor (private readonly ProductsApi: ProductsApiRemoteService) {}
    

  public async handle (
    data: IProductsListPayload
  ): Promise<Result<IProductsListResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ProductsApi.list(data)

      if (response.isFailure) {
        return response
      }

      const list = response.getValue()

      if(!list) return response

    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
