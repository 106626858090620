import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useMemo,
    useState,
  } from 'react'
import { Environment } from '../Config/constants'
import { StorageLocalstorage } from '../Shared/storage/storage-localstorage'
import { useNavigate } from 'react-router-dom'
  

  
  type UserStateType = {
    username?: string
  }
  
  type UserInfo = {
    username?: string
  }
  
  export type UserContextType = {
    UserInfo: (userInfo: UserInfo) => void
    Logout: () => void
    GetUserData: () => UserStateType
    SetUserDataKeyValue: (key: keyof UserStateType, value: string) => void
  }
  
  const UserContext = createContext({} as UserContextType)
  
  export const UserProvider = ({ children }: { children: ReactNode }) => {
    const userStorage = StorageLocalstorage.get(`${Environment.CUSTOMER}:user`)
 

    const [userState, setUserState] = useState<UserStateType>({
        username: userStorage?.username || '',
    })
  
 
  
    const UserInfo = (userInfo: UserInfo) => {
      try {
        setUserState({
            username: userInfo.username,
        })
  
        StorageLocalstorage.set(`${Environment.CUSTOMER}:user`, {
            username: userInfo.username,
        })
      } catch (error) {
        console.log(error)
      }
    }
  
   
  
    const Logout =  async () => {
      try {
        
         await StorageLocalstorage.clearAll()
  
        // StorageLocalstorage.set(`${Environment.CUSTOMER}:user`, {
        //  username: ''
        // })
     
     
       
      } catch (error) {
        console.log(error)
      }
    }
  
    const GetUserData = useCallback(() => userState, [userState])
    
  
    const SetUserDataKeyValue = (key: keyof UserStateType, value: string) => {
      try {
        const userStorage = StorageLocalstorage.get(`${Environment.CUSTOMER}:user`)
  
        StorageLocalstorage.set(`${Environment.CUSTOMER}:user`, {
          ...userStorage,
          [key]: value,
        })
        setUserState((state) => ({ ...state, [key]: value }))
      } catch (error) {
        console.log(error)
      }
    }
  
    const userProviderValue = useMemo(
      () => ({
      
        UserInfo,
        Logout,
        GetUserData,
        SetUserDataKeyValue,
      }),
      [
        UserInfo,
        Logout,
        GetUserData,
       
      ],
    )
  
    // const handleRefreshToken = async () => {
    //   try {
    //     const userRefreshToken = StorageLocalstorage.getItem('refreshToken')
  
    //     if (!userRefreshToken || userRefreshToken?.length === 0) {
    //       return
    //     }
  
    //     const { refreshToken, sessionToken } = await Services.Token.refresh(
    //       userRefreshToken,
    //     )
  
    //     setUserState((prevState) => ({
    //       ...prevState,
    //       refreshToken,
    //       sessionToken,
    //     }))
  
    //     StorageLocalstorage.setItem('refreshToken', refreshToken)
    //     StorageLocalstorage.setItem('sessionToken', sessionToken)
    //   } catch (error) {
    //     console.log(error)
    //     Logout()
    //     navigate('/sign-in', { replace: true })
    //   }
    // }
  
    // useEffect(() => {
    //   handleRefreshToken()
    // }, [location.pathname])
  
    return (
      <UserContext.Provider value={userProviderValue}>
        {children}
      </UserContext.Provider>
    )
  }
  
  export function UserFullContext() {
    const context = useContext(UserContext)
    return context
  }