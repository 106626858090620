import React, { FC } from 'react'
import { Flex } from '../Flex/flex.component'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Typography } from '@mui/material';

interface IPlaceholderProps {
    description: string
}

const Placeholder : FC<IPlaceholderProps> = ({ description }) => {
    return(
        <Flex flexDirection='column' alignItems='center' justifyContent='center' flex={1}>
            <ErrorOutlineIcon fontSize='large' />
            <Typography marginTop='15px'>{description}</Typography>
        </Flex>
    )
}

export { Placeholder}