import { ServiceOdersServiceApiRemoteService } from '../../Services/api/service-orders/service-orders-service'
import { IServiceOrdersListResponse } from '../../Services/api/service-orders/service-order.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'
import { IProductsServicePayload } from '../../Services/api/products-services/products-services-interface'
import { ProductsServicesServiceApiRemoteService } from '../../Services/api/products-services/products-services-service'


export class CreateProductsServiceOrdersUseCase
implements IFormUsecase<IProductsServicePayload, void> {
  constructor (private readonly ProductsServiceApi: ProductsServicesServiceApiRemoteService) {}
    

  public async handle (
    data: IProductsServicePayload
  ): Promise<Result<void>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ProductsServiceApi.create(data)

      if (response.isFailure) {
        return response
      }


      return Result.ok()
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
