import React, { FC, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { Box, Card, CardContent, Typography, Grid2, Button, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox } from '@mui/material';
import { StrictModeDroppable } from './components/droppable-component';
import { Flex } from '../Flex/flex.component';
import { Table } from '../Table/table.component';
import OsDetailsModal from '../Modals/variants/os-details/os-details-variant.component';
import IKanbanBoardProductionSchemaProps from './interfaces/kanban-board-production-schema.interface';
import IServiceOrdersListProps from '../../Shared/Interfaces/service-orders-list.interface';
import { IServiceOrderEntity, ProductionStatusEnum } from '../../Services/api/service-orders/service-order.interface';
import { ConfirmDialog } from '../Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { useNavigate } from 'react-router-dom';


interface IKanbanBoardProductionProps {
  initialData?: IKanbanBoardProductionSchemaProps
  handleConfirmProductionItems: (data?:any) => void;
  details?: IServiceOrderEntity
  handleShowOrderDetails: (osId?: string) => void
  setOrderDetails: (data?: any) => void
  canDrag?: boolean
  canClick?: boolean
}

const KanbanBoardProduction : FC<IKanbanBoardProductionProps> = ({ initialData, handleConfirmProductionItems, handleShowOrderDetails, details, setOrderDetails, canClick, canDrag }) => {
  const [data, setData] = useState(initialData);
  const [tempMovement, setTempMovement] = useState<DropResult | null>(null);
  const [showConfirmation, setShowConfirmation] = useState({
    show: false,
    title: '',
    description: ''
  })
 
  const navigate = useNavigate()

  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCancelDragAction = () => {
    setShowConfirmation({ show: false, title: '', description: ''})
    setTempMovement(null)
  }

  const moveToNextColumn = () => {
    try {
      if (!tempMovement) return;
      const { destination, source, draggableId } = tempMovement;
  
      if (!destination) return;
  
      const task = data?.tasks[draggableId];
      console.log("task: ", task);
  
      if (
        destination.droppableId === source?.droppableId &&
        destination.index === source?.index
      ) {
        return;
      }
  
      const startColumn = data?.columns[source.droppableId];
      const endColumn = data?.columns[destination.droppableId];
  
      if (startColumn === endColumn) {
        const newTaskIds = Array.from(startColumn?.taskIds ?? []);
        newTaskIds.splice(source.index, 1);
        newTaskIds.splice(destination.index, 0, draggableId);
        
        const newColumn = {
          ...startColumn,
          taskIds: newTaskIds,
        };
      
        setData((prev: any) => ({
          ...prev,
          columns: {
            ...prev.columns,
            [String(newColumn.id)]: newColumn,
          },
        }));
        return;
      }
  
      const startTaskIds = Array.from(startColumn?.taskIds ?? []);
      startTaskIds.splice(source.index, 1);
      const newStartColumn = {
        ...startColumn,
        taskIds: startTaskIds,
      };
  
      const endTaskIds = Array.from(endColumn?.taskIds ?? []);
      endTaskIds.splice(destination.index, 0, draggableId);
  
      const newEndColumn = {
        ...endColumn,
        taskIds: endTaskIds,
      };
  
      setData((prev: any) => ({
        ...prev,
        columns: {
          ...prev.columns,
          [String(newStartColumn.id)]: newStartColumn,
          [String(newEndColumn.id)]: newEndColumn,
        },
      }));
  
      const productsIds = task?.content?.items?.map((i: any) => i.id);
      const osId = task?.id;
  
      const payload = {
        productsIds,
        osId,
        status: endColumn?.id,
      };
      handleConfirmProductionItems(payload);
    } catch (error) {
      console.log("error: ", error);
    } finally {
      onCancelDragAction();
    }
  };
  

  const onDragEnd = (result: any) => {
    console.log("drag called: ", data)
    const { destination, source, draggableId } = result;
  
    if (!destination) return;
  
    const task = data?.tasks[draggableId];
    const endColumn = data?.columns[destination.droppableId];
    console.log("endColumn: ", endColumn)
  
    if (task?.isLocked) {
      alert('Este card está bloqueado e não pode ser movido.');
      return;
    }

    setTempMovement(result)
    setShowConfirmation({
      show: true,
      title: 'Você tem certeza?',
      description: `Movimentar todos os itens dessa OS para "${endColumn?.title}"`
    })
  
    
  };

  const handleCardClick = (task: any) => {
    console.log("task: ", task)
    navigate(`/production-details/${task.id}`)
    // handleShowOrderDetails(task?.id)
    // setSelectedTask(task);
    // setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setOrderDetails(undefined)
  };
 


  return (
    <>
    <ConfirmDialog open={showConfirmation?.show}  title={showConfirmation?.title} description={showConfirmation?.description} onCancel={onCancelDragAction} onConfirm={moveToNextColumn} />

     <DragDropContext onDragEnd={onDragEnd}>
        <Card sx={{ padding: '20px', height: '80vh'}}>
            <Grid2 container spacing={2}>
            {data?.columnOrder?.map((columnId:any) => {
                const column = data?.columns[columnId];
                const tasks = column?.taskIds?.map((taskId) => data?.tasks[taskId]);

                return (
                
                    <Grid2 size={4} key={column.id}>
                    <Typography variant="h6" align="center" mb='20px'>
                        {column?.title}
                    </Typography>
                    <StrictModeDroppable droppableId={column.id}>
                        {(provided) => (
                        <Box
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            sx={{
                            background: '#2A2A2A',
                            padding: 2,
                            borderRadius: 1,
                            minHeight: 700,
                            }}
                        >
                            {tasks.map((task, index) => (
                              <Draggable
                                key={task.id}
                                draggableId={task.id}
                                index={index}
                                isDragDisabled={task.isLocked || !canDrag} // Bloqueia o drag
                              >
                                {(provided) => (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...(task.isLocked ? {} : provided.dragHandleProps)} // Remove o handle para itens bloqueados
                                    sx={{
                                      marginBottom: 2,
                                      opacity: task.isLocked ? 0.5 : 1, // Visualmente indicar bloqueio
                                      cursor: task.isLocked || !canClick ? 'not-allowed' : 'pointer',
                                    }}
                                    onClick={() => canClick ? handleCardClick(task) : {}}
                                  >
                                    <CardContent>
                                      <Typography sx={{ marginTop: '10px', fontSize: '19px'}}>{task?.content?.os}</Typography>
                                      {/* <Typography>Items:</Typography>
                                      {task?.content?.items?.map((i) => (
                                        <Typography key={i.id}>{i.title}</Typography>
                                      ))} */}
                                    </CardContent>
                                  </Card>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                        </Box>
                        )}
                    </StrictModeDroppable>
                    </Grid2>
                
                );
            })}
            </Grid2>
        </Card>
    </DragDropContext>

    {details?.id && <OsDetailsModal onClose={handleCloseModal} data={details}  handleConfirmProductionItems={handleConfirmProductionItems}  /> }

   
    </>
   
  );
};

export  {KanbanBoardProduction};