
import { Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';


interface ProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    description: string
  }
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  
interface IDrawerComponent extends DrawerProps {
    onToggle: (state: boolean) => void;
    title: string;
    subTitle?: string;
    search?: boolean;
    searchFunction?: () => void;
    titleStyle?: object
    subTitleStyle?: object
    open: boolean;
    handleSelect: (category: string) => void;
   

}
const CatetoryListDrawer : FC<IDrawerComponent> = ({ onToggle, title, subTitle, open, titleStyle = {}, subTitleStyle = {}, handleSelect  }) => {
    const theme = useTheme();
    const { handleSubmit, register, control, formState } = useForm<any>({
        resolver: yupResolver(SignInSchemaValidation),
      });

      const { errors } =  formState


    return (
        <Drawer open={open} onClose={onToggle} anchor='right' style={{ zIndex: 99999999 }}>
            <Grid2 sx={{ minWidth: '540px', padding: '30px 30px'}} >
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >{title}</Typography>
                {subTitle && <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: theme.palette.text.secondary,  ...subTitleStyle}} >{subTitle}</Typography> }

                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Nome"
                        placeholder="Preencha o nome"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        {...register('name')}
                    />
                </Grid2>
        <Grid2 size={12}>
            <Grid2 size={12} sx={{ marginTop: '20px'}}>
                <Button boxLeftSx={{ gap: '10px', marginLeft: '5px'}} style={{  width: '200px'}} LeftIcon={() => <GoPlus size={18} />}>Nova Categoria</Button> 
            </Grid2>

            <Divider sx={{ marginTop: '20px', marginBottom:'10px'}} />

            {['Copos de Papel','Copos de Papel/Copos Personalizados','Copos de Papel/Copos Kraft','Copos de Papel/Copos Brancos','Copos de Papel/Tampas Plásticas','Copos de Papel/Tampas Antivazamento']?.map(item => (
                <Grid2 size={12} sx={{ marginTop: '20px'}} key={item}>
                  <Switch label={item} name={`swtich-${item}`} control={control} onChangeCallback={() => handleSelect(item)}/>
                </Grid2>
            ))}

          
        </Grid2>
        </Grid2>
           
           </Drawer>
)
}

export { CatetoryListDrawer }