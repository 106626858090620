import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Grid2, Paper, useTheme } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { Input } from '../../../Components/Form/Input/input.component';
import { Loader } from '../../../Components/Loader';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { useToast } from '../../../Hooks/ToasContext';
import { CreateProductUseCase } from '../../../Usecase/Products/create-product-usecase';

import { CatetoryListDrawer } from '../../../Components/Drawer/variants/category-list/category-list-drawer.component';
import { SeoFormDrawer } from '../../../Components/Drawer/variants/seo-form/seo-form-drawer.component';


interface ProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    description: string
    width: number
    length: number
    height: number
  }
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  

  interface IFinancialFormProps {
    createProductUseCase: CreateProductUseCase
  }
const FinancialForm : FC<IFinancialFormProps> = ({ createProductUseCase }) => {
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState<File[]>([]);
    const [showCategoriesModal, setShowCategoriesModal] = useState(false)
    const [categories,setCategories] = useState<string[]>([])
    const [showSEOModal, setShowSEOModal] = useState(false)
    const theme = useTheme()
    const navigate = useNavigate()
    const { showToast } = useToast()
    const [lengthValue, setLenghValue] = useState(0);
    const [widthValue, setWidthValue] = useState(0)
    const [heightValue, setHeightValue] = useState(0)

    const dragAndDropRef = useRef(null);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    const { handleSubmit, register, control, formState } = useForm<ProductProps>({
        resolver: yupResolver(SignInSchemaValidation),
      });

      const { errors } =  formState

   
      function CustomTabPanel(props: TabPanelProps) {
        const { children, value, index, ...other } = props;
      
        return (
          <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
          >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
          </div>
        );
      }
      
      function a11yProps(index: number) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
      }

      const handleAction: SubmitHandler<ProductProps> = async (values) => {
        console.log("values: ", values)
        try {
          setLoading(true)
          let normalizeVales : any = {...values}
          normalizeVales.service = false
          normalizeVales.nsStoreIds = [5061090]
          console.log('Form submitted with:', values);
          // Example login logic (replace with your actual login use case)

          const formData = new FormData();

        
          Object.keys(normalizeVales).forEach((key) => {
            let value = normalizeVales[key];
            if (key === 'price') {
              // Process the price value
              value = value
                .replace(/\s/g, '')          // Remove all whitespaces
                .replace(/R\$\s*/g, '')      // Remove 'R$' symbol and any trailing spaces
                .replace(/\./g, '')          // Remove thousands separator '.'
                .replace(',', '.');          // Replace decimal separator ',' with '.'
            }
            formData.append(key, value);
          });

           // Anexar as imagens ao FormData
          //  const files = dragAndDropRef?.current?.files;
          //   files.forEach((file: File) => {
          //       formData.append('images', file);
          //   });


          
         
          
          const storeResult = await createProductUseCase.handle(formData);

          if (storeResult.isFailure) {
            console.log("storeResult: ", storeResult)
            showToast(String(storeResult?.error?.error), 'error');
            return;
          }

          showToast('Produto Cadastrado', 'success');
         
        
          navigate('/products-list');
        } catch (error) {
          // toast({
          //   position: 'top-right',
          //   render: () => <ToastCustom type='fail' title='Erro' description={error.response?.data?.error ?? 'Erro ao logar. Por favor, tente novamente'} />,
          // });
        } finally {
          setLoading(false);
        }
      };

      const handleSelectCategories = (e: string) => {
        const existsCategory = categories.find(category => category === e)

        if(existsCategory){
          const filtered = categories?.filter(category => category !== e)
          setCategories([...filtered])
        } else {
          setCategories(prev => [...prev, e])
        }
      }

      console.log("category: ", categories)
      
    return (
        <BaseScreen>
            <CatetoryListDrawer handleSelect={e => handleSelectCategories(e)} open={showCategoriesModal} title='V' onToggle={() => setShowCategoriesModal(prev => !prev)} subTitle='Cadastre uma nova categoria' />
            <SeoFormDrawer handleSelect={e => handleSelectCategories(e)} open={showSEOModal} title='Tags, Marca e SEO' onToggle={() => setShowSEOModal(prev => !prev)} subTitle='Cadastre os conteúdos de SEO para otimizar suas buscas.' containerStyle={{ width: '540px' }} />
            <Paper >
                <BaseContentPage 
                    title='Caixas'
                    breadcrumb={[{ title: 'Caixas', link: "/box-list"}, { title: 'Nova Caixa'}]}
                >

                {loading && <Loader />}

                <Grid2 container spacing={5} sx={{ marginTop: 2}} component="form" onSubmit={handleSubmit(handleAction)}>


                <Grid2 size={8}>
                    <Input
                        label="Código da Caixa"
                        placeholder="Ex: CP 100/180"
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        {...register('name')}
                    />
                </Grid2>

                <Grid2 size={4}>
                    <Input
                        label="Tamanho da caixa"
                        disabled
                        placeholder="Preencher em Cm"
                        value={`${lengthValue}x${widthValue}x${heightValue} cm` }
                        name='size'
                        onChange={() => {}}
                       
                    />
                </Grid2>

                <Grid2 size={4}>
                    <Input
                        label="Comprimento"
                        placeholder="Preencher em Cm"
                        callbackfunction={(e) => setLenghValue(Number(e))}
                        {...register('length')}
                       
                       
                        
                    />
                </Grid2>

                <Grid2 size={4}>
                  <Input
                        label="Largura"
                        placeholder="Preencher em Cm"
                        {...register('width')}
                        callbackfunction={(e) => setWidthValue(Number(e))}
                    />
                  
                </Grid2>

                <Grid2 size={4}>
                <Input
                        label="Altura"
                        placeholder="Preencher em Cm"
                        {...register('height')}
                        callbackfunction={(e) => setHeightValue(Number(e))}
                    />
                  
                  
                </Grid2>                

                <Button type="submit" sx={{ marginTop: '10px', width: '200px' }} isLoading={loading}>
                    SALVAR
                </Button>
                </Grid2>

              </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { FinancialForm };
