import { Environment } from '../../Config/constants'

export class StorageLocalstorage {
  constructor () {}

  public static set (
    key: `${typeof Environment.CUSTOMER}:user` | `${typeof Environment.CUSTOMER}:token` | `${typeof Environment.CUSTOMER}:refresh-token` | `${typeof Environment.CUSTOMER}:metabase-id`,
    value: any
  ): void {
    localStorage.setItem(key, JSON.stringify(value))
  }

  public static get (
    key: `${typeof Environment.CUSTOMER}:user` | `${typeof Environment.CUSTOMER}:token`
  ): any {
    const value = localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  }

  public static clearAll (): void {
    localStorage.clear()
  }
}
