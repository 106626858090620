import { Divider, Grid, Typography, useTheme, Box, Grid2 } from '@mui/material';
import React, { FC } from 'react';
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button } from '../../../Form/Button/button.scomponent';
import { Switch } from '../../../Form/Switch/switch.component';
import { DatePicker } from '../../../Form/DatePicker/date-picker.component';

const SignInSchemaValidation = Yup.object().shape({
  name: Yup.string().required('Nome é obrigatório'),
  code: Yup.string(),
  ncm: Yup.string(),
  personalized: Yup.boolean(),
  createOnNuvemShop: Yup.boolean(),
  createOnOmie: Yup.boolean(),
  price: Yup.string().required('Preço Unitário é obrigatório'),
  description: Yup.string(),
  createOnb2b: Yup.boolean(),
  createOnb2c: Yup.boolean(),
});

interface IDrawerComponent extends DrawerProps {
  onToggle: (state: boolean) => void;
  title: string;
  subTitle?: string;
  search?: boolean;
  searchFunction?: () => void;
  titleStyle?: object;
  subTitleStyle?: object;
  open: boolean;
  handleSelect: (category: string) => void;
}

const FinancialFiltersDrawer: FC<IDrawerComponent> = ({
  onToggle,
  title,
  subTitle,
  open,
  titleStyle = {},
  subTitleStyle = {},
  handleSelect,
}) => {
  const theme = useTheme();
  const { handleSubmit, register, control, formState } = useForm<any>({
    resolver: yupResolver(SignInSchemaValidation),
  });

  const { errors } = formState;

  return (
    <Drawer
      open={open}
      onClose={onToggle}
      anchor="right"
      style={{ zIndex: 99999999, flex: 1 }}
    >
      <Grid2 container sx={{ minWidth: '540px', padding: '30px 30px', height: '100%', flex: 1, position: 'relative' }}>
        {/* Conteúdo do Drawer */}
        <Grid2  size={12}>
          <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle }}>
            {title}
          </Typography>
          {subTitle && (
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: '400',
                color: theme.palette.text.secondary,
                ...subTitleStyle,
              }}
            >
              {subTitle}
            </Typography>
          )}
          <Divider sx={{ marginTop: '20px', marginBottom: '10px' }} />
          <Typography
            sx={{ fontSize: '1rem', fontWeight: 500, color: theme.palette.text.secondary }}
          >
            Status
          </Typography>
          {['Pronto para faturar', 'Parcialmente Produzido', 'Faturado', 'Aguardando'].map((item) => (
            <Grid2 size={12} sx={{ marginTop: '20px' }} key={item}>
              <Switch
                label={item}
                name={`swtich-${item}`}
                control={control}
                onChangeCallback={() => handleSelect(item)}
              />
            </Grid2>
          ))}

          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 500,
              marginTop: '20px',
              marginBottom: '20px',
              color: theme.palette.text.secondary,
            }}
          >
            Datas
          </Typography>
          <Grid2 container spacing={2}>
            <Grid2 size={6}>
              <DatePicker
                name="initial_date"
                control={control}
                label="Data inicial"
                helperText={errors.date?.message}
                minDate="2023-01-01"
                maxDate="2024-12-31"
              />
            </Grid2>
            <Grid2 size={6}>
              <DatePicker
                name="final_date"
                control={control}
                label="Data Final"
                helperText={errors.date?.message}
                minDate="2023-01-01"
                maxDate="2024-12-31"
              />
            </Grid2>
          </Grid2>
        </Grid2>

        {/* Footer */}
        <Box
          sx={{
            position: 'absolute',
            bottom: '40px',
            backgroundColor: theme.palette.background.paper,
          }}
        >
          <Grid2 container spacing={2} justifyContent="flex-end">
            <Grid2 >
              <Button variant="outlined" onClick={() => onToggle(false)} sx={{ width: '120px'}}>
                Cancelar
              </Button>
            </Grid2>
            <Grid2>
              <Button variant="contained" onClick={handleSubmit((data) => console.log(data))} sx={{ width: '120px'}}>
                Filtrar
              </Button>
            </Grid2>
          </Grid2>
        </Box>
      </Grid2>
    </Drawer>
  );
};

export { FinancialFiltersDrawer };
