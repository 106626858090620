/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosInstance } from "axios";
import { Result } from "../../../Shared/Protocol/protocol-result";
import { ICreateCubageVariantPayload, ICubageVariantEntity, IUpdateCubageVariantPayload } from "./cubages-variants.interface";

export class CubagesVariantsApiRemoteService {
  constructor(private service: AxiosInstance) {}

  public update = async (data: IUpdateCubageVariantPayload): Promise<Result<void>> => {
    try {
        const response = await this.service.put(`/cubages/variants/${data.cubageVariationId}`, data);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public create = async ({ cubageProductId, data}: ICreateCubageVariantPayload): Promise<Result<void>> => {
    try {
        const response = await this.service.post(`/cubages/${cubageProductId}/variants`, data);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public show = async (variantId: string): Promise<Result<ICubageVariantEntity>> => {
    try {
        const response = await this.service.get(`/cubages/variants/${variantId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

  public destroy = async (variantId: string): Promise<Result<void>> => {
    try {
        const response = await this.service.delete(`/cubages/variants/${variantId}`);

      return Result.ok(response.data);
    } catch (error: any) {
      return Result.fail(error?.response?.data ?? error);
    }
  };

//   public list = async (page: number): Promise<Result<ICubageEntity[]>> => {
//     try {
//         const response = await this.service.get(`/cubages`);

//       return Result.ok(response.data);
//     } catch (error: any) {
//       return Result.fail(error?.response?.data ?? error);
//     }
//   };
}
