
import { Divider, Grid2, Typography, useTheme } from '@mui/material'
import React, { FC, ReactNode, useEffect } from 'react'
import { Button } from '../../../Form/Button/button.scomponent'
import { GoPlus } from 'react-icons/go'
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import { Input } from '../../../Form/Input/input.component';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup'
import { Switch } from '../../../Form/Switch/switch.component';
import { TagInput } from '../../../Form/TagInput/tag-input.component';
import TextArea from '../../../Form/Textarea/textarea.component';



interface ProductProps {
    name: string;
    code: string;
    ncm: string;
    personalized: boolean
    createOnNuvemShop: boolean;
    createOnOmie: boolean;
    createOnb2b: boolean;
    createOnb2c: boolean;
    price: string
    description: string
  }
  
  
const SignInSchemaValidation = Yup.object().shape({
    name: Yup.string().required('Nome é obrigatório'),
    code: Yup.string(),
    ncm: Yup.string(),
    personalized: Yup.boolean(),
    createOnNuvemShop: Yup.boolean(),
    createOnOmie: Yup.boolean(),
    price: Yup.string().required('Preço Unitário é obrigatório'),
    description: Yup.string(),
    createOnb2b: Yup.boolean(),
    createOnb2c: Yup.boolean(),
  });

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
  
interface IDrawerComponent extends DrawerProps {
    onToggle: (state: boolean) => void;
    title: string;
    subTitle?: string;
    search?: boolean;
    searchFunction?: () => void;
    titleStyle?: object
    subTitleStyle?: object
    open: boolean;
    handleSelect: (category: string) => void;
    containerStyle?: object
   

}
const SeoFormDrawer : FC<IDrawerComponent> = ({ onToggle, title, subTitle, open, titleStyle = {}, subTitleStyle = {}, containerStyle = {}, handleSelect  }) => {
    const theme = useTheme();
    const { handleSubmit, register, control, formState } = useForm<any>({
        resolver: yupResolver(SignInSchemaValidation),
      });

      const { errors } =  formState


    return (
        <Drawer open={open} onClose={onToggle} anchor='right' style={{ zIndex: 99999999 }}>
            <Grid2 sx={{ minWidth: '540px', padding: '30px 30px', ...containerStyle}} >
                <Typography sx={{ fontSize: '2rem', fontWeight: 'bold', ...titleStyle}} >{title}</Typography>
                {subTitle && <Typography sx={{ fontSize: '1rem', fontWeight: '400', color: theme.palette.text.secondary,  ...subTitleStyle}} >{subTitle}</Typography> }

                
                <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginTop: '30px'}} >Tags</Typography>
                <Typography sx={{ fontSize: '0.9rem', fontWeight: '400'}} >Adicione palavras-chave que descrevam seu produto para que seus clientes possam encontrá-lo na barra de pesquisa da sua loja.</Typography>
                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <TagInput
                        label="Nome"
                        placeholder="Preencha a tag"
                        // error={!!errors.name}
                        // helperText={errors.name?.message}
                        // {...register('name')}
                    />
                </Grid2>

                <Typography sx={{ fontSize: '1.5rem', fontWeight: 'bold', marginTop: '30px'}} >SEO</Typography>
                <Typography sx={{ fontSize: '0.9rem', fontWeight: '400'}} >Aumente a relevância dos seus produtos nos buscadores Google.</Typography>
                {/* <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="URL Amigável"
                        prefix="https://.../produtos/"
                        // error={!!errors.name}
                        helperText={errors.name?.message}
                        // {...register('name')}
                    />
                </Grid2>
                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <Input
                        label="Título SEO"
                        placeholder="Ex: Copo 500ml"
                        // error={!!errors.name}
                        // helperText={errors.name?.message}
                        // {...register('name')}
                    />
                </Grid2>
                <Grid2 size={12} sx={{ marginTop: '20px'}}>
                    <TextArea
                        label="Descrição SEO"
                        placeholder="Ex: Copo 500ml reutilizável com tampa antivazamento."
                        // error={!!errors.name}
                        // helperText={errors.name?.message}
                        {...register('name')}
                    />
                </Grid2> */}

        
        </Grid2>
           
           </Drawer>
)
}

export { SeoFormDrawer }