import { Paper, useTheme } from '@mui/material';
import { FC, useEffect, useMemo } from 'react';
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../../../Components/Dialog/variants/dialog-confirmation/dialog-confirmation.component';
import { ActionButtons } from '../../../Components/Form/ActionButtons/action-buttons.component';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';
import { Table } from '../../../Components/Table/table.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { DestroyBoxesUseCase } from '../../../Usecase/Boxes/destroy-boxes-usecase';
import { ListBoxesUseCase } from '../../../Usecase/Boxes/list-boxes-usecase';
import { useListBoxes } from './box-list.hook';
interface IBoxList {
  listBoxesUseCase: ListBoxesUseCase
  destroyBoxesUseCase: DestroyBoxesUseCase
}
const BoxList : FC<IBoxList> = ({ listBoxesUseCase, destroyBoxesUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listBoxes, list, setBoxToDestroy, boxToDestroy, handleDestroyBox,totalRegisters } = useListBoxes({
      listBoxesUseCase,
      destroyBoxesUseCase,
      
      
    })

    useEffect(() => {
        listBoxes(1)
    }, [])


    const columns = useMemo(() => {
        const createColumns: any[] = [
        
          {
            field: 'code',
            headerName: 'Código',
            width: 300,
          },
          {
            field: 'size',
            headerName: 'Tamanho',
            width: 300,
          },
          {
            field: "actions",
            headerName: "Ações",
            headerAlign: "right",
            flex: 1,
            renderCell: (params: any) => <ActionButtons params={params} setItemToDestroy={setBoxToDestroy} editLink={`/box-form/${params.row.id}`} />
          }
        ];
      
        return createColumns;
      }, []);
   

    return (
        <BaseScreen>
        <ConfirmDialog title='Remover Caixa' onCancel={() => setBoxToDestroy('')} description='Você tem certeza?' open={!!boxToDestroy} onConfirm={handleDestroyBox}  /> 
            <Paper >
                <BaseContentPage 
                    title='Caixas'
                    breadcrumb={[{ title: 'Caixas', link: "/box-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '130px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => navigate('/box-form')}>Adicionar</Button>}
                >
                  {!!list?.length ? <Table data={list} totalCount={totalRegisters} columns={columns} onPageChange={listBoxes} /> : <Placeholder description='Nenhum dado encontrado.' />}
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { BoxList };
