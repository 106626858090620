export const typeOptions = [
    {
      "value": "E",
      "label": "Embalagens Papel"
    },
    {
      "value": "Q",
      "label": "Equipamentos e Acessórios"
    },
    {
      "value": "R",
      "label": "Produtos Revenda"
    }
  ];
  
  
  export const familyOptions = [
    
    {
      "value": "CCP",
      "label": "Copo"
    },
    {
      "value": "PPT",
      "label": "Pote"
    },
    {
      "value": "BTR",
      "label": "Bandeja com tampa de trava"
    },
    {
      "value": "BTS",
      "label": "Bandeja selável com tampa"
    },
    {
      "value": "TBS",
      "label": "Tampa para bandeja selável"
    },
    {
      "value": "BFB",
      "label": "Bandeja selável sem tampa"
    },
    {
      "value": "TSC",
      "label": "Tampa selável copo"
    },
    {
      "value": "TSP",
      "label": "Tampa selável pote"
    },
    {
      "value": "GRT",
      "label": "Gratinadeira PET"
    },
    {
      "value": "CXT",
      "label": "Caixa montagem com travas"
    },
    {
      "value": "DIV",
      "label": "Divisória"
    },
    {
      "value": "CXC",
      "label": "Caixas Cartucho / cola"
    },
    {
      "value": "PAB",
      "label": "Papel Bandeja"
    },
    {
      "value": "CAN",
      "label": "Canudos"
    },
    {
      "value": "SEL",
      "label": "Seladora"
    },
    {
      "value": "ACS",
      "label": "Acessório para seladora"
    },
    {
      "value": "SOS",
      "label": "Sacola com alça"
    },
    {
      "value": "SAC",
      "label": "Saco"
    },
    {
      "value": "CIN",
      "label": "Cintas para copos e potes"
    },
    {
      "value": "TPP",
      "label": "Tampas plásticas"
    },
    {
      "value": "BOF",
      "label": "Bobina Fundo"
    },
    {
      "value": "FSC",
      "label": "Forma para seladora copos"
    },
    {
      "value": "BSC",
      "label": "Berço Simples seladora Caixas"
    },
    {
      "value": "BDC",
      "label": "Berço Duplo seladora de Caixas"
    },
    {
      "value": "xPST",
      "label": "Seladora de Caixas"
    },
    {
      "value": "xPSC",
      "label": "Seladora de Copos e Potes"
    },
    {
      "value": "PSTD",
      "label": "Seladora de Caixas Trilho Duplo"
    }
  ];
  
  export const volumeOptions = [
    {
      "value": "0100",
      "label": "100ml"
    },
    {
      "value": "0120",
      "label": "120ml"
    },
    {
      "value": "0160",
      "label": "160ml"
    },
    {
      "value": "0180",
      "label": "180ml"
    },
    {
      "value": "0210",
      "label": "210ml"
    },
    {
      "value": "0220",
      "label": "220ml"
    },
    {
      "value": "0270",
      "label": "270ml"
    },
    {
      "value": "0300",
      "label": "300ml"
    },
    {
      "value": "0360",
      "label": "360ml"
    },
    {
      "value": "0400",
      "label": "400ml"
    },
    {
      "value": "0500",
      "label": "500ml"
    },
    {
      "value": "0550",
      "label": "550ml"
    },
    {
      "value": "0600",
      "label": "600ml"
    },
    {
      "value": "0650",
      "label": "650ml"
    },
    {
      "value": "0700",
      "label": "700ml"
    },
    {
      "value": "0800",
      "label": "800ml"
    },
    {
      "value": "0900",
      "label": "900ml"
    },
    {
      "value": "1000",
      "label": "1000ml"
    },
    {
      "value": "1100",
      "label": "1100ml"
    },
    {
      "value": "1400",
      "label": "1400ml"
    },
    {
      "value": "1500",
      "label": "1500ml"
    },
    {
      "value": "1600",
      "label": "1600ml"
    },
    {
      "value": "1800",
      "label": "1800ml"
    },
    {
      "value": "2000",
      "label": "2000ml"
    },
    {
      "value": "3000",
      "label": "3000ml"
    },
    {
      "value": "0790",
      "label": "790mm (largura de bobina)"
    },
    {
      "value": "1716",
      "label": "17.5x16.5x13"
    },
    {
      "value": "1617",
      "label": "16,5x17,5x16,5"
    },
    {
      "value": "1415",
      "label": "14,5x15,5x14,5"
    },
    {
      "value": "1414",
      "label": "14,2x14,2x15,0"
    },
    {
      "value": "0912",
      "label": "9,4x12,0x12,0"
    },
    {
      "value": "2431",
      "label": "24x31,5x12"
    },
    {
      "value": "3131",
      "label": "31,5x31,5x17"
    },
    {
      "value": "2230",
      "label": "22x30x12,5"
    },
    {
      "value": "3035",
      "label": "30x35x08"
    },
    {
      "value": "0418",
      "label": "4,5x18,0x8,0"
    },
    {
      "value": "1936",
      "label": "19,5x36x12"
    },
    {
      "value": "2424",
      "label": "24x24x15"
    },
    {
      "value": "3844",
      "label": "38x44x14"
    },
    {
      "value": "2435",
      "label": "24x35x9,5"
    },
    {
      "value": "3132",
      "label": "31x32x18"
    },
    {
      "value": "3744",
      "label": "37,5x44,5x14"
    },
    {
      "value": "2234",
      "label": "22x34x10"
    },
    {
      "value": "1827",
      "label": "18x27x10"
    },
    {
      "value": "3041",
      "label": "30,5x41x14,5"
    },
    {
      "value": "3934",
      "label": "39,5x34,0x15,5"
    },
    {
      "value": "1812",
      "label": "18x12,5x4,0"
    },
    {
      "value": "2215",
      "label": "22x15x5,0"
    },
    {
      "value": "2322",
      "label": "23,5x22x4,0"
    },
    {
      "value": "0505",
      "label": "500ml/500ml"
    },
    {
      "value": "0506",
      "label": "500ml/600ml"
    },
    {
      "value": "0508",
      "label": "500ml/800ml"
    },
    {
      "value": "0509",
      "label": "500ml/900ml"
    },
    {
      "value": "0510",
      "label": "500ml/1000ml"
    },
    {
      "value": "0515",
      "label": "500ml/1500ml"
    },
    {
      "value": "0518",
      "label": "500ml/1800ml"
    },
    {
      "value": "0520",
      "label": "500ml/2000ml"
    },
    {
      "value": "0606",
      "label": "600ml/600ml"
    },
    {
      "value": "0608",
      "label": "600ml/800ml"
    },
    {
      "value": "0609",
      "label": "600ml/900ml"
    },
    {
      "value": "0610",
      "label": "600ml/1000ml"
    },
    {
      "value": "0615",
      "label": "600ml/1500ml"
    },
    {
      "value": "0618",
      "label": "600ml/1800ml"
    },
    {
      "value": "0620",
      "label": "600ml/2000ml"
    },
    {
      "value": "0808",
      "label": "800ml/800ml"
    },
    {
      "value": "0809",
      "label": "800ml/900ml"
    },
    {
      "value": "0810",
      "label": "800ml/1000ml"
    },
    {
      "value": "0815",
      "label": "800ml/1500ml"
    },
    {
      "value": "0818",
      "label": "800ml/1800ml"
    },
    {
      "value": "0820",
      "label": "800ml/2000ml"
    },
    {
      "value": "0909",
      "label": "900ml/900ml"
    },
    {
      "value": "0910",
      "label": "900ml/1000ml"
    },
    {
      "value": "0915",
      "label": "900ml/1500ml"
    },
    {
      "value": "0918",
      "label": "900ml/1800ml"
    },
    {
      "value": "0920",
      "label": "900ml/2000ml"
    },
    {
      "value": "1010",
      "label": "1000ml/1000ml"
    },
    {
      "value": "1015",
      "label": "1000ml/1500ml"
    },
    {
      "value": "1018",
      "label": "1000ml/1800ml"
    },
    {
      "value": "1020",
      "label": "1000ml/2000ml"
    },
    {
      "value": "1515",
      "label": "1500ml/1500ml"
    },
    {
      "value": "1518",
      "label": "1500ml/1800ml"
    },
    {
      "value": "1520",
      "label": "1500ml/2000ml"
    },
    {
      "value": "1818",
      "label": "1800ml/1800ml"
    },
    {
      "value": "1820",
      "label": "1800ml/2000ml"
    },
    {
      "value": "2020",
      "label": "2000ml/2000ml"
    }
  ];
  
  export const materialOptions = [
    {
      "value": "XXXX",
      "label": "Não aplicável"
    },
    {
      "value": "PSPR",
      "label": "PS - Poliestireno Preto"
    },
    {
      "value": "PSBC",
      "label": "PS - Poliestireno Branco"
    },
    {
      "value": "PPET",
      "label": "PET"
    },
    {
      "value": "CTP1",
      "label": "Cartão Triplex P1"
    },
    {
      "value": "CSP1",
      "label": "Cartão Triplex P1 cupstock"
    },
    {
      "value": "PCCT",
      "label": "Cartão Triplex"
    },
    {
      "value": "PCCD",
      "label": "Cartão Duplex"
    },
    {
      "value": "PPOF",
      "label": "Offset"
    },
    {
      "value": "PPKR",
      "label": "Kraft"
    },
    {
      "value": "PCKR",
      "label": "Kraft Cupstock"
    },
    {
      "value": "0101",
      "label": "0.1 - 110v"
    },
    {
      "value": "0102",
      "label": "0.1 - 220v"
    },
    {
      "value": "0201",
      "label": "0.2 - 110v"
    },
    {
      "value": "0202",
      "label": "0.2 - 220v"
    },
    {
      "value": "1001",
      "label": "1.0 - 110V"
    },
    {
      "value": "1002",
      "label": "1.0 - 220v"
    },
    {
      "value": "1101",
      "label": "1.1 - 110v"
    },
    {
      "value": "1102",
      "label": "1.1 - 220v"
    },
    {
      "value": "2001",
      "label": "2.0 - 110v"
    },
    {
      "value": "2002",
      "label": "2.0 - 220v"
    },
    {
      "value": "2101",
      "label": "2.1 - 110v"
    },
    {
      "value": "2102",
      "label": "2.1 - 220v"
    }
  ];
  
  export const complementOptions = [
    {
      "value": "XX",
      "label": "Não Aplicável"
    },
    {
      "value": "PR",
      "label": "Personalizado"
    },
    {
      "value": "EP",
      "label": "Estampa padrão"
    },
    {
      "value": "BR",
      "label": "Em Branco"
    },
    {
      "value": "BC",
      "label": "Bico"
    },
    {
      "value": "CF",
      "label": "Com furo"
    },
    {
      "value": "SF",
      "label": "Sem furo"
    },
    {
      "value": "BL",
      "label": "Bolha"
    }
  ];
  
  export const versionsOptions = [
    {
      "value": "A",
      "label": "Versão A"
    },
    {
      "value": "B",
      "label": "Versão B"
    },
    {
      "value": "C",
      "label": "Versão C"
    },
    {
      "value": "D",
      "label": "Versão D"
    },
    {
      "value": "E",
      "label": "Versão E"
    },
    {
      "value": "F",
      "label": "Versão F"
    }
  ];
  
  export const customerOptions = [
    {
      "value": "0001",
      "label": "Site Serviço"
    },
    {
      "value": "0002",
      "label": "Site Venda"
    },
    {
      "value": "0003",
      "label": "Estoque Empo"
    },
    {
      "value": "0004",
      "label": "Estoque Papello"
    },
    {
      "value": "0005",
      "label": "Equipamento em comodato"
    },
    {
      "value": "9999",
      "label": "Marca zzz (código numérico cliente Omie)"
    },
    {
      "value": "0000",
      "label": "Marca yyy"
    }
  ];
  
 