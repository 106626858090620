import React, { useMemo, useState } from "react";
import { ListProductUseCase } from "../../../Usecase/Products/list-product-usecase";
import { useToast } from "../../../Hooks/ToasContext";
import { IProductEntity } from "../../../Services/api/products/products.interface";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { Box, Button, Typography } from '@mui/material'
import { ISaleEntity, TDeliveryPaymentStatus, TSalePaymentStatus } from "../../../Services/api/sales/sales.interface";
import { Environment } from "../../../Config/constants";
import { IFinancialEntity, TFinancialStatus } from "../../../Services/api/financial/financial.interface";
import { ListOrdersUseCase } from "../../../Usecase/Orders/list-orders-usecase";
import { IBillProductOrderPayload, IOrderEntity } from "../../../Services/api/orders/orders.interface";
import { format } from "date-fns";
import { Normalize } from "../../../utils/normalizeProperties";
import IKanbanBoardProductionSchemaProps from "../../../Components/KanbanBoardProduction/interfaces/kanban-board-production-schema.interface";
import { ProductionStatusEnum } from "../../../Services/api/service-orders/service-order.interface";
import { BillProductOrderOrdersUseCase } from "../../../Usecase/Orders/bill-products-order-usecase";
import { Task } from "../Details/financial-details.hook";


interface IUseListFinancialProps {
  listOrdersUseCase: ListOrdersUseCase
  billProductOrderOrdersUseCase: BillProductOrderOrdersUseCase
}
export function useListFinancial({ listOrdersUseCase, billProductOrderOrdersUseCase }: IUseListFinancialProps){
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(1)
    const [data, setData] = useState<IOrderEntity[]>([])
    const [showDetails, setShowDetails] = useState<undefined | string>(undefined)
    const [showFiltersModal, setShowFiltersModal] = useState(false)
    const [kanbanSchema, setKanbanSchema] = useState<IKanbanBoardProductionSchemaProps>()

    const { showToast } =  useToast()

    const transformData = (dados: IOrderEntity[]) => {
      
      const tasks: Record<string, Task> = {};
      const readyToInvoice : any = [];
      const invoiced : any = [];
      const finishsedOS : any = [];

   
      
      dados.forEach((item, index) => {
          // const taskId = `task-${index + 1}`;

          item.products.forEach(product => {
            const taskId = item.id;
            tasks[taskId] = {
                id: taskId,
                content: {
                    os: product.id,
                    items: []
                    
                }
            };

            if(product.status === ProductionStatusEnum.FINALIZACAO){
              readyToInvoice.push(taskId);
            } else if (product.status === ProductionStatusEnum.BILLED){
              invoiced.push(taskId)
            } 
          })
          
          
      });

      const result = {
          tasks,
          columns: {
            [ProductionStatusEnum.FINALIZACAO]: {
                  id: ProductionStatusEnum.FINALIZACAO,
                  title: 'Pronto para faturar',
                  taskIds: readyToInvoice,
              },
              [ProductionStatusEnum.PRODUCAO]: {
                  id: ProductionStatusEnum.PRODUCAO,
                  title: 'Faturado',
                  taskIds: invoiced,
              }
          },
          columnOrder: [ProductionStatusEnum.INICIO, ProductionStatusEnum.PRODUCAO, ProductionStatusEnum.FINALIZACAO],
      };

      return result;
    }

    const listOrders = async (currentPage: number) => {
        
        try {
          setLoading(true)
          
          const listResult = await listOrdersUseCase.handle(currentPage);

          if (listResult.isFailure) {
       
            showToast(String(listResult?.error?.error), 'error');
            return;
          }

         const list = listResult.getValue()
         const normalizeSchema : IKanbanBoardProductionSchemaProps = transformData(list?.data ?? [])
         setKanbanSchema(normalizeSchema)
        // const list : IFinancialEntity[] = [
        //   {
        //     id: '1',
        //     code: '100',
        //     createdAt: '29 out',
        //     customer: {
        //       name: 'Diego Hennrich',
        //       email: 'diegohennrich@gmail.com',
        //       phone: '51981872327',
        //     },
        //     total: 'R$ 10.735,00',
        //     delivery_address: {
        //       address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
        //     },
        //     payment_status: TFinancialStatus.APPROVED,
        //     delivery_status: TDeliveryPaymentStatus.WAITING,
        //     products: [
        //       {
        //         id: '10',
        //         name: 'Copo e Papel Kraft Personalizado 100ml',
        //         quantity: 1,
        //         variant: '5000',
        //         sku: '0010076',
        //         price_unit: 'R$ 2.034,28',
        //         total: 'R$ 2.034,28',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
        //       },
        //       {
        //         id: '11',
        //         name: 'Copo e Papel Personalizado 500ml',
        //         quantity: 1,
        //         variant: '10000',
        //         sku: '1000010014',
        //         price_unit: 'R$ 8.702,10',
        //         total: 'R$ 8.702,10',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
        //       }
        //     ]
        //   },
        //   {
        //     id: '2',
        //     code: '101',
        //     createdAt: '29 out',
        //     customer: {
        //       name: 'Ana Paula',
        //       email: 'anapaula@gmail.com',
        //       phone: '51981872327',
        //     },
        //     total: 'R$ 10.735,00',
        //     delivery_address: {
        //       address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
        //     },
        //     payment_status: TFinancialStatus.BILLED,
        //     delivery_status: TDeliveryPaymentStatus.WAITING,
        //     products: [
        //       {
        //         id: '10',
        //         name: 'Copo e Papel Kraft Personalizado 100ml',
        //         quantity: 1,
        //         variant: '5000',
        //         sku: '0010076',
        //         price_unit: 'R$ 2.034,28',
        //         total: 'R$ 2.034,28',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
        //       },
        //       {
        //         id: '11',
        //         name: 'Copo e Papel Personalizado 500ml',
        //         quantity: 1,
        //         variant: '10000',
        //         sku: '1000010014',
        //         price_unit: 'R$ 8.702,10',
        //         total: 'R$ 8.702,10',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
        //       }
        //     ]
        //   },
        //   {
        //     id: '5',
        //     code: '105',
        //     createdAt: '29 out',
        //     customer: {
        //       name: 'Miguel Souza',
        //       email: 'anapaula@gmail.com',
        //       phone: '51981872327',
        //     },
        //     total: 'R$ 7.800,00',
        //     delivery_address: {
        //       address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
        //     },
        //     payment_status: TFinancialStatus.PARTIAL,
        //     delivery_status: TDeliveryPaymentStatus.WAITING,
        //     products: [
        //       {
        //         id: '10',
        //         name: 'Copo e Papel Kraft Personalizado 100ml',
        //         quantity: 1,
        //         variant: '5000',
        //         sku: '0010076',
        //         price_unit: 'R$ 2.034,28',
        //         total: 'R$ 2.034,28',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
        //       },
        //       {
        //         id: '11',
        //         name: 'Copo e Papel Personalizado 500ml',
        //         quantity: 1,
        //         variant: '10000',
        //         sku: '1000010014',
        //         price_unit: 'R$ 8.702,10',
        //         total: 'R$ 8.702,10',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
        //       },
        //       {
        //         id: '11',
        //         name: 'Copo e Papel Personalizado 500ml',
        //         quantity: 1,
        //         variant: '10000',
        //         sku: '1000010014',
        //         price_unit: 'R$ 8.702,10',
        //         total: 'R$ 8.702,10',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
        //       }
        //     ]
        //   },
        //   {
        //     id: '3',
        //     code: '102',
        //     createdAt: '29 out',
        //     customer: {
        //       name: 'Pedro Rosa',
        //       email: 'anapaula@gmail.com',
        //       phone: '51981872327',
        //     },
        //     total: 'R$ 10.735,00',
        //     delivery_address: {
        //       address: 'Rua Carlos Antônio Bender 450 (93610-220) União, Estância Velha, Rio Grande do Sul, BR'
        //     },
        //     payment_status: TFinancialStatus.WAITING,
        //     delivery_status: TDeliveryPaymentStatus.WAITING,
        //     products: [
        //       {
        //         id: '10',
        //         name: 'Copo e Papel Kraft Personalizado 100ml',
        //         quantity: 1,
        //         variant: '5000',
        //         sku: '0010076',
        //         price_unit: 'R$ 2.034,28',
        //         total: 'R$ 2.034,28',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/100ml-copos_02_500-x-500-6c0615b1ffab57737b17065505955748-640-0.png'
        //       },
        //       {
        //         id: '11',
        //         name: 'Copo e Papel Personalizado 500ml',
        //         quantity: 1,
        //         variant: '10000',
        //         sku: '1000010014',
        //         price_unit: 'R$ 8.702,10',
        //         total: 'R$ 8.702,10',
        //         image: 'https://dcdn-us.mitiendanube.com/stores/005/262/599/products/copo-personalizado-500ml-de-papel-1-26b5fda1d5d9cef8e417053469403581-640-0.png'
        //       }
        //     ]
        //   }
        // ]

         if(list){
            setData(list.data)
         }

         setPage(currentPage)
        } catch (error) {
          console.log("error listOrders function: ", error)
        } finally {
          setLoading(false);
        }
      };

    
    const list = useMemo(() => {
        const rows = data?.map(sale => {

            return {
                ...sale,
                createdAt: format(new Date(sale.createdAt), 'dd/MM/yyyy'),
                totalValue: Normalize.value(sale.totalValue, { withPoint: true }),
                products: sale.products.map(item => {
                  return {
                    ...item,
                    
                    image: item?.product?.images[0]?.link || Environment.IMAGE_PLACEHOLDER
                  }
                })
              
            }
        })

        return rows
    }, [data])

    const handleShowDetails = (code: string) => {
      console.log("code: ", code)
      setShowDetails(code)
    } 

    const billProductsOrder = async (payload: IBillProductOrderPayload) => {
          console.log("chamou: ")
      try {
        setLoading(true)
        
        const billResult = await billProductOrderOrdersUseCase.handle(payload);

        if (billResult.isFailure) {
     
          showToast(String(billResult?.error?.error), 'error');
          return;
        }

      } catch (error) {
        console.log("error billProductsOrder function: ", error)
      } finally {
        setLoading(false);
      }
    };

      

    return {
        loading,
        listOrders,
        list,
        data,
        handleShowDetails,
        showDetails,
        setShowDetails,
        setShowFiltersModal,
        showFiltersModal,
        kanbanSchema,
        billProductsOrder

        
    }
}