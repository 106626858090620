import { Box, Paper, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { GoPlus } from "react-icons/go";
import { Table } from '../../../Components/Table/table.component';
import { useNavigate } from 'react-router-dom';
import { ListProductUseCase } from '../../../Usecase/Products/list-product-usecase';
import { useListCollaborators } from './collaborators-list.hook';
import { Placeholder } from '../../../Components/Placeholder/placeholder.component';

interface ICollaboratorsList {
    listProductUseCase: ListProductUseCase
}
const CollaboratorsList : FC<ICollaboratorsList> = ({ listProductUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listProdcuts, list, data } = useListCollaborators({
        listProductUseCase
    })

    // useEffect(() => {
    //     listProdcuts(1)
    // }, [])


    const columns = useMemo(() => {
        const createColumns: any[] = [
          {
            field: 'photo',
            headerName: 'Imagem',
            renderCell: (params: any) => <Box component='img' src={params.value} sx={{ width: '80px', height: '80px', objectFit: 'contain'}} />
          },
          
          {
            field: 'name',
            headerName: 'Produto',
            width: 300,
          },
          {
            field: 'code',
            headerName: 'Código',
            width: 100,
          },
          {
            field: 'price',
            headerName: 'Preço Unitário',
            width: 150,
          },
        ];
      
        return createColumns;
      }, []);
   

    return (
        <BaseScreen>
            <Paper >
                <BaseContentPage 
                    title='Colaboradores'
                    breadcrumb={[{ title: 'Colaboradores', link: "/collaborators-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '130px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => navigate('/collaborators-form')}>Adicionar</Button>}
                >
                  {!!list?.length ? <Table data={list} columns={columns} totalCount={0} /> : <Placeholder description='Nenhum dado encontrado.' /> }
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { CollaboratorsList }