import { IServiceInfoListResponse } from '../../Services/api/services-info/services-info-interface'
import { ServiceInfoServiceApiRemoteService } from '../../Services/api/services-info/services-info-service'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListServiceInfoOrdersUseCase
implements IFormUsecase<void, IServiceInfoListResponse> {
  constructor (private readonly ServiceInfoApi: ServiceInfoServiceApiRemoteService) {}
    

  public async handle(): Promise<Result<IServiceInfoListResponse>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.ServiceInfoApi.list()

      if (response.isFailure) {
        return response
      }

      const list = response.getValue()

      if(!list) return response

    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
