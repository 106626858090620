import { BoxesApiRemoteService } from '../../Services/api/boxes/boxes-service'
import { IBoxEntity } from '../../Services/api/boxes/boxes.interface'
import { IFormUsecase } from '../../Shared/Protocol/protocol-form-usecase'
import { Result } from '../../Shared/Protocol/protocol-result'


export class ListBoxesUseCase
implements IFormUsecase<number, IBoxEntity[]> {
  constructor (private readonly BoxesApi: BoxesApiRemoteService) {}
    

  public async handle (
    page: number
  ): Promise<Result<IBoxEntity[]>> {
    // eslint-disable-next-line no-useless-catch
    try {

      const response = await this.BoxesApi.list(page)

      if (response.isFailure) {
        return response
      }
    
      const list = response.getValue()

      if(!list) return response
    
      return Result.ok(list)
    } catch (error: any) {
      return Result.fail(error)
    }
  }

 
}
