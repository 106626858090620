import { Paper, useTheme } from '@mui/material';
import { FC, useEffect } from 'react';
import { GoPlus } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { CubageVariantProductFormDrawer } from '../../../Components/Drawer/variants/cubage-product-form/cubage-variant-product-form-drawer.component';
import { Button } from '../../../Components/Form/Button/button.scomponent';
import { CubageTable } from '../../../Components/Table/variants/cubage-table/cubage-table.component';
import { BaseContentPage } from '../../../Components/Templates/BaseContentPage/base-content-page.componen';
import { BaseScreen } from '../../../Components/Templates/BaseScreen/basescreen.component';
import { ListProductUseCase } from '../../../Usecase/Products/list-product-usecase';
import { useCubages } from './cubage-list.hook';
import { CreateCubagesUseCase } from '../../../Usecase/Cubages/create-cubages-usecase';
import { ListCubagesUseCase } from '../../../Usecase/Cubages/list-cubages-usecase';
import { list } from '@chakra-ui/react';
import { CreateCubagesVariationsUseCase } from '../../../Usecase/CubagesVariations/create-cubages-variations-usecase';
import { ListBoxesUseCase } from '../../../Usecase/Boxes/list-boxes-usecase';
import { ShowCubagesVariationsUseCase } from '../../../Usecase/CubagesVariations/show-cubages-variations-usecase';
import { UpdateubagesVariationsUseCase } from '../../../Usecase/CubagesVariations/update-cubages-variations-usecase';
import { DestroyCubagesVariationsUseCase } from '../../../Usecase/CubagesVariations/destroy-cubages-variations-usecase';
import { ShowCubagesUseCase } from '../../../Usecase/Cubages/show-cubages-usecase';
import { UpdateCubagesUseCase } from '../../../Usecase/Cubages/update-cubages-usecase';
import { DestroyCubagesUseCase } from '../../../Usecase/Cubages/destroy-cubages-usecase';
import { ConfirmDialog } from '../../../Components/Dialog/variants/dialog-confirmation/dialog-confirmation.component';

interface ICubageList {
    listProductUseCase: ListProductUseCase
    createCubagesUseCase: CreateCubagesUseCase
    listCubagesUseCase: ListCubagesUseCase
    createCubagesVariationsUseCase: CreateCubagesVariationsUseCase
    listBoxesUseCase: ListBoxesUseCase
    showCubagesVariationsUseCase: ShowCubagesVariationsUseCase
    updateubagesVariationsUseCase: UpdateubagesVariationsUseCase
    destroyCubagesVariationsUseCase: DestroyCubagesVariationsUseCase
    showCubagesUseCase: ShowCubagesUseCase
    updateCubagesUseCase: UpdateCubagesUseCase
    destroyCubagesUseCase: DestroyCubagesUseCase
}

const CubageList : FC<ICubageList> = ({ listProductUseCase, createCubagesUseCase, listCubagesUseCase, createCubagesVariationsUseCase, listBoxesUseCase, showCubagesVariationsUseCase, updateubagesVariationsUseCase, destroyCubagesVariationsUseCase, showCubagesUseCase, updateCubagesUseCase, destroyCubagesUseCase }) => {
    const theme = useTheme()
    const navigate = useNavigate()

    const { listCubages, list, data, showNewCubage, setShowNewCubage, loading , handleCreateCubage, handleCreateCubageVariation, cubageProductIdSelected, listBoxes, boxOptions, setCubageProductIdSelected,  setCubageVariantSelected, cubageVariantSelected, handleUpdateCubageVariation, cubageVariantToDestroy, setCubageVariantToDestroy, handleDestroyVariant, variantProductSelected, setVariantProductSelected, variantProductToDestroy, setVariantProductToDestroy,  handleShowCubage, handleUpdateCubage, handleDestroyCubage
     } = useCubages({
        listProductUseCase,
        createCubagesUseCase,
        listCubagesUseCase,
        createCubagesVariationsUseCase,
        listBoxesUseCase,
        showCubagesVariationsUseCase,
        updateubagesVariationsUseCase,
        destroyCubagesVariationsUseCase,
        showCubagesUseCase,
        updateCubagesUseCase,
        destroyCubagesUseCase
    })
    

    useEffect(() => {
        listCubages(1)
        listBoxes(1)
    }, [])


    return (
        <BaseScreen>

        <ConfirmDialog title='Remover Produto de Cubagem' onCancel={() => setVariantProductToDestroy('')} description='Você tem certeza?' open={!!variantProductToDestroy} onConfirm={() => handleDestroyCubage(String(variantProductToDestroy))}  /> 
        
        {(showNewCubage || !!variantProductSelected?.id) && <CubageVariantProductFormDrawer open={showNewCubage || !!variantProductSelected?.id} loading={loading} onToggle={() => {
            if(variantProductSelected?.id){
                setVariantProductSelected(undefined)
            } else {
                setShowNewCubage(prev => !prev)
            }
            
        }} title={variantProductSelected ? 'Editar Produto de Cubagem' : 'Novo Produto de Cubagem'} handleAction={variantProductSelected ? handleUpdateCubage : handleCreateCubage} variantProductSelected={variantProductSelected}  /> }
        
            <Paper >
                <BaseContentPage 
                    title='Tabela de Cubagem'
                    breadcrumb={[{ title: 'Tabela de Cubagem', link: "/cubage-list"}, { title: 'Listagem'}]}
                    Action={() => <Button LeftIcon={() => <GoPlus size={18} />} sx={{ width: '190px'}} boxLeftSx={{ gap: '10px', marginLeft: '5px'}} textSx={{ textTransform: 'Capitalize', fontWeight: 500}} onClick={() => setShowNewCubage(true)}>Adicionar Produto</Button>}
                >
                  {/* {list?.length &&   <Table data={list} columns={columns} /> } */}
                  
                  <CubageTable data={list} handleAction={!!cubageVariantSelected?.id ? handleUpdateCubageVariation : handleCreateCubageVariation} cubageProductIdSelected={cubageProductIdSelected} boxOptions={boxOptions ?? []} setCubageProductIdSelected={setCubageProductIdSelected} setCubageVariantSelected={setCubageVariantSelected} cubageVariantSelected={cubageVariantSelected} cubageVariantToDestroy={cubageVariantToDestroy} setCubageVariantToDestroy={setCubageVariantToDestroy} handleDestroyVariant={handleDestroyVariant} handleShowCubage={handleShowCubage} setVariantProductToDestroy={setVariantProductToDestroy}  />
                </BaseContentPage>
              
            </Paper>
                
        </BaseScreen>
    )
}

export { CubageList };
