import React from 'react';
import { Box, Typography } from '@mui/material';
import 'react-quill/dist/quill.snow.css'; // Importar o tema do React Quill
import ReactQuill from 'react-quill';
import { Controller, Control } from 'react-hook-form';

const modules = {
  toolbar: [
    [{ 'header': [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike'], // negrito, itálico, sublinhado, tachado
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    ['link', 'image'], // links e imagens
    [{ 'align': [] }],
    ['clean'], // limpa formatação
  ],
};

const formats = [
  'header',
  'bold', 'italic', 'underline', 'strike',
  'list', 'bullet',
  'link', 'image', 'align',
];

interface RichTextEditorProps {
  name: string;
  control: Control<any>;
  label?: string;
  defaultValue?: string;
  // Outras props opcionais
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  name,
  control,
  label,
  defaultValue = '',
  ...rest
}) => {
  return (
    <Box>
      {label && <Typography variant="subtitle1">{label}</Typography>}
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field }) => (
          <ReactQuill
            {...field}
            onChange={(content, delta, source, editor) => {
              field.onChange(content); // Atualiza o valor no react-hook-form
            }}
            value={field.value}
            modules={modules}
            formats={formats}
            style={{ height: '400px' }} // Defina a altura do editor
            {...rest}
          />
        )}
      />
    </Box>
  );
};

export default RichTextEditor;